import { FC } from 'react'
import { HalCustomerNoteData, SkfCustomerNoteData } from '@/models/reportStatus/types'
import { StyledInfoCardContainer } from '@/modules/report-status/styled'
import CustomerInfoCard from '@/modules/report-status/components/customerNote/CustomerInfoCard'
import SkfInfoCard from '@/modules/report-status/components/customerNote/SkfInfoCard'

const InfoCard: FC<{
  noteInfo: SkfCustomerNoteData | HalCustomerNoteData
}> = ({ noteInfo }) => {
  const card = () => {
    if (noteInfo.isSkf) {
      return <SkfInfoCard noteData={noteInfo} />
    } else {
      return <CustomerInfoCard noteData={noteInfo} />
    }
  }
  return <StyledInfoCardContainer>{card()}</StyledInfoCardContainer>
}
export default InfoCard
