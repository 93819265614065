import useEscapeKey from '@/shared/hooks/useEscapeKey'
import useOutsideClick from '@/shared/hooks/useOutsideClick'
import {
  Button,
  COLORS,
  Card,
  Flexbox,
  IconButton,
  Popup,
  SPACINGS,
  Text,
  css,
  useTooltip,
} from '@skf-internal/ui-components-react'
import { Column } from '@tanstack/react-table'
import { isEqual } from 'lodash-es'
import { FC, Fragment, ReactNode, useRef, useState } from 'react'

type ColumnFilterProps<T> = {
  initialValue?: T
  onFilterValueChange: (value?: T) => void
}

const FilteredColumnIndicator: FC<{ isFilterApplied: boolean }> = ({ isFilterApplied }) => {
  return (
    isFilterApplied && (
      <span
        css={css({
          position: 'absolute',
          height: '0.4rem',
          width: '0.4rem',
          right: 0,
          bottom: 0,
          backgroundColor: COLORS.brand,
          borderRadius: '50%',
          display: 'inline-block',
          pointerEvents: 'none',
        })}
      ></span>
    )
  )
}

const FilterWrapper = <ColumnType,>({
  InternalComponent,
  isFilterApplied,
  initialFilterValue,
  onFilterSet,
}: {
  InternalComponent: FC<ColumnFilterProps<ColumnType>>
  isFilterApplied: boolean
  initialFilterValue: ColumnType
  onFilterSet: (filterValue?: ColumnType) => void
}) => {
  const [filterValue, setFilterValue] = useState<ColumnType | undefined>(
    isFilterApplied ? initialFilterValue : undefined
  )

  const isFilterApplyDisabled =
    (isFilterApplied === false && filterValue === undefined) ||
    (isFilterApplied === true && initialFilterValue !== undefined && isEqual(initialFilterValue, filterValue))

  const handleFilterValueChange = (value?: ColumnType) => {
    setFilterValue(value)
  }

  const handleFilterClear = () => onFilterSet(undefined)
  const handleFilterApply = () => onFilterSet(filterValue)

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' && !isFilterApplyDisabled) {
      handleFilterApply()
    }
  }

  return (
    <>
      <div onKeyDown={(event) => handleKeyDown(event)}>
        <InternalComponent onFilterValueChange={handleFilterValueChange} initialValue={filterValue} />
      </div>
      <Flexbox feGap="sm" feJustifyContent="right">
        <Button feType="secondary" feSize="sm" onClick={handleFilterClear}>
          Clear
        </Button>

        <Button feSize="sm" onClick={handleFilterApply} disabled={isFilterApplyDisabled}>
          Apply
        </Button>
      </Flexbox>
    </>
  )
}

export const withColumnFilterPopover =
  <RowType, ColumnType>(
    InternalComponent: FC<ColumnFilterProps<ColumnType>>,
    formatAppliedFilter: (filterValue: ColumnType) => ReactNode
  ): FC<{ column: Column<RowType, unknown> }> =>
  ({ column }) => {
    const isFilterApplied = column.getIsFiltered()
    const appliedFilterValue = column.getFilterValue() as ColumnType

    const [showPopover, setShowPopover] = useState(false)
    const outsideClickRef = useRef<HTMLDivElement>(null)

    useOutsideClick(
      () => {
        setShowPopover(false)
      },
      {
        refs: [outsideClickRef],
        classNames: ['ant-picker-panel-container', 'ant-picker-date-panel-container'],
      }
    )
    const [clickedRef] = useTooltip()

    const [hoveredRef, isHovered] = useTooltip()

    const handleFilterSet = (filterValue?: ColumnType) => {
      column.setFilterValue(filterValue)
      setShowPopover(false)
    }

    useEscapeKey(() => setShowPopover(false))

    return (
      <Fragment>
        <div>
          <div css={css({ position: 'relative', lineHeight: 0 })} ref={hoveredRef}>
            <FilteredColumnIndicator isFilterApplied={isFilterApplied} />

            <IconButton
              feSize="sm"
              feIcon="filter"
              as="button"
              aria-label="Open filter popover"
              onClick={() => setShowPopover((showPopover) => !showPopover)}
              ref={clickedRef}
            />
          </div>
        </div>
        <Popup isHovered={isHovered && isFilterApplied} triggerElement={hoveredRef.current}>
          <Flexbox feFlexDirection="column" feGap="sm">
            <Text feFontSize="md" feFontWeight="bold">
              The following filter values are applied:
            </Text>
            {appliedFilterValue && formatAppliedFilter(appliedFilterValue)}
          </Flexbox>
        </Popup>
        <Popup feNoPadding isHovered={showPopover} triggerElement={clickedRef.current}>
          <Card
            css={css({
              top: SPACINGS.sm,
              gap: SPACINGS.md,
              display: 'flex',
              columnGap: SPACINGS.xxl,
              flexDirection: 'column',
              border: 'none !important',
              boxShadow: 'none !important',
              fontSize: '1rem !important',
            })}
            ref={outsideClickRef}
          >
            <FilterWrapper
              InternalComponent={InternalComponent}
              onFilterSet={handleFilterSet}
              isFilterApplied={isFilterApplied}
              initialFilterValue={appliedFilterValue}
            />
          </Card>
        </Popup>
      </Fragment>
    )
  }
