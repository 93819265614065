// =================================================================================================
// IMPORTS
// =================================================================================================

import { FC, useState } from 'react'
import { Row } from '@tanstack/react-table'
import { FONT_SIZES, Flexbox, css, styled } from '@skf-internal/ui-components-react'
import { withColumnFilterPopover } from '../../../../../../common/table/filters/filter-popover-wrapper'
import { DeviceStatusCheckbox } from './device-status-checkbox'
import { DevicesSensorsTableDeviceRowData } from '@/models/devicesAndSensors/types'
import { DeviceStatusString } from '@/modules/devices-sensors/utils/constants'

// =================================================================================================
// FILTER FUNCTION
// =================================================================================================

export const deviceStatusFilterFn = (
  row: Row<DevicesSensorsTableDeviceRowData>,
  columnId: string,
  filteredStatus: DevicesSensorsTableDeviceRowData['status'][]
) => {
  const columnValue = row.getValue<DevicesSensorsTableDeviceRowData['status']>(columnId)
  return filteredStatus.some((v) => v == columnValue)
}

// =================================================================================================
// FILTER POPOVER
// =================================================================================================

const StatusFilterPopover: FC<{
  initialValue?: DevicesSensorsTableDeviceRowData['status'][]
  onFilterValueChange: (value?: DevicesSensorsTableDeviceRowData['status'][]) => void
}> = ({ initialValue, onFilterValueChange }) => {
  const [filteredStatus, setFilteredStatus] = useState<DevicesSensorsTableDeviceRowData['status'][]>(initialValue || [])

  const findStatusValue = (value: DevicesSensorsTableDeviceRowData['status']): boolean =>
    filteredStatus.filter((v) => v == value)[0] != undefined

  const handleOnChange = (status: DevicesSensorsTableDeviceRowData['status'], checked: boolean) => {
    let newFilterValue: DevicesSensorsTableDeviceRowData['status'][] | undefined = undefined

    if (checked) {
      newFilterValue = [...filteredStatus, status]
      setFilteredStatus(newFilterValue)
    } else {
      newFilterValue = filteredStatus.filter((v) => v !== status)
      setFilteredStatus(newFilterValue)
    }

    if (newFilterValue.length === 0) {
      newFilterValue = undefined
    }

    onFilterValueChange(newFilterValue)
  }

  return (
    <StyledDeviceStatusFilterRoot feJustifyContent="center" feFlexDirection="column" feGap="md">
      <DeviceStatusCheckbox status="notNormal" checked={findStatusValue('notNormal')} onChange={handleOnChange} />
      <DeviceStatusCheckbox status="normal" checked={findStatusValue('normal')} onChange={handleOnChange} />
    </StyledDeviceStatusFilterRoot>
  )
}

// =================================================================================================
// FILTER DEFINITION
// =================================================================================================

export const DeviceStatusFilter = withColumnFilterPopover<
  DevicesSensorsTableDeviceRowData,
  DevicesSensorsTableDeviceRowData['status'][]
>(StatusFilterPopover, (filterValue: DevicesSensorsTableDeviceRowData['status'][]) => (
  <ul
    css={css`
      list-style-type: disc;
      list-style-position: inside;
      margin: 0;
      padding: 0;

      & > li {
        font-size: ${FONT_SIZES.md};
      }
    `}
  >
    {filterValue.map((v, index) => (
      <li key={index}>{DeviceStatusString[v]}</li>
    ))}
  </ul>
))

// =================================================================================================
// STYLES
// =================================================================================================

const StyledDeviceStatusFilterRoot = styled(Flexbox)`
  width: 200px;
`
