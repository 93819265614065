import useDeepCompareEffect from '@/shared/hooks/useDeepCompareEffect'
import { DependencyList } from 'react'

const ESCAPE_KEY = 'Escape'

type CallbackFunction = (event: KeyboardEvent) => void

/**
 * Handles the `Escape` key press event and calls a provided callback function when it occurs.

 * @param {CallbackFunction} callback - The callback function to be called when the `Escape` key is pressed.
 * @param {DependencyList} [dependencies=[]] - An optional array of dependencies that trigger the effect. If provided, the effect will only re-run when these dependencies change.
 */

const useEscapeKey = (callback: CallbackFunction, dependencies: DependencyList = []): void => {
  useDeepCompareEffect(() => {
    const onKeyPress = (event: KeyboardEvent) => {
      if (event.key === ESCAPE_KEY) {
        callback(event)
      }
    }

    document.addEventListener('keydown', onKeyPress)

    return () => {
      document.removeEventListener('keydown', onKeyPress)
    }
  }, [callback, dependencies])
}
export default useEscapeKey
