import { postRecommendations } from '@/api/paths/analytics/recommendations'
import { DarwinWidgetError } from '@/common/error-boundary/DarwinError'
import { useApi } from '@/shared/hooks/useApi'
import { RAwidgetDataType } from '@/models/widgets/recommended-actions/types'
import { SelectFilterType } from '@/models/dashboard/globalFiltering/types'
import { AxiosError } from 'axios'
import { useDashboardContext } from '@/contexts/dashboard'
import { generateLast12MonthsData } from '@/modules/dashboard/utils'
import { useParams } from 'react-router-dom'
import { WidgetType } from '@/models/dashboard/types'

const useWidgetRecommendedActions = (globalFilter: SelectFilterType) => {
  const { sid: companyId } = useParams()
  const { setWidgetRecommendedActions } = useDashboardContext()

  return useApi(async () => {
    return await postRecommendations(
      globalFilter,
      {
        onSuccess: async (result) => {
          let recommendedActionsWidgetData = result as unknown as RAwidgetDataType
          if (recommendedActionsWidgetData.length === 0) {
            recommendedActionsWidgetData = generateLast12MonthsData()
          }
          setWidgetRecommendedActions(recommendedActionsWidgetData as unknown as RAwidgetDataType)
        },
        onError: async (error: AxiosError) => {
          return Promise.reject(new DarwinWidgetError(error, WidgetType.RECOMMENDED_ACTIONS))
        },
      },
      { companyId }
    )
  })
}

export default useWidgetRecommendedActions
