import { useEffect, useState, useRef, useCallback } from 'react'

export default function useElementInDOM(selector: string, callback?: () => void) {
  const [isPresent, setIsPresent] = useState(false)
  const callbackCalled = useRef(false)

  useEffect(() => {
    const checkElement = () => {
      const element = document.querySelector(selector)
      const elementExists = !!element

      if (elementExists !== isPresent) {
        setIsPresent(elementExists)
      }

      if (elementExists && !callbackCalled.current) {
        callback && callback()
        callbackCalled.current = true
      }
    }

    // Initial check
    checkElement()

    // Set up a MutationObserver to watch for changes in the DOM
    const observer = new MutationObserver(checkElement)
    observer.observe(document.body, { childList: true, subtree: true })

    return () => {
      observer.disconnect()
    }
  }, [selector, callback, isPresent])

  const reset = useCallback(() => {
    callbackCalled.current = false
  }, [])

  return [isPresent, reset]
}
