import { CustomFilterMeta } from '@/common/table/filters'
import {
  StyledTabledHeaderColumnIcon,
  StyledTabledHeaderColumnIconContainer,
  StyledTableHeaderColumn,
  StyledTableHeaderColumnContainer,
} from '@/shared/components/TableHeaderColumn/styled'
import useDeepCompareMemo from '@/shared/hooks/useDeepCompareMemo'
import { dataTestId } from '@/tests/testid'
import { Icon } from '@skf-internal/ui-components-react'
import { Column, flexRender, Header } from '@tanstack/react-table'
import { PropsWithChildren, useMemo } from 'react'

interface TableHeaderColumnProps<T> extends PropsWithChildren {
  header: Header<T, unknown>
  columnWidth?: number
  centerValue?: boolean
  spanCol?: number
  withSorting?: boolean
  withContent?: boolean
}

const TableHeaderColumn = <T,>({
  header: { column, isPlaceholder, getContext, colSpan },
  columnWidth = 0,
  centerValue,
  spanCol,
  withSorting = true,
  withContent = true,
  children,
}: TableHeaderColumnProps<T>) => {
  const FilterComponent = (column: Column<T, unknown>) =>
    (column.columnDef?.meta as CustomFilterMeta<T>).filterComponent({ column })

  const width = useMemo(() => (columnWidth ? `${columnWidth}px` : '100%'), [columnWidth])
  const flexContentJustification = useMemo(() => (centerValue ? 'center' : 'start'), [centerValue])
  const canSort = useMemo(() => !!column.getCanSort(), [column])
  const canFilter = useMemo(() => column.getCanFilter() && column.columnDef?.meta, [column])
  const headerComponent = useDeepCompareMemo(
    () => (isPlaceholder ? null : flexRender(column.columnDef.header, getContext())),
    [column.columnDef.header, isPlaceholder]
  )
  const colSpanValue = useMemo(() => (spanCol ? spanCol : colSpan), [colSpan, spanCol])
  const cursor = useMemo(() => (column.getCanSort() ? 'pointer' : 'auto'), [column])

  return (
    <StyledTableHeaderColumn
      data-testid={dataTestId.tableGenericHeaderColumn}
      colSpan={colSpanValue}
      width={width}
      cursor={cursor}
      scope="col"
    >
      <StyledTableHeaderColumnContainer flexContentJustification={flexContentJustification}>
        {withContent && (
          <>
            {canFilter && <FilterComponent data-testid={dataTestId.columHeaderCustomFilter} {...column} />}
            <StyledTabledHeaderColumnIconContainer onClick={column.getToggleSortingHandler()}>
              {headerComponent}
              {withSorting &&
                ({
                  asc: <Icon feIcon="caretUp" feSize="sm" />,
                  desc: <Icon feIcon="caretDown" feSize="sm" />,
                }[column.getIsSorted() as string] ??
                  (canSort ? <StyledTabledHeaderColumnIcon feSize="sm" feIcon="caretUpDown" /> : null))}
            </StyledTabledHeaderColumnIconContainer>
          </>
        )}
      </StyledTableHeaderColumnContainer>

      {children}
    </StyledTableHeaderColumn>
  )
}

export default TableHeaderColumn
