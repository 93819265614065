import {
  StyledCollapseRowWrapper,
  StyledReconfirmCollapseContainer,
  StyledReconfirmFlex,
  StyledReconfirmUsernameSpan,
  StyledRowWrapper,
} from '@/modules/report-status/styled'
import { compareDates, DateFormats, formatDate } from '@/shared/dateUtils'
import { Flexbox, Image, SPACINGS } from '@skf-internal/ui-components-react'
import { FC } from 'react'
import { Row } from './Row'
import { AssetHistorySnapshot } from '@/models/reportStatus/types'
import CondensedImg from './assets/markAsNormal.png'
import HistoryLinePrefix from '../Line'
import useDeepCompareMemo from '@/shared/hooks/useDeepCompareMemo'
import { dataTestId } from '@/tests/testid'

export const Collapse: FC<{ collapsedData: AssetHistorySnapshot[]; open: boolean }> = ({ collapsedData, open }) => {
  const lastReconfirm = useDeepCompareMemo(() => {
    return collapsedData.reduce((latest, current) => {
      return compareDates(current.timestamp, latest.timestamp) === -1 ? latest : current
    })
  }, [collapsedData])

  return (
    <div>
      <Flexbox feGap="xs">
        {collapsedData.length > 1 && (
          <Image
            src={CondensedImg}
            width={20}
            height={20}
            alt="condensed-image"
            data-testid={dataTestId.condensedImg}
          />
        )}
        {collapsedData.length === 1 ? (
          <StyledRowWrapper feGap="sm" feFlexDirection="column">
            <span>{formatDate(lastReconfirm.timestamp, DateFormats.AmericanAMPMFormat)}</span>
            <StyledReconfirmUsernameSpan>{lastReconfirm.username}</StyledReconfirmUsernameSpan>
          </StyledRowWrapper>
        ) : open ? (
          <StyledReconfirmFlex feGap="sm">
            <span>{formatDate(lastReconfirm.timestamp, DateFormats.AmericanAMPMFormat)}</span>
            <StyledReconfirmUsernameSpan>{lastReconfirm.username}</StyledReconfirmUsernameSpan>
          </StyledReconfirmFlex>
        ) : (
          <StyledReconfirmFlex feGap="sm">
            <span>{formatDate(lastReconfirm.timestamp, DateFormats.AmericanAMPMFormat)}</span>
            <span>({collapsedData.length} marks as normal)</span>
          </StyledReconfirmFlex>
        )}
      </Flexbox>

      {open && collapsedData.length > 1 && (
        <StyledReconfirmCollapseContainer feFlexDirection="row">
          <HistoryLinePrefix marginTop={SPACINGS.xxs} />
          <StyledCollapseRowWrapper feFlexDirection="column">
            {collapsedData
              .filter((v) => v != lastReconfirm)
              .reverse()
              .map((item) => (
                <Row collapsedData={item} />
              ))}
          </StyledCollapseRowWrapper>
        </StyledReconfirmCollapseContainer>
      )}
    </div>
  )
}
