import { useFaultEvidenceContext } from '@/contexts/moduleContexts/evidence'
import { useFaultsFormStateContext } from '@/contexts/moduleContexts/faultsForm'
import { useReportStatusContext } from '@/contexts/reportStatus'
import {
  ACCEPTED_FILE_TYPES,
  MAX_FILE_SIZE,
  MAX_FILES,
  MAX_TOTAL_SIZE,
} from '@/modules/analysis-backlog/utils/constants'
import { ADD_EVIDENCE_UPLOADED_IMAGE } from '@/modules/report-status/reducer/actions.types'
import {
  StyledDashedBorder,
  StyledErrorParagraph,
  StyledIcon,
  StyledInput,
  StyledParagraph,
  StyledUploadIconContainer,
  StyledUploadImageCard,
} from '@/modules/report-status/styled'
import {
  convertDropZoneData,
  convertFileToBase64,
  handleInputPasteEvent,
  evidenceFileValidator,
} from '@/modules/report-status/utils/evidenceUtils'
import useDeepCompareCallback from '@/shared/hooks/useDeepCompareCallback'
import useDeepCompareEffect from '@/shared/hooks/useDeepCompareEffect'
import { dataTestId } from '@/tests/testid'
import { Button, css, Flexbox } from '@skf-internal/ui-components-react'
import { MouseEvent as ReactMouseEvent, useCallback, useState } from 'react'
import { FileRejection, useDropzone } from 'react-dropzone'

const DragAndDropUploader = () => {
  const [validationError, setValidationError] = useState<string | null>(null)

  const { faultImages, faultId } = useFaultEvidenceContext()

  const { faultsFormDispatch } = useFaultsFormStateContext()

  const { isEvidenceFaultImageModalOpen, faultEditModeData } = useReportStatusContext()

  const convertToEvidenceImageData = useDeepCompareCallback(
    async (filesArray: File[] | FileRejection[]) => {
      const convertedData = convertDropZoneData(filesArray, faultId)

      const handleAsyncOperations = async () => {
        const promises = convertedData.map(async (data) => {
          const content = await convertFileToBase64(data.imageInfo.originFile)
          data.imageInfo.content = content
          return data
        })
        await Promise.all(promises)
      }
      await handleAsyncOperations()
      return convertedData
    },
    [faultId, faultImages]
  )

  const onDrop = useCallback(
    async (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
      const allFiles = [...acceptedFiles, ...rejectedFiles]
      if (allFiles.length + faultImages.length > MAX_FILES) {
        setValidationError(`You can only upload a maximum of ${MAX_FILES} files.`)
        return
      }

      const convertedValidFiles = await convertToEvidenceImageData(allFiles as File[] | FileRejection[])
      const totalSize = [...faultImages, ...convertedValidFiles].reduce((acc, file) => acc + file.imageInfo.size, 0)

      if (totalSize > MAX_TOTAL_SIZE) {
        setValidationError(`Total file size exceeds ${MAX_TOTAL_SIZE / (1024 * 1024)} MB.`)
        return
      }

      if (
        faultImages.some((image) =>
          convertedValidFiles.some((file) => image.imageInfo.content === file.imageInfo.content)
        )
      ) {
        setValidationError('This image was already added as evidence to the fault')
        return
      }

      setValidationError(null)
      faultsFormDispatch({
        type: ADD_EVIDENCE_UPLOADED_IMAGE,
        payload: { evidences: [...faultImages, ...convertedValidFiles], faultId },
      })
    },
    [faultImages, faultId, convertToEvidenceImageData, faultsFormDispatch]
  )

  const { getRootProps, getInputProps, isDragActive, inputRef } = useDropzone({
    onDrop,
    multiple: true,
    maxSize: MAX_TOTAL_SIZE,
    maxFiles: MAX_FILES - faultImages.length,
    disabled: faultImages.length >= MAX_FILES,
    accept: {
      'image/jpeg': [],
      'image/png': [],
      'image/jpg': [],
      'image/gif': [],
    },
    validator: (file) => evidenceFileValidator(file, ACCEPTED_FILE_TYPES, MAX_FILE_SIZE),
  })

  const handlePasteFile = useCallback(
    (event: ClipboardEvent | ReactMouseEvent) => {
      const processPasteData = async () => {
        const htmlInput = inputRef.current as unknown as HTMLInputElement
        await handleInputPasteEvent(htmlInput, event)
      }

      !isEvidenceFaultImageModalOpen && processPasteData()
    },
    [inputRef, isEvidenceFaultImageModalOpen]
  )

  useDeepCompareEffect(() => {
    document.addEventListener('paste', handlePasteFile)
    return () => document.removeEventListener('paste', handlePasteFile)
  }, [inputRef, isEvidenceFaultImageModalOpen])

  useDeepCompareEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => faultImages.forEach((file) => file.imageInfo.fileURL && URL.revokeObjectURL(file.imageInfo.fileURL))
  }, [faultImages])

  return (
    <Flexbox>
      {faultEditModeData[faultId] && (
        <StyledUploadImageCard>
          <Button
            feType="secondary"
            feIcon={{ feIcon: 'duplicate', position: 'left' }}
            onClick={(event) => handlePasteFile(event)}
            css={css`
              margin-bottom: 15px;
            `}
            data-testid={dataTestId.evidencePasteFromClipboardButton}
            disabled={faultImages.length >= 5}
          >
            Paste from clipboard
          </Button>
          <StyledDashedBorder
            {...getRootProps()}
            data-testid={dataTestId.evidenceDragAndDropUploader}
            imageCount={faultImages.length}
          >
            <StyledUploadIconContainer imageCount={faultImages.length}>
              <StyledIcon feIcon="upload" feColor={faultImages.length >= 5 ? 'gray' : 'brand'} feSize="sm" />
              <StyledInput {...getInputProps()} />
            </StyledUploadIconContainer>
            {isDragActive ? (
              <StyledParagraph>Release to drop the files here</StyledParagraph>
            ) : (
              <StyledParagraph>Drag files here or click to upload</StyledParagraph>
            )}
          </StyledDashedBorder>
          {validationError && <StyledErrorParagraph>{validationError}</StyledErrorParagraph>}
        </StyledUploadImageCard>
      )}
    </Flexbox>
  )
}

export default DragAndDropUploader
