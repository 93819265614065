import { dataTestId } from '@/tests/testid'
import { css } from '@emotion/react'
import { Loader } from '@skf-internal/ui-components-react'

export const CenteredLoader = () => {
  return (
    <div
      data-testid={dataTestId.centeredLoader}
      css={css`
        flex-grow: 1;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      `}
    >
      <Loader />
    </div>
  )
}
