import { EvidenceImageData } from '@/models/reportStatus/evidenceTypes'
import {
  StyledFaultHistoryEvidenceCommentBG,
  StyledFaultHistoryEvidenceComment,
  StyledFaultHistoryEvidenceFilesContent,
  StyledFaultHistoryEvidenceFile,
} from '@/modules/report-status/styled'
import { FC } from 'react'
import { useReportStatusContext } from '@/contexts/reportStatus'
import SingleLineTextEllipsis from '@/common/text-ellipsis/SingleLineTextEllipsis'
import { useFaultEvidenceContext } from '@/contexts/moduleContexts/evidence'

interface EvidenceHistoryContentProps {
  evidence: EvidenceImageData['imageInfo']
}

const EvidenceHistoryContent: FC<EvidenceHistoryContentProps> = ({ evidence }) => {
  const { faultEditModeData, setIsEvidenceFaultImageModalOpen, setEvidenceDialogData, setFaultEditModeData } =
    useReportStatusContext()
  const { faultId } = useFaultEvidenceContext()

  const handleEvidenceHistoryModal = () => {
    setFaultEditModeData({ ...faultEditModeData, [faultId]: false })
    setEvidenceDialogData({ imageInfo: evidence } as EvidenceImageData)
    setIsEvidenceFaultImageModalOpen(true)
  }

  return (
    <StyledFaultHistoryEvidenceFilesContent>
      <StyledFaultHistoryEvidenceFile
        src={evidence.fileURL}
        alt={`evidenceHistoryImage${evidence.fileID}`}
        onClick={handleEvidenceHistoryModal}
      />
      <SingleLineTextEllipsis value={evidence.fileName} characterLength={20} fontSizeInRem={'0.75rem'} />
      <StyledFaultHistoryEvidenceCommentBG>
        {evidence.comment && <StyledFaultHistoryEvidenceComment value={evidence.comment} rowsUntilEllipsis={5} />}
      </StyledFaultHistoryEvidenceCommentBG>
    </StyledFaultHistoryEvidenceFilesContent>
  )
}
export default EvidenceHistoryContent
