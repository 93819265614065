import { format, parseISO } from 'date-fns'

const processDate = (date: string) => {
  const ISO = parseISO(date)
  return format(ISO, 'M/d/yyyy h:mm:ss a')
}

const formatProp = <T>(prop: T | undefined, formatFunction: (value: T) => string, defaultValue = '-'): string => {
  return prop != undefined ? formatFunction(prop) : defaultValue
}

const getBooleanString = (booleanValue: boolean, withCapitalization: boolean = false): string => {
  let result = booleanValue.toString()

  if (withCapitalization) {
    result = result.charAt(0).toUpperCase() + result.slice(1)
  }

  return result
}

export { processDate, formatProp, getBooleanString }
