/**
 * Downloads a CSV file with the given data.
 *
 * @param {string} csvData - The CSV data to be downloaded.
 * @param {string} [csvName='data.csv'] - The name of the CSV file.
 * @returns {void}
 */

const downloadCSV = (csvData: string = '', csvName: string = 'data.csv') => {
  const link = document.createElement('a')
  link.download = csvName
  link.href = window.URL.createObjectURL(new Blob([csvData], { type: 'text/csv' }))
  link.click()
}

const getCSVFilename = (moduleName: string = ''): string => {
  const timestamp = new Date().getTime()
  return `${moduleName.toLowerCase().split(' ').join('_')}_${timestamp}.csv`
}

export { downloadCSV, getCSVFilename }
