import { useFetchDataContext } from '@/contexts/common/fetchDataContext'
import { STORAGE_ERROR_COUNT } from '../localStorageUtils'
import useDeepCompareEffect from './useDeepCompareEffect'

/**
 * Custom hook that cleans up the `STORAGE_ERROR_COUNT` from session storage
 * if the fetchDataStatus is 'success' and the error count equals 1.
 *
 * This hook relies on the `useFetchDataContext` to get the `fetchDataStatus`
 * and `useDeepCompareEffect` to perform a deep comparison of dependencies.
 *
 * - If `STORAGE_ERROR_COUNT` exists in session storage and equals 1.
 * - When `fetchDataStatus` is 'success', the `STORAGE_ERROR_COUNT` is removed from session storage.
 *
 * @function useCleanupStorage
 *
 * @returns {void} - This hook does not return any value.
 */

const useCleanupStorage = () => {
  const { fetchDataStatus } = useFetchDataContext()
  useDeepCompareEffect(() => {
    const errorCount = sessionStorage.getItem(STORAGE_ERROR_COUNT)
    if (errorCount && +errorCount === 1 && fetchDataStatus === 'success') {
      sessionStorage.removeItem(STORAGE_ERROR_COUNT)
    }
  }, [fetchDataStatus])
}

export default useCleanupStorage
