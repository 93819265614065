import { IconButton } from '@skf-internal/ui-components-react'
import { FC, useState } from 'react'
import { Collapse } from './Collapse'
import { MarkAsNormalWrapper, StyledMarkAsNormalRowInnerContainerChildren } from '@/modules/report-status/styled'
import { AssetHistorySnapshot } from '@/models/reportStatus/types'
import { dataTestId } from '@/tests/testid'

interface MarkAsNormal {
  snapshot: AssetHistorySnapshot[]
}

export const MarkAsNormalHistory: FC<MarkAsNormal> = ({ snapshot }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const handleClick = () => {
    setIsOpen((lastState) => !lastState)
  }

  return (
    <StyledMarkAsNormalRowInnerContainerChildren feJustifyContent="space-start" feGap="sm">
      <Collapse collapsedData={snapshot} open={isOpen} />
      <MarkAsNormalWrapper>
        {snapshot.length > 1 && (
          <IconButton
            as="button"
            type="button"
            feSize="sm"
            aria-label={isOpen ? 'Open' : 'Close'}
            onClick={handleClick}
            feIcon={isOpen ? 'chevronUp' : 'chevronDown'}
            style={{ zIndex: 10 }}
            data-testid={dataTestId.iconCondensedAsNormal}
          />
        )}
      </MarkAsNormalWrapper>
    </StyledMarkAsNormalRowInnerContainerChildren>
  )
}
