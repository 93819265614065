import { Row } from '@tanstack/react-table'
import { AssetRow } from '@/models/analysisBacklog/types'
import { FC, useState } from 'react'
import { Checkbox, FONT_SIZES, Flexbox, css } from '@skf-internal/ui-components-react'
import { AssetStatusIcon } from '../../../status-icon'
import { withColumnFilterPopover } from '../../../../../common/table/filters/filter-popover-wrapper'
import { assetStatusString } from '@/modules/analysis-backlog/models'

export const statusFilterFn = (row: Row<AssetRow>, columnId: string, filteredStatus: AssetRow['status'][]) => {
  const columnValue = row.getValue<AssetRow['status']>(columnId)
  return filteredStatus.some((v) => v == columnValue)
}

const StatusCheckbox: FC<{
  status: AssetRow['status']
  checked: boolean
  onChange: (status: AssetRow['status'], checked: boolean) => void
}> = ({ status, checked, onChange }) => {
  return (
    <Flexbox feAlignItems="center" feJustifyContent="space-between" feGap="md">
      <Checkbox
        name={status}
        checked={checked}
        feLabel={assetStatusString[status]}
        onChange={(_, checked) => onChange(status, checked)}
        css={css`
          font-size: 1rem;
        `}
      />
      <AssetStatusIcon value={status} />
    </Flexbox>
  )
}

const StatusFilterPopover: FC<{
  initialValue?: AssetRow['status'][]
  onFilterValueChange: (value?: AssetRow['status'][]) => void
}> = ({ initialValue, onFilterValueChange }) => {
  const [filteredStatus, setFilteredStatus] = useState<AssetRow['status'][]>(initialValue || [])

  const findStatusValue = (value: AssetRow['status']): boolean =>
    filteredStatus.filter((v) => v == value)[0] != undefined

  const handleOnChange = (status: AssetRow['status'], checked: boolean) => {
    let newFilterValue: AssetRow['status'][] | undefined = undefined

    if (checked) {
      newFilterValue = [...filteredStatus, status]
      setFilteredStatus(newFilterValue)
    } else {
      newFilterValue = filteredStatus.filter((v) => v !== status)
      setFilteredStatus(newFilterValue)
    }

    if (newFilterValue.length === 0) {
      newFilterValue = undefined
    }

    onFilterValueChange(newFilterValue)
  }

  return (
    <Flexbox feJustifyContent="center" feFlexDirection="column" feGap="md">
      <StatusCheckbox status="never-reported" checked={findStatusValue('never-reported')} onChange={handleOnChange} />
      <StatusCheckbox status={'normal'} checked={findStatusValue('normal')} onChange={handleOnChange} />
      <StatusCheckbox status={'acceptable'} checked={findStatusValue('acceptable')} onChange={handleOnChange} />
      <StatusCheckbox status={'unsatisfactory'} checked={findStatusValue('unsatisfactory')} onChange={handleOnChange} />
      <StatusCheckbox status={'unacceptable'} checked={findStatusValue('unacceptable')} onChange={handleOnChange} />
      <StatusCheckbox status={'severe'} checked={findStatusValue('severe')} onChange={handleOnChange} />
    </Flexbox>
  )
}

export const StatusFilter = withColumnFilterPopover<AssetRow, AssetRow['status'][]>(
  StatusFilterPopover,
  (filterValue: AssetRow['status'][]) => (
    <ul
      css={css`
        list-style-type: disc;
        list-style-position: inside;
        margin: 0;
        padding: 0;

        & > li {
          font-size: ${FONT_SIZES.md};
        }
      `}
    >
      {filterValue.map((v, index) => (
        <li key={index}>{assetStatusString[v]}</li>
      ))}
    </ul>
  )
)
