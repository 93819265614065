// =================================================================================================
// IMPORTS
// =================================================================================================

import { ColumnCell } from '@/common/table/base-components/column-cell'
import { ColumnDef } from '@/common/table/base-components/column-def'
import { TextSearchFilter, textSearchFilterFn } from '@/common/table/filters/text-search-filter'
import { DevicesSensorsTableDeviceRowData } from '@/models/devicesAndSensors/types'
import TableHeaderColumn from '@/shared/components/TableHeaderColumn'
import { ColumnHelper, Header } from '@tanstack/react-table'
import { FC } from 'react'

// =================================================================================================
// CONSTANTS
// =================================================================================================

const COLUMN_WIDTH_IN_PX = 250

// =================================================================================================
// COLUMN DEF
// =================================================================================================

export const generateNameColumnDefinition = (columnHelper: ColumnHelper<DevicesSensorsTableDeviceRowData>) =>
  columnHelper.accessor('name', {
    id: 'name',
    header: 'Device',
    enableColumnFilter: true,
    filterFn: textSearchFilterFn,
    meta: {
      filterComponent: TextSearchFilter,
    },
    cell: ({ getValue }) => getValue(),
  })

// =================================================================================================
// COLUMN DEF COMPONENT
// =================================================================================================

export const DevicesSensorsTableNameColumnDef: FC = () => <ColumnDef columnWidth={COLUMN_WIDTH_IN_PX} />

// =================================================================================================
// HEADER COMPONENT
// =================================================================================================

export const DevicesSensorsTableNameHeaderColumn: FC<{ header: Header<DevicesSensorsTableDeviceRowData, unknown> }> = ({
  header,
}) => (
  <TableHeaderColumn<DevicesSensorsTableDeviceRowData>
    columnWidth={COLUMN_WIDTH_IN_PX}
    header={header}
    centerValue={false}
  />
)

// =================================================================================================
// CELL COMPONENT
// =================================================================================================

export const DevicesSensorsTableNameColumnCell: FC<{ value: string }> = ({ value }) => (
  <ColumnCell columnWidth={COLUMN_WIDTH_IN_PX} value={value} centerValue={false} />
)
