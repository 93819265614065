export enum AssetStatus {
  'never-reported' = 'Never reported',
  normal = 'Normal',
  acceptable = 'Acceptable',
  unsatisfactory = 'Unsatisfactory',
  unacceptable = 'Unacceptable',
  severe = 'Severe',
}
export interface Recommendation {
  assetID: string
  assetName: string
  assetParent: string
  assetStatus: AssetStatus
  faultID: string
  faultType: string
  raOpenDate: string
  recommendedAction: string
  raDueDate: string
  raComment: string
}
export type RecommendedAction = Recommendation & {
  id: string
  dueInDays: string
}
export type UnresolvedRecommendation = {
  openRecommendationResponse: Recommendation[]
  closedRecommendationResponse: Recommendation[]
}

export enum RecommendationState {
  Closed = 'Closed',
  Open = 'Open',
  DefaultDate = '-',
}
export enum ClosedRecommendedActionState {
  dueInDays = '-99999',
}

export type DateFilter = {
  dateFilterType: 'after' | 'before' | 'between'
  startDate?: Date
  endDate?: Date
}
