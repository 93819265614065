import { AssetRow } from '@/models/analysisBacklog/types'
import { css } from '@emotion/react'
import { Icon, IconProps, SIZES, Tooltip, useTooltip } from '@skf-internal/ui-components-react'
import { FC } from 'react'

const statusToName: Record<AssetRow['status'], string> = {
  'never-reported': 'Never reported',
  normal: 'Normal',
  acceptable: 'Acceptable',
  unsatisfactory: 'Unsatisfactory',
  unacceptable: 'Unacceptable',
  severe: 'Severe',
}

export const AssetStatusIcon: FC<{ value: AssetRow['status']; className?: string }> = ({ value, className }) => {
  const [hoverRef, isHovered] = useTooltip()

  return (
    <div
      css={css`
        width: ${SIZES.s20};
        height: ${SIZES.s20};
      `}
      className={className}
      ref={hoverRef}
    >
      <Icon
        css={css`
          width: auto;
          height: auto;
        `}
        {...iconProps(value)}
        feSize="sm"
      />
      <Tooltip isHovered={isHovered} triggerElement={hoverRef.current}>
        {statusToName[value] ?? 'Invalid data'}
      </Tooltip>
    </div>
  )
}

const iconProps = (value: AssetRow['status']): Pick<IconProps, 'feIcon' | 'feColor'> => {
  switch (value) {
    case 'never-reported':
      return { feIcon: 'circleStatus', feColor: 'gray' }
    case 'normal':
      return { feIcon: 'checkCircle', feColor: 'green' }
    case 'acceptable':
      return { feIcon: 'refresh', feColor: 'green' }
    case 'unsatisfactory':
      return { feIcon: 'error', feColor: 'yellow' }
    case 'unacceptable':
      return { feIcon: 'warning', feColor: 'orange' }
    case 'severe':
      return { feIcon: 'danger', feColor: 'red' }
    default:
      return { feIcon: 'forbidden', feColor: 'red' }
  }
}
