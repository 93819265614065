// =================================================================================================
// IMPORTS
// =================================================================================================

import { ColumnHelper, Header } from '@tanstack/react-table'
import { FC } from 'react'
import { formatProp, processDate } from '@/common/utils'
import { DevicesSensorsTableDeviceRowData } from '@/models/devicesAndSensors/types'
import { ColumnDef } from '@/common/table/base-components/column-def'
import { ColumnCell } from '@/common/table/base-components/column-cell'
import { DateFilter, dateFilterFn } from '@/common/table/filters/date-filter'
import TableHeaderColumn from '@/shared/components/TableHeaderColumn'

// =================================================================================================
// CONSTANTS
// =================================================================================================

const COLUMN_WIDTH_IN_PX = 300

// =================================================================================================
// COLUMN DEF
// =================================================================================================

export const generateLastCommunicationColumnDefinition = (
  columnHelper: ColumnHelper<DevicesSensorsTableDeviceRowData>
) =>
  columnHelper.accessor('lastCommunicationDate', {
    id: 'lastCommunicationDate',
    header: 'Last Communication',
    sortingFn: 'datetime',
    enableColumnFilter: true,
    meta: {
      filterComponent: DateFilter,
    },
    filterFn: dateFilterFn<DevicesSensorsTableDeviceRowData>,
    cell: ({ getValue }) => {
      const dateValue = getValue()
      dateValue ? processDate(dateValue) : '-'
    },
  })

// =================================================================================================
// COLUMN DEF COMPONENT
// =================================================================================================

export const DevicesSensorsTableLastCommunicationDateColumnDef: FC = () => (
  <ColumnDef columnWidth={COLUMN_WIDTH_IN_PX} />
)

// =================================================================================================
// HEADER COMPONENT
// =================================================================================================

export const DevicesSensorsTableLastCommunicationDateHeaderColumn: FC<{
  header: Header<DevicesSensorsTableDeviceRowData, unknown>
}> = ({ header }) => (
  <TableHeaderColumn<DevicesSensorsTableDeviceRowData>
    columnWidth={COLUMN_WIDTH_IN_PX}
    header={header}
    centerValue={false}
  />
)

// =================================================================================================
// CELL COMPONENT
// =================================================================================================

export const DevicesSensorsTableLastCommunicationDateColumnCell: FC<{ value?: string }> = ({ value }) => (
  <ColumnCell
    columnWidth={COLUMN_WIDTH_IN_PX}
    value={formatProp<string>(value, (prop) => processDate(prop))}
    centerValue={false}
  />
)
