import { css } from '@emotion/react'

import AuthConsumer from '@/shared/components/AuthConsumer'
import GenericLoader from '@/shared/components/GenericLoader'
import RouterConsumer from '@/shared/components/RouterConsumer'
import { ENV } from '@/shared/constants'
import { useEffectOnce } from '@/shared/hooks/useEffectOnce'
import { checkDataTestValuesUniqueness } from '@/tests/testid'
import { IPublicClientApplication } from '@azure/msal-browser'
import { MsalProvider } from '@azure/msal-react'
import { COLORS, Global, SkfUiProvider } from '@skf-internal/ui-components-react'
import { FC, Suspense } from 'react'
import { CenteredLoader } from '../common/centered-loader'

interface AppProps {
  authInstance: IPublicClientApplication
}

export const App: FC<AppProps> = ({ authInstance }) => {
  if (import.meta.env.MODE !== ENV.PROD) {
    console.log(`%cCURRENT RELEASE: %c${APP_VERSION}`, 'color: black; font-weight: bold;', 'color: green;')
  }

  useEffectOnce(() => {
    checkDataTestValuesUniqueness()
  })

  return (
    <Suspense fallback={<CenteredLoader />}>
      <MsalProvider instance={authInstance}>
        <SkfUiProvider>
          <Global
            styles={css`
              #root {
                min-height: 100vh;
                display: flex;
                flex-direction: column;
                background-color: ${COLORS.gray050};
              }

              * {
                transition-duration: unset !important;
                animation-delay: unset !important;
                animation-duration: unset !important;
              }

              body {
                overflow: hidden;
              }

              @media (width <= 1280px) {
                h1 {
                  font-size: 22px !important;
                }

                [role='tablist'] {
                  font-size: 12px !important;
                }

                label {
                  font-size: 12px !important;
                }

                [data-comp='select'] {
                  [data-comp='input-wrapper'] {
                    font-size: 12px !important;
                  }
                }
              }
            `}
          />
          <AuthConsumer>
            <RouterConsumer />
          </AuthConsumer>
          <GenericLoader />
        </SkfUiProvider>
      </MsalProvider>
    </Suspense>
  )
}
