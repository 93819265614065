import { TupleUnion } from '@/common/types'
import { EvidenceImageData } from '@/models/reportStatus/evidenceTypes'
import {
  Fault,
  FaultState,
  OpenFault,
  NewFault,
  RecommendedAction,
  RecommendedActionState,
  ClosedFault,
  NewRecommendedAction,
  OpenRecommendedAction,
  ClosedRecommendedAction,
  Status,
} from '@/models/reportStatus/faultsFormStateTypes'
import {
  AssetHistoryClosedRecommendedAction,
  AssetHistoryOpenRecommendedAction,
  AssetHistorySnapshot,
  ClosedFaultSnapshot,
  DataEntry,
  FaultHistoricMetadata,
  FaultsAndHistory,
  FaultWithMetadata,
  HalCustomerNoteData,
  NewFaultSnapshot,
  OpenFaultMetadata,
  OpenFaultSnapshot,
  OpenFaultWithMetadata,
  PublishReportClosedRecommendedAction,
  PublishReportCreatedRecommendedAction,
  PublishReportData,
  PublishReportOngoingRecommendedAction,
  ResponseCustomerNotes,
  ResponseReport,
  ResponseReportsHistory,
  SkfCustomerNoteData,
} from '@/models/reportStatus/types'
import { mapResponseFaultEvidences } from '@/modules/report-status/utils/evidenceUtils'
import { DateFormats, formatDate, moveDatePartToStart, parseDate } from '@/shared/dateUtils'
import { maxBy } from '@/shared/utils'

const sortFaultId = (stringId1: string, stringId2: string) => {
  const fault1Id = parseInt(stringId1.split('-')[1])
  const fault2Id = parseInt(stringId2.split('-')[1])
  return fault1Id - fault2Id
}

const sortRecommendedActionId = (stringId1: string, stringId2: string) => {
  const fault1Id = parseInt(stringId1)
  const fault2Id = parseInt(stringId2)
  return fault1Id - fault2Id
}

const getOutcome = (action: string): ClosedRecommendedAction['outcome'] => {
  const outcomeString = action.split('_')[1]
  return outcomeString === 'completed' ? 'completed' : 'rejected'
}

const getCorrectDiagnostic = (action: string): boolean => {
  const correctDiagnosticString = action.split('_')[1]
  return correctDiagnosticString === 'correct'
}

const getOpenRecommendedActionState = (
  action: Exclude<
    ResponseReportsHistory['reports'][number]['faults'][number]['RecommendedActions'][number]['action'],
    'closed_rejected' | 'closed_completed'
  >
): Exclude<RecommendedAction['state'], 'closed' | 'closed_last_report'> => {
  if (action === 'created' || action === 'unchanged' || action === 'updated') {
    return RecommendedActionState.OPEN
  }

  return RecommendedActionState.NEW
}

const mapOpenRecommendedActions = (
  recommendedActions: ResponseReportsHistory['reports'][number]['faults'][number]['RecommendedActions'],
  username: string
): RecommendedAction[] => {
  return recommendedActions
    .sort(({ id: ra1IdString }, { id: ra2IdString }) => sortRecommendedActionId(ra1IdString, ra2IdString))
    .map((v) => {
      if (v.action === 'closed_completed' || v.action === 'closed_rejected' || v.outcome) {
        return {
          additionalNotes: v.comment,
          workOrder: v.workOrder,
          closeDate: formatDate(v.closeDate!, DateFormats.UTCFormat),
          dueDate: formatDate(v.dueDate, DateFormats.AmericanDateFormat),
          createdDate: formatDate(v.createdDate, DateFormats.UTCFormat),
          id: v.id,
          outcome: v.outcome ?? getOutcome(v.action),
          recommendedAction: v.recommendation,
          state: RecommendedActionState.CLOSED,
          username: username,
        }
      } else {
        return {
          additionalNotes: v.comment,
          workOrder: v.workOrder,
          dueDate: formatDate(v.dueDate, DateFormats.AmericanDateFormat),
          createdDate: formatDate(v.createdDate, DateFormats.UTCFormat),
          id: v.id,
          recommendedAction: v.recommendation,
          state: getOpenRecommendedActionState(v.action),
          username: username,
        } as NewRecommendedAction | OpenRecommendedAction
      }
    })
}

const mapHistoryRecommendedActions = (
  recommendedActions: ResponseReportsHistory['reports'][number]['faults'][number]['RecommendedActions'],
  username: string
): (AssetHistoryOpenRecommendedAction | AssetHistoryClosedRecommendedAction)[] => {
  return recommendedActions
    .sort(({ id: ra1IdString }, { id: ra2IdString }) => sortRecommendedActionId(ra1IdString, ra2IdString))
    .map((v) => {
      if (v.action === 'closed_completed' || v.action === 'closed_rejected' || v.outcome) {
        return {
          additionalNotes: v.comment,
          workOrder: v.workOrder,
          closeDate: v.closeDate,
          dueDate: v.dueDate,
          outcome: v.outcome ?? getOutcome(v.action),
          recommendedAction: v.recommendation,
          state: 'closed',
          user: {
            name: username,
          },
        } as AssetHistoryClosedRecommendedAction
      } else {
        return {
          additionalNotes: v.comment,
          workOrder: v.workOrder,
          dueDate: v.dueDate,
          recommendedAction: v.recommendation,
          state: 'open',
        } as AssetHistoryOpenRecommendedAction
      }
    })
}

const mapHistoryFaults = (
  faults: ResponseReportsHistory['reports'][number]['faults'],
  username: string
): ((NewFaultSnapshot | OpenFaultSnapshot | ClosedFaultSnapshot) & { metadata: FaultHistoricMetadata })[] => {
  return faults
    ?.sort(({ id: fault1IdString }, { id: fault2IdString }) => sortFaultId(fault1IdString, fault2IdString))
    .map((v) => {
      let fault: NewFaultSnapshot | OpenFaultSnapshot | ClosedFaultSnapshot

      if (v.action === 'closed_correct' || v.action === 'closed_wrong') {
        fault = {
          collectionDate: v.collectionDate,
          createdDate: v.createdDate,
          correctDiagnostic: getCorrectDiagnostic(v.action),
          explanation: v.wrongDiagnosisExplanation,
          fault: v.type,
          id: v.id,
          observation: v.observation,
          recommendedActions: mapHistoryRecommendedActions(v.RecommendedActions, username),
          evidences: v.evidences,
          state: 'closed',
          status: 'normal',
        } as ClosedFaultSnapshot
      } else if (v.action === 'unchanged' || v.action === 'updated') {
        fault = {
          collectionDate: v.collectionDate,
          createdDate: v.createdDate,
          fault: v.type,
          id: v.id,
          observation: v.observation,
          recommendedActions: mapHistoryRecommendedActions(v.RecommendedActions, username),
          evidences: v.evidences,
          state: 'open',
          status: v.status,
        } as OpenFaultSnapshot
      } else {
        fault = {
          collectionDate: v.collectionDate,
          fault: v.type,
          id: v.id,
          observation: v.observation,
          recommendedActions: mapHistoryRecommendedActions(v.RecommendedActions, username),
          evidences: v.evidences,
          state: 'new',
          status: v.status,
        } as NewFaultSnapshot
      }

      return {
        ...fault,
        metadata: {
          customerNotes: [],
        },
      }
    })
}

const mapAssetFaultsAndHistoryResponse = (reportsHistory: ResponseReportsHistory): FaultsAndHistory => {
  let result: FaultsAndHistory = {
    openFaults: [],
    history: [],
  }

  if (reportsHistory?.reports.length > 0) {
    const lastReport = reportsHistory.reports.reduce((a, b) =>
      parseDate(a.time).getTime() > parseDate(b.time).getTime() ? a : b
    ) // GET LAST REPORT
    const lastReportOpenFaults: {
      fault: OpenFault
      metadata: OpenFaultMetadata
    }[] = lastReport.faults
      ?.filter((v) => v.action === 'created' || v.action === 'updated' || v.action === 'unchanged')
      .sort(({ id: fault1IdString }, { id: fault2IdString }) => sortFaultId(fault1IdString, fault2IdString))
      .map((v) => ({
        fault: {
          collectionDate: formatDate(v.collectionDate, DateFormats.AmericanDateFormat),
          createdDate: formatDate(v.createdDate, DateFormats.AmericanDateFormat),
          fault: v.type,
          recommendedActions: mapOpenRecommendedActions(v.RecommendedActions, lastReport.userName),
          id: v.id,
          observation: v.observation,
          state: FaultState.OPEN,
          status: v.status,
          evidences: mapResponseFaultEvidences(
            v.evidences?.map(
              (data: EvidenceImageData['imageInfo']) => ({ imageInfo: { ...data } }) as EvidenceImageData
            ),
            v.id
          ),
        } as OpenFault,
        metadata: {
          customerNotes: [],
        },
      }))

    result = {
      openFaults: lastReportOpenFaults,
      history: reportsHistory.reports
        .map((v) => ({
          faults: mapHistoryFaults(v.faults, v.userName),
          timestamp: v.time,
          username: v.userName,
          status: v.status,
          reconfirms: v.reconfirms?.map(({ userID, userName, time }) => ({
            time,
            userName,
            userId: userID,
          })),
        }))
        .sort((a, b) => parseDate(a.timestamp).getMilliseconds() - parseDate(b.timestamp).getMilliseconds())
        .reverse(),
    }
  }
  return result
}

const mapCustomerNotes = (customerNotesResponse: ResponseCustomerNotes): HalCustomerNoteData[] => {
  let result: HalCustomerNoteData[] = []

  if (customerNotesResponse) {
    result = Object.entries(customerNotesResponse.faultsNotes).flatMap((noteArray) => {
      return noteArray[1].map((note): HalCustomerNoteData => {
        return {
          idNote: note.id,
          isInvestigatingAlert: note.isInvestigatingAlert,
          isInvestigatingSKF: note.IsSKFInvestigating,
          isResolvedIdNoteSKF: note.isResolvedIDNoteSKF,
          isSkf: false,
          acknowledgment: note.acknowledged,
          droplistLevel1to4SKF: note.droplistLevel1to4SKF,
          droplistLevel5SKF: note.droplistLevel5SKF,
          noteDate: note.composedAt,
          notes: note.message,
          engineHour: note.engineHour,
          SAP: note.sap,
          typeOfAlert: note.alertType,
          orphan: note.orphan,
          databaseName: note.databaseName,
        }
      })
    })
  }

  return result
}

const findFaultCustomerNotes = (
  faultId: string,
  customerNotesData: HalCustomerNoteData[],
  assetId: string | undefined
): HalCustomerNoteData[] => {
  return customerNotesData.filter((cn) => {
    const [idAsset, idFault] = cn.idNote.split('#')
    return idAsset === assetId && idFault === faultId && cn.isSkf === false && cn.orphan === false
  })
}

const getOpenFaultByIndex = (
  faultIndex: number,
  faultData: FaultsAndHistory,
  customerNotesData: HalCustomerNoteData[],
  assetId: string | undefined
): OpenFaultWithMetadata | undefined => {
  const fault = faultData.openFaults.find((_, i) => i === faultIndex)

  return (
    fault && {
      fault: {
        ...fault.fault,
      },
      metadata: {
        customerNotes: findFaultCustomerNotes(fault.fault.id, customerNotesData, assetId),
      },
    }
  )
}

const getReversedHistory = (history: AssetHistorySnapshot[]) => history.slice().reverse()

const getFaults = (
  faultData: FaultsAndHistory,
  customerNotesData: HalCustomerNoteData[],
  assetId: string | undefined
) => {
  const resultData: FaultWithMetadata[] = [
    ...faultData.history.flatMap((assetHistorySnapshot) => {
      return assetHistorySnapshot.faults.map((f) => ({
        fault: {
          ...f,
        },
        metadata: {
          customerNotes: findFaultCustomerNotes(f.id, customerNotesData, assetId),
        },
      }))
    }),
  ]
  return resultData
}

const getOpenFaults = (
  faultData: FaultsAndHistory,
  customerNotesData: HalCustomerNoteData[],
  assetId: string | undefined
) =>
  faultData.openFaults?.map(({ fault }) => ({
    fault,
    metadata: {
      customerNotes: findFaultCustomerNotes(fault.id, customerNotesData, assetId),
    },
  }))

const worstStatus = (statuses: Status[]): Status => {
  const ranks: TupleUnion<Status> = [
    Status.NORMAL,
    Status.ACCEPTABLE,
    Status.SEVERE,
    Status.UNACCEPTABLE,
    Status.UNSATISFACTORY,
  ]

  return maxBy(statuses, (status) => ranks.indexOf(status)) ?? Status.NORMAL
}

const checkPriority = (faultStatus: string, faultState: string) => {
  switch (faultStatus) {
    case 'acceptable':
      return 'Priority 1 - Initial fault'

    case 'unsatisfactory':
      return 'Priority 2 - Plan for action'

    case 'unacceptable':
      return 'Priority 3 - Take action ASAP'

    case 'severe':
      return 'Priority 4 - Take immediate action'

    default:
      if (faultState === 'closed') {
        return 'Priority 0 - Closed'
      }
      return ''
  }
}

const formatSkfCustomerNote = (
  fault: NewFaultSnapshot | OpenFaultSnapshot | ClosedFaultSnapshot | OpenFault,
  reportTimeStamp: string,
  userName: string
): SkfCustomerNoteData => {
  return {
    fault: fault.fault,
    recommendedActions: fault.recommendedActions.map((rA) => {
      return {
        code: rA.recommendedAction,
        additionalNotes: rA.additionalNotes,
        workOrder: rA.workOrder,
      }
    }),
    status: fault.status === 'normal' ? 'closed' : 'open',
    comments: fault.observation,
    collectionDate: fault.collectionDate,
    noteDate: reportTimeStamp,
    noteTitle: fault.fault,
    userName: userName,
    isSkf: true,
    idNote: fault.id + '-' + reportTimeStamp,
    priority: checkPriority(fault.status, fault.state),
    component: '',
    priorityColor: '',
    acknowledgment: true,
  }
}

function mapCreatedFault(fault: NewFault): PublishReportData['faults']['created'][number] {
  const createdRecommendedActions: PublishReportCreatedRecommendedAction[] = []

  fault.recommendedActions.forEach((r) => {
    if (r.state === 'new') {
      createdRecommendedActions.push({
        recommendation: r.recommendedAction,
        dueDate: formatDate(r.dueDate.split('T')[0], DateFormats.ISO8601Date),
        comment: r.additionalNotes,
        workOrder: r.workOrder,
      })
    }
  })

  return {
    status: fault.status,
    type: fault.fault,
    collectionDate: formatDate(fault.collectionDate.split('T')[0], DateFormats.ISO8601Date),
    observation: fault.observation,
    recommendedActions: {
      created: createdRecommendedActions,
    },
    evidences: fault.evidences.map((evidence) => ({
      fileID: evidence.imageInfo.fileID,
      comment: evidence.imageInfo.comment,
    })),
  }
}

function mapOngoingFault(fault: OpenFault): PublishReportData['faults']['ongoing'][number] {
  const createdRecommendedActions: PublishReportCreatedRecommendedAction[] = []
  const ongoingRecommendedActions: PublishReportOngoingRecommendedAction[] = []
  const closedRecommendedActions: PublishReportClosedRecommendedAction[] = []

  fault.recommendedActions.forEach((r) => {
    if (r.state === RecommendedActionState.NEW) {
      createdRecommendedActions.push({
        recommendation: r.recommendedAction,
        dueDate: formatDate(r.dueDate.split('T')[0], DateFormats.ISO8601Date),
        comment: r.additionalNotes,
        workOrder: r.workOrder,
      })
    } else if ([RecommendedActionState.OPEN, RecommendedActionState.EDIT].includes(r.state)) {
      ongoingRecommendedActions.push({
        id: r.id,
        dueDate: formatDate(r.dueDate.split('T')[0], DateFormats.ISO8601Date),
        comment: r.additionalNotes,
        workOrder: r.workOrder,
      })
    } else if ([RecommendedActionState.CLOSED, RecommendedActionState.PENDING_CLOSE].includes(r.state)) {
      const closeDate = (r as ClosedRecommendedAction).closeDate
      closedRecommendedActions.push({
        id: r.id,
        dueDate: formatDate(r.dueDate.split('T')[0], DateFormats.ISO8601Date),
        comment: r.additionalNotes,
        workOrder: r.workOrder,
        outcome: (r as ClosedRecommendedAction).outcome,
        closeDate: formatDate(closeDate.split('T')[0], DateFormats.ISO8601Date),
      })
    }
  })

  return {
    id: fault.id,
    status: fault.status,
    type: fault.fault,
    collectionDate: formatDate(fault.collectionDate.split('T')[0], DateFormats.ISO8601Date),
    observation: fault.observation,
    recommendedActions: {
      created: createdRecommendedActions,
      ongoing: ongoingRecommendedActions,
      closed: closedRecommendedActions,
    },
    evidences: fault.evidences.map((evidence) => ({
      fileID: evidence.imageInfo.fileID,
      comment: evidence.imageInfo.comment,
    })),
  }
}

function mapClosedFault(fault: ClosedFault): PublishReportData['faults']['closed'][number] {
  const closedRecommendedActions: PublishReportClosedRecommendedAction[] = []

  fault.recommendedActions.forEach((r) => {
    const closeDate = (r as ClosedRecommendedAction).closeDate
    console.log(closeDate)

    closedRecommendedActions.push({
      id: r.id,
      dueDate: formatDate(r.dueDate.split('T')[0], DateFormats.ISO8601Date),
      comment: r.additionalNotes,
      workOrder: r.workOrder,
      outcome: r.outcome,
      closeDate: formatDate(closeDate.split('T')[0], DateFormats.ISO8601Date),
    })
  })

  if (fault.correctDiagnostic === true) {
    return {
      id: fault.id,
      collectionDate: formatDate(fault.collectionDate.split('T')[0], DateFormats.ISO8601Date),
      observation: fault.observation,
      correctDiagnosis: true,
      wrongDiagnosisExplanation: '',
      recommendedActions: {
        closed: closedRecommendedActions,
      },
      evidences: fault.evidences.map((evidence) => ({
        fileID: evidence.imageInfo.fileID,
        comment: evidence.imageInfo.comment,
      })),
    }
  } else {
    return {
      id: fault.id,
      collectionDate: formatDate(fault.collectionDate.split('T')[0], DateFormats.ISO8601Date),
      observation: fault.observation,
      correctDiagnosis: false,
      wrongDiagnosisExplanation: fault.explanation,
      recommendedActions: {
        closed: closedRecommendedActions,
      },
      evidences: fault.evidences.map((evidence) => ({
        fileID: evidence.imageInfo.fileID,
        comment: evidence.imageInfo.comment,
      })),
    }
  }
}

const buildPublishedReport = (faults: Fault[], lastReportDateTime?: string): PublishReportData => {
  const createdFaults: PublishReportData['faults']['created'] = []
  const ongoingFaults: PublishReportData['faults']['ongoing'] = []
  const closedFaults: PublishReportData['faults']['closed'] = []

  faults.forEach((f) => {
    if (f.state === FaultState.NEW) {
      createdFaults.push(mapCreatedFault(f))
    } else if ([FaultState.OPEN, FaultState.EDIT].includes(f.state)) {
      ongoingFaults.push(mapOngoingFault(f as OpenFault))
    } else {
      closedFaults.push(mapClosedFault(f as ClosedFault))
    }
  })

  return {
    baseReportTime: lastReportDateTime ?? undefined,
    faults: {
      created: createdFaults,
      ongoing: ongoingFaults,
      closed: closedFaults,
    },
  }
}

const mapPublishedReportResponse = (publishedReport: ResponseReport) => ({
  history: [
    {
      faults: mapHistoryFaults(publishedReport.faults, publishedReport.userName),
      timestamp: publishedReport.time,
      username: publishedReport.userName,
      reconfirms: publishedReport.reconfirms.map(({ userID, userName, time }) => ({
        time,
        userName,
        userId: userID,
      })),
    },
  ],
  openFaults: publishedReport.faults
    ?.filter((v) => v.action === 'created' || v.action === 'updated' || v.action === 'unchanged')
    .sort(({ id: fault1IdString }, { id: fault2IdString }) => sortFaultId(fault1IdString, fault2IdString))
    .map((v) => ({
      fault: {
        collectionDate: formatDate(moveDatePartToStart(v.collectionDate, 'd'), DateFormats.UTCFormat),
        createdDate: formatDate(v.createdDate, DateFormats.AmericanDateFormat),
        fault: v.type,
        recommendedActions: mapOpenRecommendedActions(v.RecommendedActions, publishedReport.userName),
        id: v.id,
        observation: v.observation,
        state: FaultState.OPEN,
        status: v.status,
      } as OpenFault,
      metadata: {
        customerNotes: [],
      },
    })),
})

/**
 * Groups consecutive `normal` entries in the provided data into arrays and returns a mixed array of grouped and individual entries.
 *
 * @param {AssetHistorySnapshot[]} data - An array of asset history snapshots.
 * @returns {(DataEntry | DataEntry[])[]} - A result array where consecutive `normal` entries are grouped into arrays, and other entries are kept individually.
 */

const convertStructure = (data: AssetHistorySnapshot[]): (DataEntry | DataEntry[])[] => {
  return data
    .reduce<{
      result: (DataEntry | DataEntry[])[]
      tempGroup: DataEntry[]
    }>(
      (acc, entry, index) => {
        if (entry.status === 'normal' && entry.faults.length === 0) {
          if (!acc.tempGroup) acc.tempGroup = []
          acc.tempGroup.push(entry)
        } else {
          if (acc.tempGroup && acc.tempGroup.length > 0) {
            acc.result.push([...acc.tempGroup])
            acc.tempGroup = []
          }
          acc.result.push(entry)
        }
        if (index === data.length - 1 && acc.tempGroup && acc.tempGroup.length > 0) {
          acc.result.push([...acc.tempGroup])
        }

        return acc
      },
      { result: [], tempGroup: [] }
    )
    .result.toReversed()
}

/**
 * Maps an array of faults to their respective statuses.
 * If the fault state is NEW or OPEN, returns the fault's status.
 * Otherwise, returns `Status.NORMAL`.
 *
 * @param {Array<NewFaultSnapshot | OpenFaultSnapshot | ClosedFaultSnapshot & { metadata: FaultHistoricMetadata }>} faults
 *  The array of fault snapshots, each containing fault state, status, and metadata.
 *
 * @returns {Status[]}
 *  An array of statuses corresponding to each fault, defaulting to `Status.NORMAL` for non-active faults.
 */

const mapFaultStatuses = (
  faults: ((NewFaultSnapshot | OpenFaultSnapshot | ClosedFaultSnapshot) & {
    metadata: FaultHistoricMetadata
  })[]
): Status[] => {
  return faults.map((fault) => {
    if ([FaultState.NEW, FaultState.OPEN].includes(fault.state as FaultState)) {
      return fault.status as Status
    }
    return Status.NORMAL
  })
}

/**
 * Determines the worst status from a snapshot's faults.
 * Maps the fault statuses using `mapFaultStatuses` and then determines the worst status.
 *
 * @param {DataEntry} snapshot
 *  The data entry containing fault information to process.
 *
 * @returns {Status}
 *  The worst status among the faults, or `Status.NORMAL` if there are no active faults.
 */

const getWorstStatus = (snapshot: DataEntry): Status => {
  const faultStatuses = mapFaultStatuses(snapshot.faults)
  return worstStatus(faultStatuses)
}

export {
  mapAssetFaultsAndHistoryResponse,
  mapCustomerNotes,
  getOpenFaultByIndex,
  getReversedHistory,
  getFaults,
  getOpenFaults,
  worstStatus,
  formatSkfCustomerNote,
  buildPublishedReport,
  mapPublishedReportResponse,
  findFaultCustomerNotes,
  convertStructure,
  getWorstStatus,
  mapFaultStatuses,
}
