export enum FaultStatusType {
  Severe = 'severe',
  Unacceptable = 'unacceptable',
  Unsatisfactory = 'unsatisfactory',
  Normal = 'normal',
  Acceptable = 'acceptable',
}

export type AssetHealthFault = {
  id: string
  name: string
  status: FaultStatusType
  lastFaultCreatedDate: string
  faults: Faults[]
}

export type Faults = {
  id: string
  observation: string
  recommendedActions: RecommendedAction[]
}
export type RecommendedAction = {
  id: number
  openDate: string
  additionalNotes: string
  recommendation: string
  workOrder: string
  dueDate: string
  createdDate: string
}

export type FaultsApiData = {
  faultType: string
  count: number
  assets: AssetHealthFault[]
}
