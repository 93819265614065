import { Fault } from '@/models/reportStatus/faultsFormStateTypes'
import { useAsync } from '@/shared/hooks/useAsync'

export enum LoadingType {
  GLOBAL,
  HEADER,
  NONE,
  WIDGET,
}

export interface UseApiProps {
  loaderType?: LoadingType
  postData?: Fault[] | string[] | string
  assetId?: string
  widgetType?: string
}

export function useApi<T>(request: (props?: UseApiProps) => Promise<T>) {
  const [state, methods] = useAsync(request)

  return {
    ...state,
    ...methods,
  }
}
