import { css } from '@emotion/react'
import { IconButton } from '@skf-internal/ui-components-react'
import { FC, ReactNode, useState } from 'react'

const ToggleButton: FC<{ className?: string; open: boolean; onToggle: () => void }> = ({
  className,
  open,
  onToggle,
}) => (
  <div className={className}>
    {open ? (
      <IconButton as="button" aria-label="Hide" feIcon="chevronUp" onClick={onToggle} feSize="md" />
    ) : (
      <IconButton as="button" aria-label="Show" feIcon="chevronDown" onClick={onToggle} feSize="md" />
    )}
  </div>
)

export const Collapsible: FC<{ header: ReactNode; children: ReactNode; toggleButtonBackgroundColor?: string }> = ({
  header,
  children,
  toggleButtonBackgroundColor = '#FFF',
}) => {
  const [open, setOpen] = useState(false)

  return (
    <div
      css={css`
        display: grid;
        width: 100%;
        grid-template-columns: auto 40px;
        grid-template-rows: min-content auto;
      `}
    >
      {header}
      <ToggleButton
        css={css`
          background-color: ${toggleButtonBackgroundColor};
          display: flex;
          align-items: center;
          justify-content: center;
        `}
        open={open}
        onToggle={() => setOpen((open) => !open)}
      />
      <div
        css={css`
          grid-column: 1 / 3;
        `}
      >
        {open ? children : null}
      </div>
    </div>
  )
}
