export enum Roundness {
  Sm = '0.125rem',
  Base = '0.25rem',
  Md = '0.375rem',
  Lg = '0.5rem',
}

export enum Boldness {
  Light = 300,
  Regular = 400,
  Normal = 500,
  Bold = 700,
}
