import { useRef, useMemo, DependencyList } from 'react'
import { isEqual } from '@/shared/utils'

const useDeepCompareMemo = <T>(callback: () => T, dependencies: DependencyList): T => {
  const ref = useRef<DependencyList>(dependencies)

  if (!isEqual(ref.current, dependencies)) {
    ref.current = dependencies
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(callback, [ref.current])
}

export default useDeepCompareMemo
