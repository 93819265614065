import { useTypedSelector } from '@/store/store'
import { Flexbox, SPACINGS, css } from '@skf-internal/ui-components-react'
import HeaderTitle from './title'
import HeaderInfoPanel from '@/shared/components/ModuleHeader/infoPanel'
import HeaderSubtitle from '@/shared/components/ModuleHeader/subtitle'
import HeaderActionPanel from '@/shared/components/ModuleHeader/actionPanel'

/**
 * Module header is unified header for all components
 *
 * The component consists of 5 areas
 *  - Header area
 *  - BackButton area
 *  - Info panel area
 *  - Action panel area
 *  - Subtitle area
 *
 * Each area can be hidden and visible via headerConfig reducer
 * @returns {JSX.Element} The rendered component.
 */

const ModuleHeader = () => {
  const {
    moduleHeaderData: { headerConfig },
  } = useTypedSelector((store) => ({ ...store }))

  const { showHeader, /*showBackButton,*/ showInfoPanel, showActionPanel, showSubtitle } = headerConfig

  return (
    showHeader && (
      <Flexbox
        feFlexDirection="column"
        css={css`
          gap: ${SPACINGS.lg};
        `}
      >
        {/* {showBackButton && <HeaderTitle />} */}
        {/* {showSubtitle && <BackToOverviewButton />} */}
        {showSubtitle && <HeaderTitle />}
        <Flexbox feAlignItems="center" feJustifyContent="space-between">
          {/* {!showSubtitle && ((showBackButton && <BackToOverviewButton />) || <HeaderTitle />)} */}
          {!showSubtitle && <HeaderTitle />}
          {showSubtitle && <HeaderSubtitle />}
          {showInfoPanel && <HeaderInfoPanel />}
          {showActionPanel && <HeaderActionPanel />}
        </Flexbox>
      </Flexbox>
    )
  )
}

export default ModuleHeader
