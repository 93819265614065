import { FetchDataContextProvider, useFetchDataContext } from '@/contexts/common/fetchDataContext'
import {
  UnresolvedRecommendationsContextProvider,
  useUnresolvedRecommendationsContext,
} from '@/contexts/unresolvedRecommendations'
import {
  ClosedRecommendedActionState,
  RecommendationState,
  UnresolvedRecommendation as UnresolvedRecommendationData,
} from '@/models/unresolvedRecommendations/types'
import UnresolvedRecommendationsHeader from '@/modules/unresolved-recommendation/components/header'
import RecommendationTable from '@/modules/unresolved-recommendation/components/recommendation-table/table'
import { getMappedValue } from '@/modules/unresolved-recommendation/components/table-cell/cell'
import useGetUnresolvedRecommendations from '@/modules/unresolved-recommendation/hooks/useGetUnresolvedRecommendations'
import { calculateDueDays, getStatusType } from '@/modules/unresolved-recommendation/utils'
import { unresolvedRecommendationTableDefinition } from '@/modules/unresolved-recommendation/utils/tableUtils'
import DataRefresher from '@/shared/components/DataRefresher'
import { DateFormats, formatDate } from '@/shared/dateUtils'
import useApplyTableFiltersAndSorting from '@/shared/hooks/useApplyTableFiltersAndSorting'
import useDeepCompareEffect from '@/shared/hooks/useDeepCompareEffect'
import useGetReportFaultTypes from '@/shared/hooks/useGetReportFaultTypes'
import {
  ColumnFiltersState,
  SortingState,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { LoadingType } from '@/shared/hooks/useApi'
import { StyledUnresolvedRecommendationsRoot } from '@/modules/unresolved-recommendation/styled'
import { createCustomSortHandler, extractDateFromTimestamp } from '@/shared/utils'
import { useParams } from 'react-router-dom'
import useCleanupStorage from '@/shared/hooks/useCleanupStorage'

const UnresolvedRecommendation: FC = () => {
  return (
    <UnresolvedRecommendationsContextProvider>
      <FetchDataContextProvider>
        <StyledUnresolvedRecommendationsRoot>
          <InnerUnresolvedRecommendation />
        </StyledUnresolvedRecommendationsRoot>
      </FetchDataContextProvider>
    </UnresolvedRecommendationsContextProvider>
  )
}

const InnerUnresolvedRecommendation: FC = () => {
  const [showClosed, setShowClosed] = useState<boolean>(false)
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
  const [isTableFiltered, setIsTableFiltered] = useState<boolean>(false)
  const [sorting, setSorting] = useState<SortingState>([
    {
      id: 'dueInDays',
      desc: true,
    },
  ])
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  })

  const { sid } = useParams()
  const { unresolvedRecommendations } = useUnresolvedRecommendationsContext()
  const { setFetchDataFn, setFetchDataStatus } = useFetchDataContext()
  const { execute, status } = useGetUnresolvedRecommendations()

  const { execute: getReportFaultTypes, result: faultTypes } = useGetReportFaultTypes()
  const sortingChangeHandler = useMemo(() => createCustomSortHandler(3), [])

  useEffect(() => {
    setFetchDataFn(() => execute)
  }, [execute, setFetchDataFn])

  useEffect(() => {
    if (sid) {
      getReportFaultTypes()
      execute({ loaderType: LoadingType.GLOBAL })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sid])

  useDeepCompareEffect(() => {
    setFetchDataStatus(status)
  }, [status])

  useCleanupStorage()

  const formatTableData = useCallback(
    (data: UnresolvedRecommendationData, type: RecommendationState) => {
      switch (type) {
        case RecommendationState.Open:
          return data.openRecommendationResponse
            .map((data) => {
              return {
                ...data,
                recommendedAction: data.recommendedAction ? data.recommendedAction : '-',
                assetID: data.assetID ? data.assetID : '-',
                assetName: data.assetName ? data.assetName : '-',
                assetParent: data.assetParent ? data.assetParent : '-',
                faultID: data.faultID ? data.faultID : '-',
                raComment: data.raComment ? data.raComment : '-',
                assetStatus: data.assetStatus && getStatusType(data.assetStatus),
                dueInDays: data.raDueDate
                  ? calculateDueDays(
                      formatDate(
                        formatDate(extractDateFromTimestamp(data.raDueDate), DateFormats.UTCFormat),
                        DateFormats.AmericanDateFormat
                      )
                    )
                  : RecommendationState.DefaultDate,
                faultType: data.faultType ? getMappedValue(faultTypes ? faultTypes : [], data.faultType) : '-',
                raOpenDate: data.raOpenDate
                  ? formatDate(extractDateFromTimestamp(data.raOpenDate), DateFormats.AmericanDateFormat)
                  : RecommendationState.DefaultDate,
                raDueDate: data.raDueDate
                  ? formatDate(extractDateFromTimestamp(data.raDueDate), DateFormats.AmericanDateFormat)
                  : RecommendationState.DefaultDate,
              }
            })
            .sort((a, b) => {
              const dueInDaysA = typeof a.dueInDays === 'number' ? a.dueInDays : parseInt(a.dueInDays)
              const dueInDaysB = typeof b.dueInDays === 'number' ? b.dueInDays : parseInt(b.dueInDays)

              if (isNaN(dueInDaysA) && isNaN(dueInDaysB)) return 0
              if (isNaN(dueInDaysA)) return 1
              if (isNaN(dueInDaysB)) return -1
              return dueInDaysA - dueInDaysB
            })
            .map((data, index) => ({ ...data, id: `${index + 1}`, dueInDays: data.dueInDays.toString() }))
        case RecommendationState.Closed:
          return data.closedRecommendationResponse.map((data) => ({
            ...data,
            id: RecommendationState.Closed,
            assetID: data.assetID ? data.assetID : '-',
            assetName: data.assetName ? data.assetName : '-',
            recommendedAction: data.recommendedAction ? data.recommendedAction : '-',
            assetParent: data.assetParent ? data.assetParent : '-',
            faultID: data.faultID ? data.faultID : '-',
            raComment: data.raComment ? data.raComment : '-',
            assetStatus: data.assetStatus && getStatusType(data.assetStatus),
            dueInDays: ClosedRecommendedActionState.dueInDays,
            faultType: data.faultType ? getMappedValue(faultTypes ? faultTypes : [], data.faultType) : '-',
            raOpenDate: data.raOpenDate
              ? formatDate(formatDate(data.raOpenDate, DateFormats.UTCFormat), DateFormats.AmericanDateFormat)
              : RecommendationState.DefaultDate,
            raDueDate: data.raDueDate
              ? formatDate(formatDate(data.raDueDate, DateFormats.UTCFormat), DateFormats.AmericanDateFormat)
              : RecommendationState.DefaultDate,
          }))
        default:
          return []
      }
    },
    [faultTypes]
  )

  const tableData = useMemo(() => {
    if (status !== 'success') return undefined
    const openFormatData = formatTableData(unresolvedRecommendations, RecommendationState.Open)
    const closedFormatData = formatTableData(unresolvedRecommendations, RecommendationState.Closed)

    return showClosed ? [...openFormatData, ...closedFormatData] : openFormatData
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unresolvedRecommendations, showClosed])

  const table = useReactTable({
    data: tableData || [],
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    columns: unresolvedRecommendationTableDefinition(showClosed),
    state: { pagination, sorting, columnFilters },
    onSortingChange: (updater) => {
      setSorting(sortingChangeHandler(updater))
    },
    onColumnFiltersChange: setColumnFilters,
    enableMultiSort: true,
    isMultiSortEvent: () => true,
    onPaginationChange: setPagination,
    maxMultiSortColCount: 3,
  })

  const resetTableFilters = useCallback(() => {
    table.resetColumnFilters()
    table.resetSorting()
    setShowClosed(false)
    setColumnFilters([])
  }, [table])

  const handleClearFilters = () => {
    resetTableFilters()
    setIsTableFiltered(false)
  }

  useEffect(() => {
    setIsTableFiltered(columnFilters.length === 0 && sorting.length === 0)
  }, [columnFilters, sorting])

  useEffect(() => {
    handleClearFilters()
    setSorting([
      {
        id: 'dueInDays',
        desc: true,
      },
    ])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sid])

  const { tableScrollOffset } = useApplyTableFiltersAndSorting({
    setColumnFilters,
    setIsTableFiltered,
    setSorting,
    setTableState: setShowClosed,
  })

  return (
    <>
      <UnresolvedRecommendationsHeader
        table={table}
        isTableFiltered={isTableFiltered}
        handleClearFilters={handleClearFilters}
        showClosed={showClosed}
        setShowClosed={setShowClosed}
        columnFilters={columnFilters}
      />
      <RecommendationTable table={table} tableScrollOffset={tableScrollOffset} />
      <DataRefresher<UnresolvedRecommendationData> functionToExecute={execute} />
    </>
  )
}

export default UnresolvedRecommendation
