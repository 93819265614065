import { useFetchDataContext } from '@/contexts/common/fetchDataContext'
import { downloadCSV, getCSVFilename } from '@/shared/csvUtils'
import { useTypedSelector } from '@/store/store'
import { dataTestId } from '@/tests/testid'
import { IconButton, Popup, Text, css, useTooltip } from '@skf-internal/ui-components-react'

/**
 * HeaderExportCSVButton is the button that located in header action panel
 * and it responsible for trigger related module download csv function
 *
 * HeaderClearFiltersButton can be configured by size property
 *
 * HeaderClearFiltersButton is disabled when related module fetch function runs
 *
 * HeaderClearFiltersButton have to have csv data and module name in order to create csv file and send him to download
 * @returns {JSX.Element} The rendered component.
 */
const HeaderExportCSVButton = () => {
  const [csvHoveredRef, isCsvHovered] = useTooltip()
  const { fetchDataStatus } = useFetchDataContext()
  const {
    moduleHeaderData: {
      headerCSVData,
      headerActionPanelConfig: { csvDownLoadButtonSize, isAsyncIndicatorShown },
    },
  } = useTypedSelector((store) => ({ ...store }))

  const { csvData, moduleName } = headerCSVData

  return (
    <>
      <IconButton
        aria-disabled={fetchDataStatus === 'loading' && !isAsyncIndicatorShown}
        feIcon="download"
        as="button"
        aria-label="Export"
        feColor="brand"
        onClick={() => downloadCSV(csvData, getCSVFilename(moduleName))}
        ref={csvHoveredRef}
        disabled={fetchDataStatus === 'loading' && !isAsyncIndicatorShown}
        feSize={csvDownLoadButtonSize}
        data-testid={dataTestId.headerExportCSVButton}
      />
      <Popup isHovered={isCsvHovered} triggerElement={csvHoveredRef.current}>
        <Text
          css={css`
            white-space: nowrap;
          `}
        >
          Export CSV
        </Text>
      </Popup>
    </>
  )
}

export default HeaderExportCSVButton
