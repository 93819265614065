import { SkfCustomerNoteData } from '@/models/reportStatus/types'
import { StyledInfoCard } from '@/modules/report-status/styled'
import { format, parseISO } from 'date-fns'
import { FC } from 'react'
import NoteInfo from './NoteInfo'

interface SkfInfoCardProps {
  noteData: SkfCustomerNoteData
}

const SkfInfoCard: FC<SkfInfoCardProps> = ({ noteData }) => {
  return (
    <StyledInfoCard>
      <NoteInfo label="Fault" notes={noteData.fault} />
      <NoteInfo label="Collection Date" notes={format(parseISO(noteData.collectionDate), 'M/d/yyyy HH:mm:ss')} />
      <NoteInfo label="Status" notes={noteData.status} />
      <NoteInfo label="Observation" notes={noteData.comments} />
      <NoteInfo label="Analyst Name" notes={noteData.userName} />
    </StyledInfoCard>
  )
}

export default SkfInfoCard
