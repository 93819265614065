import { AxiosError } from 'axios'
import networkClient from '@/api/NetworkClient'

import { PATHS } from '@/api/types'
import {
  GetUnresolvedRecommendationsRequest,
  GetUnresolvedRecommendationsResponse,
  GetUnresolvedRecommendationsResult,
} from '@/api/paths/unresolvedRecommendations/types'
import { UnresolvedRecommendation } from '@/models/unresolvedRecommendations/types'

const getUnresolvedRecommendations = async (
  handlers: GetUnresolvedRecommendationsResult,
  params: Record<string, string | undefined>
): Promise<UnresolvedRecommendation | void> => {
  try {
    const response = await networkClient.get<GetUnresolvedRecommendationsRequest, GetUnresolvedRecommendationsResponse>(
      PATHS.GET_UNRESOLVED_RECOMMENDATIONS,
      {
        params,
      }
    )
    handlers.onSuccess && (await handlers.onSuccess(response.data))
  } catch (error) {
    handlers.onError && (await handlers.onError(error as AxiosError))
  }
}

export { getUnresolvedRecommendations }
