import { AssetHistorySnapshot } from '@/models/reportStatus/types'
import { StyledReconfirmFlex, StyledReconfirmUsernameSpan } from '@/modules/report-status/styled'
import { DateFormats, formatDate } from '@/shared/dateUtils'
import { FC } from 'react'

interface RowProps {
  collapsedData: AssetHistorySnapshot
}

export const Row: FC<RowProps> = ({ collapsedData }) => {
  return (
    <StyledReconfirmFlex feGap="sm">
      <span>{formatDate(collapsedData.timestamp, DateFormats.AmericanAMPMFormat)}</span>
      <StyledReconfirmUsernameSpan>{collapsedData.username}</StyledReconfirmUsernameSpan>
    </StyledReconfirmFlex>
  )
}
