import { Boldness } from '@/common/styles/constants'
import { TextEllipsis } from '@/common/text-ellipsis'
import { Status, NonNormalStatus, FaultState, RecommendedActionState } from '@/models/reportStatus/faultsFormStateTypes'
import { AssetStatusIcon } from '@/modules/analysis-backlog/status-icon'
import { statusBackgroundColor, statusTextColor } from '@/modules/report-status/utils/constants'
import {
  Alert,
  Button,
  Card,
  Collapse,
  COLORS,
  Flexbox,
  FONT_SIZES,
  FONT_WEIGHTS,
  Heading,
  Icon,
  Loader,
  Popup,
  RadioGroup,
  SIZES,
  SPACINGS,
  styled,
  Text,
  TextAreaField,
} from '@skf-internal/ui-components-react'

/**FORM HEADER STYLED COMPONENTS */
export const StyledReportStatusHeaderContainer = styled(Flexbox)`
  padding: ${SPACINGS.lg};
`
export const StyledAssetNameAndStatusContainer = styled(Flexbox)`
  gap: ${SPACINGS.sm};
`

export const StyledReportStatusHeaderContentContainer = styled(Flexbox)`
  gap: ${SPACINGS.lg};
`

export const StyledReportStatusAssetStatusIcon = styled(AssetStatusIcon)`
  width: ${SIZES.s24};
  height: ${SIZES.s24};
`

export const StyledReportStatusHeaderBelongToLabel = styled.div`
  white-space: nowrap;
`

export const StyledReportStatusHeaderAssetNameLabel = styled(StyledReportStatusHeaderBelongToLabel)`
  font-weight: ${FONT_WEIGHTS.bold};
  display: flex;
  gap: 1rem;
`

export const StyledReportHeaderAnalysisReason = styled.div`
  justify-self: end;
  display: flex;
  flex-flow: row wrap;
  gap: ${SPACINGS.sm};
  grid-column: 5;
  grid-row: 1/3;
  justify-content: flex-end;
`

export const StyledReportHeaderButton = styled(Button)`
  grid-column: 1/3;
  width: min-content;
`
/*****************************************************/

export const StyledReportStatusDialogContainer = styled.div`
  display: grid;
  grid-template-columns: auto;
  height: 100%;
  grid-template-rows: 120px 1fr 80px;
`

export const StyledReportStatusDialogHistoryContainer = styled.div`
  overflow: auto;
  background-color: ${COLORS.gray200};
  padding: ${SPACINGS.lg};
  display: flex;
  flex-direction: column;
  gap: ${SPACINGS.sm};
`

export const StyledReportStatusDialogLoaderContainer = styled.div`
  position: relative;
`
export const StyledReportStatusDialogLoaderBackground = styled.div`
  position: absolute;
  height: 100%;
  width: 100vw;
  background: linear-gradient(180deg, #00000080 0%, #00000080 100%);
  z-index: 1001;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const StyledReportStatusDialogLoader = styled(Loader)`
  display: flex;
  height: 100vh;
  margin: 0 auto;
`
/**FORM FOOTER STYLED COMPONENTS */
export const StyledReportStatusFooter = styled(Flexbox)`
  padding-right: ${SPACINGS.md};
  gap: ${SPACINGS.md};
  border-top: thin solid ${COLORS.gray600};
  background-color: ${COLORS.gray200};
`

export const StyledFooterErrorsAlert = styled(Alert)`
  max-height: 4.375rem;
  overflow: hidden auto;
`
/************************************************/

/**FORM FAULTS STYLED COMPONENTS */
export const StyledReportStatusFaultRow = styled(Flexbox)`
  background-color: ${COLORS.white};
  border: solid 1px ${COLORS.gray400};
  border-radius: 0.25rem 0.25rem 0 0;
  height: auto;
  overflow-y: hidden;
  gap: ${SPACINGS.md};
`
export const StyledReportStatusFaultRowContainer = styled(Flexbox)`
  width: 100%;
`

export const StyledReportStatusFaultRowTitle = styled.div<{ status: Status | NonNormalStatus }>`
  padding: 1rem 0;
  background-color: ${(props) => statusBackgroundColor[props.status ?? Status.NORMAL]};
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  color: ${(props) => statusTextColor[props.status ?? Status.NORMAL]};
  font-weight: ${FONT_WEIGHTS.bold};
  width: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
`

export const StyledReportStatusFaultRowContent = styled(Flexbox)<{ state: FaultState }>`
  padding: ${SPACINGS.md};
  gap: ${({ state }) => (state !== FaultState.OPEN ? '1rem' : '5rem')};
  align-items: flex-start;
  width: 100%;

  & :has([data-comp='text-area-field']):last-of-type {
    width: 40%;
  }
`

export const StyledReportStatusCloseFaultContext = styled(Flexbox)`
  padding: ${SPACINGS.md};
  gap: 5rem;
  align-items: flex-start;
  width: 100%;

  & :has([data-comp='text-area-field']) {
    width: 100%;
  }
`
/*****************************************/

/**FORM FAULTS COMPONENTS STYLED COMPONENTS */
export const StyledComponentLabel = styled.label<{
  isRequired: boolean
  isValid?: boolean
  state: FaultState | RecommendedActionState
}>`
  color: ${({ isValid, state }) =>
    isValid === false && state !== FaultState.NEW.toString() ? COLORS.redBase : COLORS.black};
  display: block;
  font-size: ${FONT_SIZES.sm};
  margin: 0;
  margin-bottom: ${SPACINGS.xxs};
  font-weight: ${FONT_WEIGHTS.bold};
  position: relative;
  white-space: pre;

  &::after {
    content: '*';
    opacity: ${({ isRequired }) => (isRequired ? 1 : 0)};
    margin-left: 5px;
    color: red;
  }
`

export const StyledReportStatusComponent = styled(Flexbox)<{
  state: FaultState | RecommendedActionState
  isValid?: boolean
}>`
  width: ${({ state }) =>
    [
      FaultState.EDIT,
      RecommendedActionState.EDIT,
      FaultState.PENDING_CLOSE,
      RecommendedActionState.PENDING_CLOSE,
    ].includes(state)
      ? '20%'
      : '25%'};

  & [data-comp='input-wrapper'] {
    border: ${({ isValid }) => isValid === false && `1px solid ${COLORS.redBase}`};
    outline: none;
  }

  & [data-comp='date-picker'] {
    margin-top: ${SPACINGS.xxs};

    > div {
      border: ${({ isValid }) => (isValid === false ? `1px solid ${COLORS.redBase}` : '')};
    }
  }

  & [data-comp='text-area-field'] {
    > label {
      margin: 0;
    }
  }
`
export const StyledTextAreaField = styled(TextAreaField)<{
  maxHeight?: string
  maxWidth?: string
  minHeight?: string
  minWidth?: string
}>`
  & textarea {
    max-height: ${({ maxHeight }) => maxHeight ?? 'inherit'};
    max-width: ${({ maxWidth }) => maxWidth ?? 'inherit'};
    min-height: ${({ minHeight }) => minHeight ?? 'inherit'};
    min-width: ${({ minWidth }) => minWidth ?? 'inherit'};
    resize: none;
  }
`

export const StyledRadioButton = styled(RadioGroup)`
  & label {
    font-weight: ${FONT_WEIGHTS.bold};
  }
`

export const StyledComponentErrorText = styled(Flexbox)`
  margin-top: ${SPACINGS.xxs};
  color: ${COLORS.redBase};
  font-weight: ${FONT_WEIGHTS.bold};
  font-size: ${FONT_SIZES.xs};
  visibility: visible;
  min-height: 2rem;
`
/***********************************************/

/**FORM FAULTS MENU STYLED COMPONENTS */
export const FaultsFormMenuPopup = styled(Popup)`
  padding: 0 !important;

  & ul {
    list-style: none;
    margin: 0;
    padding: 0;
    font-size: 1rem;
  }
`

export const FaultFormMenuIcon = styled(Icon)`
  block-size: ${SPACINGS.md};
  display: inline-flex;
  flex-shrink: 0;
  inline-size: ${SPACINGS.md};
  user-select: none;
  color: inherit;
  margin-right: ${SPACINGS.xs};
`

export const StyledFaultMenu = styled(Flexbox)`
  background-color: ${COLORS.brand};
  width: 2rem;
  flex-shrink: 0;
  align-self: stretch;
`

export const MenuButton = styled.button`
  display: flex;
  padding-inline: ${SPACINGS.sm};
  height: ${SIZES.s40};
  align-items: center;
  font-weight: 700;
  text-decoration: none;
  width: 100%;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  color: ${({ disabled }) => (disabled ? COLORS.gray700 : COLORS.brand)};

  &:hover {
    background-color: ${COLORS.primary200};
    color: ${({ disabled }) => (disabled ? COLORS.gray700 : COLORS.primary800)};
  }
`
/*************************************************/

/***FORM RECOMMENDED ACTIONS STYLED COMPONENTS */
export const StyledRecommendedActionContentContainer = styled(Flexbox)`
  background-color: ${COLORS.white};
  border: solid 1px ${COLORS.gray400};
  border-top: none;
  display: flex;

  h2 {
    padding: 0 2rem;
  }
`

export const StyledRecommendedActionRowHeader = styled.div<{ status: Status | NonNormalStatus }>`
  background-color: ${(props) => statusBackgroundColor[props.status ?? Status.NORMAL]};
  align-self: stretch;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${SPACINGS.md} 0;
  width: 4rem;

  span {
    color: ${(props) => statusTextColor[props.status ?? Status.NORMAL]};
  }
`

export const StyledRecommendedActionRowContent = styled(Flexbox)<{ state: RecommendedActionState }>`
  padding: ${SPACINGS.md};
  width: 100%;
  gap: ${({ state }) => (state !== RecommendedActionState.OPEN ? '3rem' : '5rem')};
  overflow-x: ${({ state }) => (state === RecommendedActionState.PENDING_CLOSE ? 'auto' : 'unset')};

  & :has([data-comp='text-area-field']) {
    min-width: ${({ state }) => (state === RecommendedActionState.EDIT ? '40%' : '25%')};
  }

  & :has([data-comp='text-area-field']):nth-last-of-type(-n + 2) {
    min-width: 20%;
  }

  & :has([data-comp='date-picker']) {
    min-width: 10%;
  }

  & :has([data-comp='select']) {
    min-width: 17%;
  }
`
/*************************************************/

/***FORM CLOSED RECOMMENDED ACTIONS STYLED COMPONENTS */
export const StyledClosedRecommendedActionRow = styled(Flexbox)`
  display: flex;
  align-items: center;
  background-color: #aaafb8;
  border: solid 1px ${COLORS.gray400};
  border-top: none;
  justify-content: space-between;

  padding-right: ${SPACINGS.md};
`
export const StyledClosedRecommendedActionRowData = styled(Flexbox)`
  gap: 1.75rem;

  & > * {
    padding: ${SPACINGS.xs};
  }

  & > div:nth-of-type(1) {
    height: 100%;
    width: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    margin-left: ${SPACINGS.xs};
  }

  & > h3 {
    margin-left: ${SPACINGS.xxs};
  }
`

export const StyledClosedRecommendedActionRowOutcome = styled(Heading)`
  font-size: ${FONT_SIZES.md} !important;
`
export const StyledClosedRecommendedActionLabel = styled.div`
  font-size: ${FONT_SIZES.sm};
`
export const StyledClosedRecommendedActionDescription = styled.div`
  word-break: break-word;
  white-space: break-spaces;
  overflow: hidden;
  max-width: 900px;
`
export const StyledClosedRecommendedActionCloseDetails = styled(Flexbox)`
  gap: ${SPACINGS.xxl};
`
/************************************************************* */

/*******************CUSTOMER NOTE STYLED COMPONENTS************/

export const StyledCustomerNoteCollapse = styled(Collapse)`
  background-color: ${COLORS.brand} !important;
  border-radius: 0 0 0.25rem 0.25rem;
  position: relative;

  svg {
    color: ${COLORS.white};
  }

  h4 {
    padding-left: ${SPACINGS.md};
    font-weight: ${FONT_WEIGHTS.normal};
  }

  h4 button {
    color: ${COLORS.white};
  }
`

export const StyledCustomerNoteNotificationContainer = styled.div`
  background-color: ${COLORS.gray025};
  height: 30rem;
  overflow: scroll;
`

export const StyledNewNoteNotificationContainer = styled(Flexbox)`
  position: absolute;
  top: 30%;
  left: 40%;
  color: ${COLORS.white};
  pointer-events: none;
`
export const StyledNoteMessageContainer = styled(Flexbox)`
  padding-top: ${SPACINGS.xxl};
`
export const StyledCustomerIcon = styled.div<{ isSkf: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ isSkf }) => (isSkf ? COLORS.brand : COLORS.redBase)};
  border-radius: 50%;
  width: 4rem;
  height: 4rem;
  color: ${COLORS.white};
  font-weight: ${FONT_WEIGHTS.bold};
  margin-left: ${SPACINGS.md};
`
export const StyledNoteCard = styled(Card)`
  width: 30rem;
  border: none;
  background-color: ${COLORS.yellowBase} !important;
`

export const StyledNodeInfo = styled(Flexbox)`
  word-wrap: break-word;
`

export const StyledInfoCardContainer = styled(Card)`
  width: 30rem;
  border: none;
  background-color: ${COLORS.white};
`

export const StyledInfoCard = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, 170px);
  gap: ${SPACINGS.md};
`
/********************************************************************* */
/******************Styled Evidence************************************ */

export const StyledEvidenceTitleContainer = styled.div`
  font-weight: ${FONT_WEIGHTS.bold};
  margin-bottom: 10px;
`

export const StyledEvidenceDragAndDropContainer = styled.div`
  display: ruby;
  grid-template-columns: 1fr;
`

export const StyledEvidenceTitleSuffix = styled.span`
  font-size: 12px;
  font-weight: ${FONT_WEIGHTS.normal};
  margin-left: 10px;
`

export const StyledUploadImageCardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  background-color: ${COLORS.white};
  gap: ${SPACINGS.md};
  width: 100%;
`

export const StyledUploadImageCard = styled.div`
  background-color: ${COLORS.white};
`

export const StyledEmptyEvidenceImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%; /* Optional, adjust as needed */
  height: 100%; /* Optional, adjust as needed */
`
export const StyledNoEvidenceText = styled.div`
  color: grey;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.25rem;
`

export const StyledEmptyEvidenceImage = styled.img`
  width: 14.375rem;
  height: 8.75rem;
  flex-shrink: 0;
`

export const StyledUploadedImageCard = styled.div<{ isError: boolean }>`
  height: 180px;
  width: 267px;
  border: ${(props) => (props.isError ? '2px solid red' : '2px solid #c7cad0')};
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures the image covers the container while maintaining aspect ratio */
  }

  /* Hover effect to show semi-transparent layer and icons */
  &:hover::before {
    content: '';
    position: absolute;
    inset: 0;
    background-color: #4f84e0;
    opacity: 0.8;
    z-index: 1;
  }

  &:hover .icon-container {
    display: flex;
  }
`

export const StyledIconContainer = styled.div<{ isFaultInEditMode: boolean }>`
  display: none;
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  justify-content: space-around;
  align-items: center;
  padding-left: 50px;
  padding-right: 50px;
  cursor: ${({ isFaultInEditMode }) => !isFaultInEditMode && 'pointer'};

  & > svg {
    fill: white;
    cursor: pointer;
    font-size: 24px;
  }
`

export const StyledCenteredLoader = styled.div`
  height: 20px;
  width: 20px;
`

export const StyledEvidenceImageDetails = styled.div`
  display: flex;
  width: 100%;
  font-size: 12px;
  font-weight: ${FONT_WEIGHTS.normal};
  justify-content: space-between;
  align-items: center;
`

export const StyledUploadedImageContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${SPACINGS.md};
  max-height: 14rem;
  overflow-y: scroll;
`

export const StyledImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 15px;
`

export const StyledErrorIndicator = styled.span`
  color: red;
  font-size: 20px;
  font-weight: 500;
`

export const StyledErrorMessage = styled.p`
  color: red;
  font-size: 12px;
  font-weight: 500;
  margin-top: 4px;
`

export const StyledEvidenceWrapper = styled.div`
  margin: 10px 0;
`
export const StyledEvidenceRowContainer = styled.div<{ status: Status | NonNormalStatus | undefined }>`
  display: grid;
  grid-template-columns: 3rem 1fr 2rem;
  background-color: ${COLORS.white};
  border: solid 1px ${COLORS.gray400};
  border-radius: 0.25rem 0.25rem 0 0;
  height: auto;
  overflow-y: hidden;
  gap: ${SPACINGS.md};

  & > div {
    padding: ${SPACINGS.md} 0;
  }

  & > div:first-of-type {
    padding: 0;
    height: 100%;
    background-color: ${(props) => statusBackgroundColor[props.status ?? 'normal']};
    writing-mode: vertical-rl;
    transform: rotate(180deg);
    color: ${(props) => statusTextColor[props.status ?? 'normal']};
    font-weight: ${FONT_WEIGHTS.bold};
    width: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 4rem;
    flex-shrink: 0;
  }

  & > div:last-of-type {
    padding: 0;
    align-self: stretch;
  }
`

export const StyledEvidenceRowChildrenContainer = styled.div`
  display: grid;
  background-color: ${COLORS.white};
  align-items: start;
  gap: ${SPACINGS.md};
`

export const StyledEvidenceRowMenuBar = styled.div`
  height: 100%;
  background-color: ${COLORS.brand};
  width: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
`

export const StyledDashedBorder = styled.div<{ imageCount: number }>`
  height: 120px;
  width: 223px;
  border: 2px dashed #c7cad0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: ${({ imageCount }) => (imageCount >= 5 ? 'no-drop' : 'pointer')};
`

export const StyledUploadIconContainer = styled.div<{ imageCount: number }>`
  height: 55px;
  width: 55px;
  margin-top: 20px;
  border: 3px solid ${({ imageCount }) => (imageCount >= 5 ? COLORS.gray600 : COLORS.brand)};
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`

export const StyledParagraph = styled.p`
  font-size: ${FONT_SIZES.xs};
`
export const StyledErrorParagraph = styled.p`
  color: red;
  font-size: 12px;
  font-weight: 500;
`

export const StyledIcon = styled(Icon)`
  height: 50px;
  width: 50px;
`

export const StyledInput = styled.input`
  display: none;
`

/*Evidence modal style*/

export const StyledModalContainer = styled.div`
  height: 100%;
`

export const StyledModalImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  background-color: #f6f6f6;
  height: 83%;
`

export const StyledModalImage = styled.img`
  max-width: 70%;
  max-height: 70%;
`

export const StyledModalFooterContainer = styled.div`
  margin-top: auto;
  padding: 10px;
`

export const StyledModalEditContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
`

export const StyledModalTextFieldContainer = styled.div`
  flex-grow: 1;
  margin-right: 30px;
  margin-left: 20px;

  label {
    font-size: 16px;
    font-weight: 700;
  }

  textarea {
    resize: none;
  }
`

export const StyledModalButtonGroup = styled.div`
  display: flex;
  gap: 10px;
  margin-right: 20px;
  margin-top: 24px;
`

export const StyledModalCommentText = styled.p`
  font-size: 16px;
  font-weight: 700;
`

export const StyledModalCommentContainer = styled.div`
  margin-left: 20px;
  margin-top: 24px;
`
/*********************************************************************************** */

/******************History Component Styled Components */

export const StyledHistoryContainer = styled(Flexbox)`
  width: 100%;
  min-width: 1185px;
`

export const StyledHistoryBlock = styled(Flexbox)`
  width: 100%;
`

export const StyledReconfirmRowStartAnchorContainer = styled.div<{ marginTop: string }>`
  margin: ${(props) => props.marginTop};
  height: calc(100% - 1.25rem + 2rem - 2 * ${SPACINGS.xs});
  width: 1rem;
`
export const StyledReconfirmRowContainer = styled(Flexbox)`
  height: 100%;
  width: 100%;
`
export const StyledReconfirmRowInnerContainer = styled(Flexbox)`
  height: 100%;
  min-width: 35%;
  width: 21.5%;
  margin-left: 0.35rem;
`
export const StyledReconfirmRowInnerContainerChildren = styled(Flexbox)`
  height: 100%;
  width: 100%;
`
export const StyledReconfirmRowStartAnchorLine = styled.div`
  background-color: ${COLORS.gray400};
  height: 100%;
  width: 0.125rem;
  margin: 0 auto;
`

export const StyledReconfirmFlex = styled(Flexbox)`
  height: 100%;
  width: 100%;

  > span:first-of-type {
    white-space: nowrap;
  }
`

export const StyledRowWrapper = styled(StyledReconfirmFlex)`
  margin-left: 1.7rem;
`

export const StyledReconfirmUsernameSpan = styled.span`
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const StyledReconfirmCollapseContainer = styled(Flexbox)`
  line-height: ${SIZES.s24};
  margin-top: ${SPACINGS.xxs};
  gap: ${SPACINGS.xxs};
  height: 50%;
`
export const StyledHistoryRowPrefix = styled(Flexbox)`
  height: 100%;
`
export const StyledHistoryRowLeftContainer = styled(Flexbox)`
  height: 100%;
  width: 30%;
`
export const StyledHistoryRowLeftInnerContainer = styled(Flexbox)`
  height: 100%;
`
export const StyledHistoryRowRightContainer = styled(Flexbox)`
  height: 100%;
  width: 70%;
`
export const StyledHistoryRowIconContainer = styled(Flexbox)`
  height: 1.25rem;
`

export const StyledUserUpdateDateContainer = styled(Flexbox)`
  margin-left: 3.7rem;
`
export const StyledFaultHistoryContainer = styled(Flexbox)`
  align-self: flex-start;
  width: 20%;
`
export const StyledFaultHistoryMainContainer = styled(Flexbox)`
  width: 100%;
`
export const StyledFaultHistoryTopContainer = styled(Flexbox)`
  width: 100%;
`
export const StyledFaultHistoryCollapseContainer = styled(Flexbox)`
  width: 100%;
`
export const StyledFaultHistoryCollapseInnerContainer = styled(Flexbox)`
  width: 100%;
`
export const StyledFaultHistoryCollectionDateRow = styled(Flexbox)`
  width: 100%;
`
export const StyledFaultHistoryCustomerNotesRow = styled(Flexbox)`
  width: 100%;
`
export const StyledFaultHistoryRecommendedActionRow = styled(Flexbox)`
  width: 100%;
`
// Evidence

export const StyledFaultHistoryEvidenceHeadingContainer = styled(Flexbox)`
  width: 30%;
`
export const StyledFaultHistoryEvidenceRow = styled(Flexbox)`
  width: 100%;
`
export const StyledFaultHistoryEvidenceContainer = styled(Flexbox)`
  width: 100%;
  margin: 0;
`
export const StyledFaultHistoryEvidenceHeadings = styled(Flexbox)`
  font-weight: 600;
`
export const StyledFaultHistoryEvidenceFilesContainer = styled.div`
  width: 75%;
`

export const StyledFaultHistoryEvidenceFilesWrapper = styled(Flexbox)`
  width: 100%;
  gap: 1.5rem;
`
export const StyledFaultHistoryEvidenceFilesContent = styled.div`
  max-width: 11rem;
`
export const StyledFaultHistoryEvidenceFile = styled.img`
  border: 2px solid #676f7c;
  height: 7.813rem;
  min-width: 10.625rem;
  max-width: 10.625rem;
`
export const StyledFaultHistoryEvidenceCommentBG = styled(Flexbox)`
  background-color: #ebecee;
`
export const StyledFaultHistoryEvidenceComment = styled(TextEllipsis)`
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.2rem;
  margin: 0.4rem;
`
export const StyledFaultHistoryRecommendedActionContainer = styled(Flexbox)`
  width: 100%;
  margin: 1rem 0;
`
export const StyledFaultHistoryRecommendedAction = styled(Flexbox)`
  width: 20%;
`
export const StyledFaultHistoryRecommendedActionText = styled(Text)`
  width: 10%;
`
export const StyledFaultHistoryRecommendedActionAdditionalComments = styled(TextEllipsis)`
  width: 20%;
`
export const StyledFaultHistoryRecommendedActionDate = styled.div`
  width: 20%;
`
export const StyledFaultHistoryRecommendedActionWorkOrder = styled(TextEllipsis)`
  width: 20%;
`
export const StyledFaultHistoryCollectionDateRowChildren = styled.div`
  width: 25%;
  margin: 1rem;
`
export const StyledFaultHistoryObservation = styled(Flexbox)`
  width: 60%;
  padding: 0 3rem;
`
export const StyledFaultHistoryOpenIconButton = styled(Flexbox)`
  align-self: flex-start;
  width: 10%;
`

export const StyledFaultHistoryTypeText = styled(TextEllipsis)`
  font-weight: ${FONT_WEIGHTS.bold};
`
export const StyledMockCustomerNoteButtonArea = styled.div`
  width: 9.25rem;
  margin-bottom: 1rem;
`
export const StyledMarkAsNormalRowInnerContainerChildren = styled(Flexbox)`
  height: 100%;
  width: 100%;
  margin-left: 2rem;
`
export const MarkAsNormalWrapper = styled(Flexbox)`
  margin-bottom: 2rem;
`

export const StyledCustomerNotesHistoryContainer = styled(Flexbox)`
  padding-block: ${SPACINGS.md};
  background-color: #e2e4e7 !important;
  padding-left: 2rem;
`

export const StyledCustomerNotesHistoryNoteContainer = styled.div`
  display: inline-block;

  & > span:not(:last-of-type) {
    padding-right: ${SPACINGS.md};
  }
`

export const StyledHALCustomerNoteHistoryContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, 10.625rem);
  gap: ${SPACINGS.md};
`

export const StyledSKfCustomerNoteHistoryContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 12.5rem) 50%;
  overflow-x: auto;
`

export const StyledRecommendedActionDateDisplayLabel = styled.span`
  font-weight: ${Boldness.Bold};
`

export const StyledRecommendedActionDateDisplayValue = styled.span`
  grid-column-end: span 1;
`
export const StyledWrapperHistoryRow = styled(Flexbox)`
  margin-bottom: -2rem;
`
export const StyledCollapseRowWrapper = styled(Flexbox)`
  margin-bottom: 0.7rem;
`
