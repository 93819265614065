import HeaderInfoPanelIcon from '@/shared/components/ModuleHeader/infoPanel/HeaderInfoPanelIcon'
import { generateUUID } from '@/shared/utils'
import { useTypedSelector } from '@/store/store'
import { dataTestId } from '@/tests/testid'
import { Flexbox, SPACINGS, css } from '@skf-internal/ui-components-react'

/**
 * HeaderInfoPanel is the flexed div that located in module header
 * and it holds module header data icons or other passed element
 *
 * HeaderClearFiltersButton can be configured by  title and disabled state
 * @returns {JSX.Element} The rendered component.
 */

const HeaderInfoPanel = () => {
  const {
    moduleHeaderData: {
      headerConfig: { infoPanelData, infoPanelElement: CustomElement },
    },
  } = useTypedSelector((store) => ({ ...store }))
  return (
    <Flexbox
      feAlignItems="center"
      css={css`
        gap: ${SPACINGS.md};
      `}
      data-testid={dataTestId.headerInfoPanel}
    >
      {infoPanelData?.map((iconData) => <HeaderInfoPanelIcon key={generateUUID()} {...iconData} />)}
      {CustomElement || <div />}
    </Flexbox>
  )
}

export default HeaderInfoPanel
