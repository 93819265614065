import { COLORS, Popup, SPACINGS, css, useTooltip } from '@skf-internal/ui-components-react'
import { FC } from 'react'
import { Boldness } from '../styles/constants'
import styled from '@emotion/styled'

export const TextEllipsis: FC<{
  rowsUntilEllipsis: number
  label?: string
  value: string | undefined
  className?: string
}> = ({ label, rowsUntilEllipsis, value, className }) => {
  const [hoverRef, isHovered] = useTooltip()

  const isOverflowing = () => {
    const { scrollWidth, scrollHeight, clientWidth, clientHeight } = hoverRef.current ?? {}

    if (
      scrollWidth === undefined ||
      scrollHeight === undefined ||
      clientWidth === undefined ||
      clientHeight === undefined
    ) {
      return false
    }
    return scrollWidth > clientWidth || scrollHeight > clientHeight
  }
  const formattedText = value ? value.replace(/\n/g, '<br>') : ''

  return (
    <>
      <div
        css={css`
          overflow: hidden;
          ${`
        display: -webkit-box;
        -webkit-line-clamp: ${rowsUntilEllipsis};
        -webkit-box-orient: vertical;
        `}
        `}
        className={className}
      >
        {label !== undefined && (
          <span
            css={{
              fontWeight: Boldness.Bold,
            }}
          >
            {label}:{' '}
          </span>
        )}
        <div
          css={css`
            width: 100%;
          `}
        >
          <div
            css={css`
              width: fit-content;
            `}
            ref={hoverRef}
            dangerouslySetInnerHTML={{ __html: formattedText }}
          ></div>
        </div>
      </div>
      {isHovered && isOverflowing() && (
        <Popup isHovered={isHovered} triggerElement={hoverRef.current}>
          <StyledTooltip dangerouslySetInnerHTML={{ __html: formattedText }}></StyledTooltip>
        </Popup>
      )}
    </>
  )
}

export const StyledTooltip = styled.div`
  background-color: ${COLORS.white};
  font-size: 0.8rem;
  max-width: 19rem;
  padding: ${SPACINGS.sm};
  border-radius: 0.1rem;
  z-index: 9999999;
  word-wrap: break-word;
  overflow-wrap: break-word;
`
