import { FC, useEffect, useState } from 'react'
import { css } from '@emotion/react'
import { DatePicker, Flexbox, RadioGroup, Spacer, Text } from '@skf-internal/ui-components-react'
import { Row } from '@tanstack/react-table'
import { withColumnFilterPopover } from '../filter-popover-wrapper'
import { format } from 'date-fns'
import { DateFormats, addToDate, epochDate, parseDate } from '@/shared/dateUtils'

type DateFilterType = 'before' | 'between' | 'after'

export type DateFilterValue = {
  dateFilterType: DateFilterType
  startDate?: Date
  endDate?: Date
}

export const dateFilterFn = <T,>(
  row: Row<T>,
  columnId: string,
  { dateFilterType, startDate, endDate }: DateFilterValue
) => {
  const columnValue = new Date(row.getValue(columnId))
  const dateWithoutHours = columnValue
  dateWithoutHours.setHours(0, 0, 0, 0)

  let valid = false

  if (dateWithoutHours.getTime() === 0) {
    return false
  }

  if (dateFilterType === 'before' && startDate && dateWithoutHours.getTime() < startDate.getTime()) {
    valid = true
  } else if (
    dateFilterType == 'between' &&
    startDate &&
    endDate &&
    dateWithoutHours.getTime() >= startDate?.getTime() &&
    dateWithoutHours.getTime() <= endDate?.getTime()
  ) {
    valid = true
  } else if (dateFilterType === 'after' && startDate && dateWithoutHours.getTime() > startDate.getTime()) {
    valid = true
  }

  return valid
}

const DateFilterPopover: FC<{
  initialValue?: DateFilterValue
  onFilterValueChange: (value?: DateFilterValue) => void
}> = ({ initialValue, onFilterValueChange }) => {
  const [dateFilterType, setDateFiltertype] = useState<DateFilterType>(initialValue?.dateFilterType || 'before')

  const [startDate, setStartDate] = useState<Date | undefined>(initialValue?.startDate)
  const [endDate, setEndDate] = useState(initialValue?.endDate)

  const handleDateFilterTypeChange = (value: DateFilterType) => {
    setStartDate(undefined)
    setEndDate(undefined)
    setDateFiltertype(value)
    if (initialValue === undefined) {
      onFilterValueChange(undefined)
    }
  }

  const handleSingleFilterChange = (startDate: Date) => {
    setStartDate(startDate)
    setEndDate(undefined)

    if (startDate !== undefined) {
      onFilterValueChange({ dateFilterType, startDate, endDate })
    }
  }

  const handleRangeFilterChange = ([startDate, endDate]: [Date, Date]) => {
    setStartDate(startDate)
    setEndDate(endDate)
    if (startDate !== undefined && endDate !== undefined) {
      onFilterValueChange({ dateFilterType, startDate, endDate })
    }
  }

  useEffect(() => {
    if (dateFilterType == 'after' || dateFilterType == 'before') {
      const newDate = {
        dateFilterType: dateFilterType,
        startDate: new Date(),
        endDate: undefined,
      }
      setStartDate(new Date())
      onFilterValueChange(newDate)
    } else {
      setStartDate(undefined)
      onFilterValueChange(undefined)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateFilterType])

  return (
    <Flexbox css={css({ width: '500px', fontSize: '1rem' })}>
      <RadioGroup<DateFilterType>
        feItems={[
          {
            feLabel: 'Before date',
            value: 'before',
          },
          {
            feLabel: 'Between two dates',
            value: 'between',
          },
          {
            feLabel: 'Newer than date',
            value: 'after',
          },
        ]}
        feLegend="Date filter type"
        name="dateFilterType"
        onChange={(_, value) => handleDateFilterTypeChange(value)}
        value={dateFilterType}
      />

      <Spacer feHorizontal={true} feSpacing="xxs" />

      <Flexbox feAlignItems="end" feFlexDirection="column" feGap="xs" css={css({ width: '100%' })}>
        {(dateFilterType == 'after' || dateFilterType == 'before') && (
          <DatePicker
            placeholder={DateFormats.ISO8601Date}
            feLabel="Date Picker"
            onChange={(date) => handleSingleFilterChange(date as Date)}
            value={startDate}
            feHideLabel
            feMinDate={epochDate().toDate()}
            feMaxDate={parseDate(addToDate(new Date(), 100, 'year'))}
          />
        )}

        {dateFilterType == 'between' && (
          <DatePicker
            feLabel="Date Picker"
            onChange={(range) => handleRangeFilterChange(range as [Date, Date])}
            value={[startDate ?? undefined, endDate ?? undefined]}
            feRange
            feHideLabel
            feMinDate={epochDate().toDate()}
            feMaxDate={parseDate(addToDate(new Date(), 100, 'years'))}
          />
        )}
      </Flexbox>
    </Flexbox>
  )
}

export const DateFilter = withColumnFilterPopover(DateFilterPopover, (filterValue: DateFilterValue) => {
  if (
    (filterValue.dateFilterType === 'before' || filterValue.dateFilterType === 'after') &&
    filterValue.startDate !== undefined
  ) {
    return (
      <Text feFontSize="md">
        Dates {filterValue.dateFilterType} <b>{format(filterValue.startDate, 'M/d/yyyy')}</b>
      </Text>
    )
  } else if (
    filterValue.dateFilterType === 'between' &&
    filterValue.startDate !== undefined &&
    filterValue.endDate !== undefined
  ) {
    return (
      <Text feFontSize="md">
        Dates between <b>{format(filterValue.startDate, 'M/d/yyyy')}</b> and{' '}
        <b>{format(filterValue.endDate, 'M/d/yyyy')}</b>
      </Text>
    )
  }
})
