// import { useDashboardContext } from '@/contexts/dashboard'
// import SelectedDashboardActionPanel from '@/modules/dashboard/components/actionPanel'
// import { css } from '@emotion/react'
import {
  Flexbox,
  // COLORS, FONT_SIZES,
  Spacer,
} from '@skf-internal/ui-components-react'
// import { useState } from 'react'
// import { useTranslation } from 'react-i18next'
// import { WidgetPanel } from './widgetPanel'
// import { Collapsible } from '@/common/collapsible'
// import { ResponsiveLayout } from '@/modules/dashboard/components/responsiveLayout'
// import { WidgetType } from '@/models/dashboard/types'
import AssetHealthCondition from '@/modules/dashboard/components/widgets/asset-health-condition'
import RecommendedActions from '@/modules/dashboard/components/widgets/recommended-actions'
import AssetHealthFault from '@/modules/dashboard/components/widgets/asset-health-fault'
import {
  StyledDashboardContainer,
  StyledMainWidgetColumn,
  StyledAssetHealthWidget,
  StyledRecommendedActionsWidget,
  StyledAssetHealthFaultWidget,
} from '@/modules/dashboard/styled'

// import DashboardSubHeader from '@/modules/dashboard/components/subHeader'

export const SelectedDashboard = () => {
  // const [t] = useTranslation('dashboard')
  // const { selectedDashboard } = useDashboardContext()
  // const [currentlyDraggedWidgetType, setCurrentlyDraggedWidgetType] = useState<WidgetType | undefined>(undefined)

  return (
    <>
      {/* <Collapsible header={<DashboardSubHeader />} toggleButtonBackgroundColor={COLORS.gray050}>
        {selectedDashboard.isEditable ? (
          <WidgetPanel onWidgetStartDrag={setCurrentlyDraggedWidgetType} />
        ) : (
          <div
            css={css`
              font-size: ${FONT_SIZES.lg};
              background-color: ${COLORS.gray050};
              text-align: center;
              height: 50px;
              width: 100%;
            `}
          >
            {t('you_cannot_drop_widgets')}
          </div>
        )}
      </Collapsible> */}
      {/* <SelectedDashboardActionPanel /> */}

      <Spacer />
      {/* <ResponsiveLayout
        currentlyDraggedWidgetType={currentlyDraggedWidgetType}
        onWidgetDropped={() => setCurrentlyDraggedWidgetType(undefined)}
      /> */}
      <StyledDashboardContainer
        feFlexDirection="row"
        feGap="lg"
        feJustifyContent="center"
        feAlignItems="flex-start"
        feFlexWrap="wrap"
      >
        <StyledMainWidgetColumn feFlexDirection="column" feGap="lg">
          <StyledAssetHealthWidget>
            <AssetHealthCondition />
          </StyledAssetHealthWidget>
          <StyledRecommendedActionsWidget>
            <RecommendedActions />
          </StyledRecommendedActionsWidget>
        </StyledMainWidgetColumn>

        <Flexbox feFlexDirection="column">
          <StyledAssetHealthFaultWidget>
            <AssetHealthFault />
          </StyledAssetHealthFaultWidget>
        </Flexbox>
      </StyledDashboardContainer>
    </>
  )
}
