import { HalCustomerNoteData } from '@/models/reportStatus/types'
import { StyledInfoCard } from '@/modules/report-status/styled'
import { format, parseISO } from 'date-fns'
import { FC } from 'react'
import NoteInfo from './NoteInfo'

interface CustomerInfoCardProps {
  noteData: HalCustomerNoteData
}

const CustomerInfoCard: FC<CustomerInfoCardProps> = ({ noteData }) => {
  return (
    <StyledInfoCard>
      <NoteInfo label="Engine Hour" notes={noteData.engineHour} />
      <NoteInfo label="Is Investigating Alert?" notes={String(noteData.isInvestigatingAlert)} />
      <NoteInfo label="Is Resolved Id Note SKF?" notes={String(noteData.isResolvedIdNoteSKF)} />
      <NoteInfo label="Is Investigating SKF" notes={String(noteData.isInvestigatingSKF)} />
      <NoteInfo label="SAP" notes={noteData.SAP} />
      <NoteInfo label="Type of Alert" notes={noteData.typeOfAlert} />
      <NoteInfo label="Drop list Level 1 to 4 SKF" notes={noteData.droplistLevel1to4SKF} />
      <NoteInfo label="Drop list Level 5 SKF" notes={noteData.droplistLevel5SKF} />
      <NoteInfo label="Creation Date" notes={format(parseISO(noteData.noteDate), 'M/d/yyyy')} />
    </StyledInfoCard>
  )
}

export default CustomerInfoCard
