import { css } from '@emotion/react'
import { EChartsOption } from 'echarts'
import { FC, useMemo } from 'react'
import { RAwidgetDataType, ModifiedDataType } from '@/models/widgets/recommended-actions/types'
import { useMonthFormatter } from '@/modules/dashboard/hooks/useMonthFormatter'
import { ReactEChartsRenderer } from '@/modules/dashboard/components/common/e-charts-renderer'
import {
  DashedPattern,
  applyDashedPatternToMarkArea,
  calculateOptions,
  useCalculateYAxisMaxLabel,
  useNoDataMonthColumnsIndices,
} from '@/modules/dashboard/utils/recommended-actions'
import { useDashboardContext } from '@/contexts/dashboard'

interface RecommendedActionsGraphProps {
  slices: RAwidgetDataType
  showPercentages: boolean
  noDataInAllMonth: boolean
}

const RecommendedActionsGraph: FC<RecommendedActionsGraphProps> = ({ slices, showPercentages, noDataInAllMonth }) => {
  const { dashboardFilters } = useDashboardContext()
  const { format: formatMonth } = useMonthFormatter()

  const modifiedData = useMemo(() => {
    const data: ModifiedDataType = {}

    slices.forEach((slice) => {
      const keysOfDataObj = Object.keys(slice.data)

      dashboardFilters.raStatus.forEach((key) => {
        if (!data[key]) {
          data[key] = []
        }
        if (keysOfDataObj.includes(key)) {
          data[key].push(slice.data[key])
        } else {
          data[key].push(0)
        }
      })
    })

    return data
  }, [slices, dashboardFilters.raStatus])

  const yAxisMaxVal = useCalculateYAxisMaxLabel(modifiedData)

  const columns = useMemo(() => {
    const doubledColumns: string[] = ['']

    slices.forEach((slice) => {
      let label = formatMonth(slice.period.month)
      label += `\n{year|(${slice.period.year})}`

      doubledColumns.push(label)

      const additionalLabel = `15-${label}`
      doubledColumns.push(additionalLabel)
    })

    return doubledColumns
  }, [slices, formatMonth])

  const noDataMonthColumnsIndices = useNoDataMonthColumnsIndices(slices, noDataInAllMonth)

  const option = useMemo<EChartsOption>(
    () =>
      calculateOptions(
        modifiedData,
        columns,
        yAxisMaxVal,
        showPercentages,
        noDataInAllMonth,
        noDataMonthColumnsIndices
      ),
    [modifiedData, columns, yAxisMaxVal, showPercentages, noDataInAllMonth, noDataMonthColumnsIndices]
  )
  if (option.series && Array.isArray(option.series) && option.series.length > 0 && !noDataInAllMonth) {
    const dashedPattern = DashedPattern('#bfdbfe99', '#fff', 'ra-pieChart')
    if (dashedPattern) {
      applyDashedPatternToMarkArea(option, dashedPattern)
    }
  }

  return (
    <ReactEChartsRenderer
      css={css`
        flex-grow: 1;
        padding: 5px;

        svg {
          cursor: defalt;

          &:hover {
            cursor: default;
          }
        }
      `}
      option={option}
    />
  )
}
export default RecommendedActionsGraph
