// =================================================================================================
// IMPORTS
// =================================================================================================

import { formatProp, processDate } from '@/common/utils'
import StatusValueTooltip from '@/modules/devices-sensors/components/tooltip'
import { Flexbox } from '@skf-internal/ui-components-react'
import { FC } from 'react'
import { deviceNumberFormatter } from '@/modules/devices-sensors/utils/constants'
import { DevicesSensorsTableChannelRowData } from '@/models/devicesAndSensors/types'
import { dataTestId } from '@/tests/testid'
import { StyledDevicesSensorsTableSensorRow } from '../styled'
import { StyledTd } from './styled'

// =================================================================================================
// COMPONENT
// =================================================================================================

export const DevicesSensorsTableSensorRow: FC<
  DevicesSensorsTableChannelRowData & { odd: boolean; selected: boolean; onSensorRowClick: () => void }
> = ({ number, name, status, lastCommunicationDate, odd, selected, onSensorRowClick, asset }) => {
  return (
    <StyledDevicesSensorsTableSensorRow
      odd={odd}
      selected={selected}
      onClick={onSensorRowClick}
      data-testid={dataTestId.deviceSensorsTableRow}
    >
      <td colSpan={1}></td>
      <td colSpan={1}>
        <Flexbox feGap="xl">
          <span>{deviceNumberFormatter.format(number)}</span>
          <span>{name}</span>
        </Flexbox>
      </td>
      <td colSpan={1}>
        <Flexbox feJustifyContent="center">
          <StatusValueTooltip value={status} />
        </Flexbox>
      </td>
      <td colSpan={1}>{formatProp(lastCommunicationDate, (prop) => processDate(prop))}</td>
      <StyledTd colSpan={1}>{asset ? asset.map((item) => <span>{item.asset.name}</span>) : '-'}</StyledTd>
      <td colSpan={1}></td>
    </StyledDevicesSensorsTableSensorRow>
  )
}
