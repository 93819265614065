import { COLORS, Icon, SPACINGS, css } from '@skf-internal/ui-components-react'
import { AssetRow, ReasonIndicatorData } from '@/models/analysisBacklog/types'
import { PriorityColumn } from './priority-column'
import { Row, createColumnHelper } from '@tanstack/react-table'
import { format, parseISO } from 'date-fns'
import { DateFilter, dateFilterFn } from '../../../common/table/filters/date-filter'
import { AssetStatusIcon } from '../status-icon'
import { StatusFilter, statusFilterFn } from './filters/status-filter'
import { AnalysisReasonFilter, analysisReasonFilterFn } from './filters/analysis-reason-filter'
import { ActionMenu } from './actions-menu'
import { TextSearchFilter, textSearchFilterFn } from '../../../common/table/filters/text-search-filter'
import { CopyHierarchyButton } from './copy-name-button'
import { DateSortFn } from '@/common/table/sort-functions/date'
import { BelongsToColumn } from '@/modules/analysis-backlog/asset-table/belongs-to-column'
import { MetaTableState } from '@/shared/models/types'
import AnalysisReason from '@/modules/analysis-backlog/analysis-reason'

const columnHelper = createColumnHelper<AssetRow>()

export const analysisBacklogTableDefinition = (tableState: boolean) => [
  columnHelper.accessor('priority', {
    header: undefined,
    cell: ({ row }) => {
      if (row.original.handled) {
        return <Icon feIcon="asset" feColor="gray" feSize="sm" />
      } else {
        return (
          <PriorityColumn isInaccuratePriority={row.original.isInaccuratePriority} priority={row.original.priority} />
        )
      }
    },
    size: 75,
    enableColumnFilter: false,
  }),
  columnHelper.accessor((row) => row.asset.name, {
    id: 'asset',
    header: 'Asset Name',
    cell: ({ getValue, row }) => {
      const assetName = getValue()
      const hierarchy = row.original.belongsTo.hierarchyPath

      return (
        <div
          css={css`
            display: flex;
            align-items: center;
            column-gap: ${SPACINGS.xs};

            color: ${COLORS.brand};
          `}
        >
          <span
            css={css`
              word-wrap: break-word; /* IE */
              overflow: hidden;
            `}
          >
            {assetName}
          </span>
          <CopyHierarchyButton assetName={assetName} hierarchy={hierarchy} />
        </div>
      )
    },
    size: 200,
    enableColumnFilter: true,
    filterFn: textSearchFilterFn,
    meta: {
      filterComponent: TextSearchFilter,
    },
  }),
  columnHelper.accessor((row) => row.belongsTo.name, {
    id: 'belongsTo',
    header: 'Belongs to',
    cell: ({ getValue, row }) => {
      const hierarchy = row.original.belongsTo.hierarchyPath
      const assetName = row.original.asset.name

      return <BelongsToColumn belongsTo={getValue()} assetName={assetName} hierarchy={hierarchy} />
    },
    size: 200,
    enableColumnFilter: true,
    filterFn: textSearchFilterFn,
    meta: {
      filterComponent: TextSearchFilter,
    },
  }),
  columnHelper.accessor('lastCollectedDate', {
    header: 'Collected',
    cell: ({ getValue }) => (
      <div css={css({ textAlign: 'center' })}>
        {getValue() !== 'no date available' ? format(parseISO(getValue<string>()), 'M/d/yyyy') : '-'}
      </div>
    ),
    size: 145,
    sortingFn: DateSortFn<AssetRow>,
    enableColumnFilter: true,
    meta: {
      filterComponent: DateFilter,
    },
    filterFn: dateFilterFn<AssetRow>,
  }),
  columnHelper.accessor('lastReportedDate', {
    header: 'Reported',
    cell: ({ getValue }) => (
      <div css={css({ textAlign: 'center' })}>
        {getValue() !== 'no date available' ? format(parseISO(getValue<string>()), 'M/d/yyyy') : '-'}
      </div>
    ),
    size: 145,
    sortingFn: DateSortFn<AssetRow>,
    enableColumnFilter: true,
    meta: {
      filterComponent: DateFilter,
    },
    filterFn: dateFilterFn<AssetRow>,
  }),
  columnHelper.accessor('status', {
    header: 'Status',
    cell: ({ getValue }) => (
      <AssetStatusIcon
        css={css`
          margin: auto;
        `}
        value={getValue()}
      />
    ),
    size: 120,
    sortingFn: (rowA: Row<AssetRow>, rowB: Row<AssetRow>, columnId: string): number => {
      const order: AssetRow['status'][] = [
        'normal',
        'acceptable',
        'unsatisfactory',
        'unacceptable',
        'severe',
        'never-reported',
      ]

      const aIdx = order.indexOf(rowA.getValue<AssetRow['status']>(columnId))
      const bIdx = order.indexOf(rowB.getValue<AssetRow['status']>(columnId))

      if (aIdx > bIdx) {
        return 1
      } else if (aIdx === bIdx) {
        return 0
      } else {
        return -1
      }
    },
    enableColumnFilter: true,
    filterFn: statusFilterFn,
    meta: {
      filterComponent: StatusFilter,
    },
  }),
  columnHelper.accessor('analysisReasons', {
    id: 'analysisReasons',
    header: 'Analysis Reasons',
    cell: ({ row }) => {
      if (row.original.handled === false) {
        const analysisReasonsOrder: ReasonIndicatorData['type'][] = [
          'band',
          'diagnostics',
          'protean',
          'ai',
          'overall',
          'feedback',
          'device_fault',
          'no_sensor_data',
        ]

        const orderedAnalysisReasons = row.original.analysisReasons
          .slice()
          .sort((a, b) => analysisReasonsOrder.indexOf(a.type) - analysisReasonsOrder.indexOf(b.type))

        return (
          <div
            css={css`
              display: flex;
              flex-wrap: wrap;
              gap: ${SPACINGS.xs};
            `}
          >
            {orderedAnalysisReasons.map((reason, i) => (
              <AnalysisReason key={i} reason={reason} />
            ))}
          </div>
        )
      } else {
        return <></>
      }
    },
    enableSorting: false,
    enableColumnFilter: true,
    filterFn: analysisReasonFilterFn,
    meta: {
      filterComponent: AnalysisReasonFilter,
    },
    size: undefined,
    minSize: 300,
  }),
  columnHelper.display({
    id: 'actions',
    cell: ({ row: { original }, table, column }) => {
      const { columnFilters, sorting } = table.getState()
      const {
        columnDef: { meta },
      } = column
      const tableState = (meta as MetaTableState).tableState

      return (
        <ActionMenu
          assetRow={original}
          elements={
            original.status === 'never-reported' || original.status === 'normal'
              ? ['mark-as-normal', 'report-status']
              : ['report-status']
          }
          columnFilters={columnFilters}
          sorting={sorting}
          tableState={tableState}
        />
      )
    },
    size: 50,
    meta: {
      tableState,
    },
  }),
]
