import { getDevicesAndSensorsData } from '@/api/paths/devicesAndSensors'
import { DarwinError } from '@/common/error-boundary/DarwinError'
import { useDevicesAndSensorsContext } from '@/contexts/devicesAndSensors'
import { useApi } from '@/shared/hooks/useApi'
import { useAsyncThrow } from '@/shared/hooks/useAsyncThrow'
import { DevicesAndSensorsData } from '@/models/devicesAndSensors/types'
import { transformDevice } from '@/modules/devices-sensors/utils'
import { transformTableData } from '@/modules/devices-sensors/utils/tableUtils'
import { AxiosError } from 'axios'
import { useParams } from 'react-router-dom'

const useGetDevicesAndSensorsData = () => {
  const { setDevicesAndSensorsData } = useDevicesAndSensorsContext()

  const { sid: companyId } = useParams()

  const throwError = useAsyncThrow()

  return useApi(() => {
    return getDevicesAndSensorsData(
      {
        onSuccess: async (result: DevicesAndSensorsData) => {
          setDevicesAndSensorsData(transformTableData(result.devices.map(transformDevice)))
        },
        onError: async (error: AxiosError) => {
          throwError(new DarwinError(error))
        },
      },
      {
        companyId,
      }
    )
  })
}

export default useGetDevicesAndSensorsData
