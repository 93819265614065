import { TextField } from '@skf-internal/ui-components-react'
import { Row } from '@tanstack/react-table'
import { FC } from 'react'
import { withColumnFilterPopover } from '../filter-popover-wrapper'

export const textSearchFilterFn = <T extends string>(row: Row<T>, columnId: string, searchString: T) => {
  const name = row.getValue<T>(columnId)

  return !Array.isArray(name)
    ? name?.toLowerCase().includes(searchString.trim().toLowerCase())
    : name.some((item) => item.name.toLowerCase().includes(searchString.trim().toLowerCase()))
}

export const numberSearchFilterFn = <T extends string>(row: Row<T>, columnId: string, searchString: T) => {
  const num = row.getValue<T>(columnId).toString()
  const dueDate = new Date(num)
  const currentDate = new Date()
  const timeDiff = dueDate.getTime() - currentDate.getTime()
  const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24))
  return daysDiff === parseInt(searchString)
}

const TextSearchFilterPopover: FC<{
  initialValue?: string
  onFilterValueChange: (value?: string) => void
}> = ({ initialValue, onFilterValueChange }) => {
  return (
    <TextField
      feSize="sm"
      feLabel="Search"
      feHideLabel
      placeholder="Search"
      defaultValue={initialValue}
      onChange={(_, searchString) => onFilterValueChange(searchString)}
      autoFocus
    />
  )
}

export const TextSearchFilter = withColumnFilterPopover<string, string>(TextSearchFilterPopover, (value) => (
  <div>{value}</div>
))
