import { StatusCode } from '@/api/constants'
import { DarwinNotFoundError } from '@/common/error-boundary/DarwinError'
import { useEffectOnce } from '@/shared/hooks/useEffectOnce'

export const ErrorNotFound = () => {
  useEffectOnce(() => {
    throw new DarwinNotFoundError({
      status: StatusCode.NotFound,
      message: 'Page not found',
      name: '',
      isAxiosError: false,
      /* v8 ignore next 1 */
      toJSON: () => ({}),
    })
  })

  return <div></div>
}
