import React from 'react'
import { getStyles, containerStyles, rawBadgeStyles } from '@/shared/components/Badge/badgeUtils'
import { dataTestId } from '@/tests/testid'

export interface BadgeProps {
  badgeContent: string | number
  bgColor?: string
  max?: number
  contentColor?: string
  verticalAlignment?: 'top' | 'bottom'
  horizontalAlignment?: 'left' | 'right'
  hideZero?: boolean
  size?: number
}

/**
 * The Badge component is used to display a badge with content on other elements.
 * It supports customization of badge appearance and behavior.
 *
 * @param {*} {
 *
    - `content` - The content to display inside the badge. This can be a string or a number
 	- `bgColor` - Background color of the badge (default: 'red').
 	- `contentColor` -Color of the content inside the badge.
 	- `max` - Maximum value to display before truncating with a '+' sign (default: 99).
 	- `style` - Custom styles object to apply to the badge.
 	- `className` -Custom CSS class name(s) to apply to the badge.
 	- `verticalAlignment` - Vertical alignment of the badge relative to its parent ('top' or 'bottom')
 	- `horizontalAlignment` - Horizontal alignment of the badge relative to its parent ('left' or 'right').
 	- `hideZero` - Whether to hide the badge when the content is zero (default: false).
 	- `size` - Size of the badge. Will be applied to width and height
 * }
 *
 * Notes:
 	- `The content prop can be automatically truncated based on the max prop. If the content exceeds max, it will be displayed as ${max}+.`
 	- `The hideZero prop can be used to conditionally hide the badge when the content is zero.`
 	- `Use the styles prop to apply custom inline styles to the badge.`
 	- `Use the className prop to apply custom CSS class names to the badge, allowing for additional styling via external CSS.`
 * @return {*}
 */
const Badge: React.FC<BadgeProps & React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement>> = ({
  children,
  badgeContent,
  bgColor = 'red',
  contentColor,
  max = 99,
  style,
  className,
  verticalAlignment = 'top',
  horizontalAlignment = 'right',
  hideZero = false,
  size,
  ...props
}) => {
  let modifiedContent = badgeContent
  if (Number.isInteger(badgeContent) && max) {
    modifiedContent = +badgeContent > max ? `${max}+` : badgeContent
  }
  const badgeStyles = getStyles({
    badgeContent,
    bgColor,
    contentColor,
    horizontalAlignment,
    max,
    hideZero,
    verticalAlignment,
    size,
  })
  const hide = hideZero && !badgeContent
  return (
    <div data-testid={dataTestId.badgeComponent} className="container" style={containerStyles}>
      <div>{children}</div>
      {!hide && (
        <span
          className={`${className} badge`}
          style={{
            ...rawBadgeStyles,
            ...badgeStyles,
            ...style,
          }}
          {...props}
        >
          {modifiedContent}
        </span>
      )}
    </div>
  )
}

export default Badge
