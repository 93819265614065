import { Heading } from '@skf-internal/ui-components-react'
import RecommendedActionTrendView from './ra-trend-view'
import { useMemo, useState } from 'react'
import { Slice, YearMonth } from '@/models/widgets/asset-data-collection/types'
import { RAstatusOptionalProps, RAwidgetDataType } from '@/models/widgets/recommended-actions/types'
import { isEqual } from '@/shared/utils'
import RecommendedActionCurrentMonthStatus from '@/modules/dashboard/components/widgets/recommended-actions/ra-current-month-status'
import { roundToPercentageForRAwidget } from '@/modules/dashboard/utils/recommended-actions'
import { useDashboardContext } from '@/contexts/dashboard'
import useDeepCompareEffect from '@/shared/hooks/useDeepCompareEffect'
import { StyledRAWidgetContainer, StyledRAWidgetContent, StyledRecommendedActionsWrapper } from './styled'
import { useFetchDataContext } from '@/contexts/common/fetchDataContext'
import { dataTestId } from '@/tests/testid'
import { useAppDispatch, useTypedSelector } from '@/store/store'
import { addWidgetType, removeWidgetLoader } from '@/store/loading/action'
import GenericLoader, { GenericLoaderType } from '@/shared/components/GenericLoader'
import useWidgets from '@/modules/dashboard/hooks/widgets/useWidgetsHook'
import { StyledRadioButton } from '@/modules/report-status/styled'
import { WidgetType } from '@/models/dashboard/types'

const RecommendedActions = () => {
  const { widgetRecommendedActions } = useDashboardContext()
  const { fetchDataStatus } = useFetchDataContext()
  const [showPercentages, setShowPercentages] = useState(false)
  const [radioValue, setRadioValue] = useState('Amount')
  const [selectedPeriod, setSelectedPeriod] = useState<YearMonth>()
  const [currentMonthDataInPercentages, setCurrentMonthDataInPercentages] =
    useState<Slice<RAstatusOptionalProps, YearMonth>>()

  const { widgetTypes } = useTypedSelector((state) => state.widgetLoading)
  const {
    widgetRecommended: { status },
  } = useWidgets()
  const recommendedActionDispatch = useAppDispatch()

  const slicesInPercentages = useMemo<RAwidgetDataType>(
    () =>
      widgetRecommendedActions?.map(({ data, period }) => ({
        period,
        data: roundToPercentageForRAwidget(data),
      })),
    [widgetRecommendedActions]
  )

  const getPercentageValue = (value: string) => {
    setRadioValue(value)
    if (value === 'Percentage') {
      setShowPercentages(true)
    } else {
      setShowPercentages(false)
    }
  }

  useDeepCompareEffect(() => {
    if (widgetRecommendedActions.length > 0) {
      const currentMonth = new Date().getMonth() + 1
      const currentYear = new Date().getFullYear()
      const currentMonthData = widgetRecommendedActions.find(
        (item) => item.period.month === currentMonth && item.period.year === currentYear
      )
      const defaultSelectedMonth = widgetRecommendedActions[widgetRecommendedActions.length - 1]
      if (currentMonthData || defaultSelectedMonth) {
        setSelectedPeriod(currentMonthData ? currentMonthData.period : defaultSelectedMonth?.period)
      }
      if (slicesInPercentages.length > 0 && defaultSelectedMonth) {
        setCurrentMonthDataInPercentages(
          (showPercentages ? slicesInPercentages : widgetRecommendedActions).find(({ period }) =>
            isEqual(period, selectedPeriod)
          ) ?? defaultSelectedMonth
        )
      }
    }
  }, [widgetRecommendedActions, selectedPeriod, showPercentages, slicesInPercentages])
  const slices = showPercentages ? slicesInPercentages : widgetRecommendedActions

  useDeepCompareEffect(() => {
    if ((status === 'success' || status === 'not-executed') && widgetTypes.includes(WidgetType.RECOMMENDED_ACTIONS))
      recommendedActionDispatch(removeWidgetLoader(WidgetType.RECOMMENDED_ACTIONS))
    if (fetchDataStatus === 'loading') {
      recommendedActionDispatch(addWidgetType(WidgetType.RECOMMENDED_ACTIONS))
    }
  }, [status, widgetTypes, fetchDataStatus])

  const shouldRenderRecommendedActionsWidget = useMemo(() => {
    return (
      widgetRecommendedActions.length > 0 &&
      selectedPeriod &&
      currentMonthDataInPercentages &&
      !widgetTypes.includes(WidgetType.RECOMMENDED_ACTIONS) &&
      fetchDataStatus !== 'loading'
    )
  }, [widgetRecommendedActions.length, selectedPeriod, currentMonthDataInPercentages, widgetTypes, fetchDataStatus])

  return shouldRenderRecommendedActionsWidget ? (
    <StyledRAWidgetContainer data-testid={dataTestId.recommendedActions}>
      <StyledRecommendedActionsWrapper>
        <Heading as="h2">Recommended Actions</Heading>
        {/* <StyledHeaderWrapper> */}
        {/* <WidgetRefreshButton<GetRecommendationsResponse>
            executeFn={execute}
            widgetType={WidgetType.RECOMMENDED_ACTIONS}
          /> */}
        <StyledRadioButton
          value={radioValue}
          onChange={(_, value: string | number) => getPercentageValue(value as string)}
          feHorizontal
          feItems={[
            {
              feLabel: 'Amount',
              value: 'Amount',
              feSize: 'sm',
            },
            {
              feLabel: 'Percentage',
              value: 'Percentage',
              feSize: 'sm',
            },
          ]}
          feLegend=""
        />
        {/* </StyledHeaderWrapper> */}
      </StyledRecommendedActionsWrapper>
      <StyledRAWidgetContent>
        <RecommendedActionTrendView slices={slices} showPercentages={showPercentages} />
        <RecommendedActionCurrentMonthStatus slice={currentMonthDataInPercentages!} showPercentages={showPercentages} />
      </StyledRAWidgetContent>
    </StyledRAWidgetContainer>
  ) : (
    <GenericLoader loaderType={GenericLoaderType.WIDGET} />
  )
}

export default RecommendedActions
