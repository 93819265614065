import { FC } from 'react'
import { HalCustomerNoteData } from '@/models/reportStatus/types'
import { StyledNoteCard } from '@/modules/report-status/styled'
import NoteInfo from '@/modules/report-status/components/customerNote/NoteInfo'

interface NoteCardProps {
  customerNoteData: HalCustomerNoteData
}

const NoteCard: FC<NoteCardProps> = ({ customerNoteData }) => {
  const notes = customerNoteData.notes

  return (
    <StyledNoteCard>
      <NoteInfo label={'Notes'} notes={notes} />
    </StyledNoteCard>
  )
}

export default NoteCard
