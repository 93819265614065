import { COLORS } from '@skf-internal/ui-components-react'

export const AssetColors = {
  NotReported: COLORS.white,
  Normal: COLORS.greenDark,
  Acceptable: COLORS.greenBase,
  Unsatisfactory: COLORS.yellowDarker,
  Unacceptable: COLORS.orangeDark,
  Severe: COLORS.redBase,
} as const
