import { StyledReasonIndicator } from '@/modules/analysis-backlog/asset-table/reasonIndicator/styled'
import { ReasonIndicatorProps } from '@/modules/analysis-backlog/asset-table/reasonIndicator/types'
import Badge from '@/shared/components/Badge'
import useDeepCompareMemo from '@/shared/hooks/useDeepCompareMemo'
import { dataTestId } from '@/tests/testid'
import { Divider, Icon } from '@skf-internal/ui-components-react'
import { FC, useCallback, useMemo } from 'react'

const ReasonIndicator: FC<ReasonIndicatorProps> = ({
  reasonIcon,
  reasonSeverity,
  reasonText,
  reasonCount,
  withRedirect = false,
  redirectUrl,
  withBadge = false,
  customStyle,
  onClick,
}) => {
  const iconData = useDeepCompareMemo(() => {
    let result: typeof reasonIcon = reasonIcon

    if (!result) {
      switch (reasonSeverity) {
        case 'alarm':
          result = { iconName: 'danger', iconColor: 'red' }
          break
        case 'alert':
          result = { iconName: 'warning', iconColor: 'orange' }
      }
    }
    return result
  }, [reasonIcon])

  const enableRedirect = useMemo(() => !!(withRedirect && redirectUrl), [withRedirect, redirectUrl])

  const iconElement = useDeepCompareMemo(() => {
    let result = <Icon feIcon={iconData.iconName} feColor={iconData.iconColor} feSize="sm" />
    if (withBadge) {
      result = (
        <Badge badgeContent="" bgColor="red">
          {result}
        </Badge>
      )
    }
    return result
  }, [iconData, withBadge])

  const onReasonIndicatorClick = useCallback(() => {
    onClick && onClick()
    enableRedirect &&
      Object.assign(document.createElement('a'), {
        target: '_blank',
        href: redirectUrl,
      }).click()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enableRedirect, redirectUrl])

  return (
    <StyledReasonIndicator
      style={{ ...customStyle }}
      withRedirect={enableRedirect}
      onClick={onReasonIndicatorClick}
      feNoPadding
      data-testid={dataTestId.reasonIndicator}
    >
      {iconElement}
      <span>{`${reasonText}`}</span>
      <Divider feVertical={true} as="div" feSpacing="xxs" />
      <span>{reasonCount}</span>
      {enableRedirect && <Icon feIcon="openInNew" feSize="sm" />}
    </StyledReasonIndicator>
  )
}

export default ReasonIndicator
