import SingleLineTextEllipsis from '@/common/text-ellipsis/SingleLineTextEllipsis'
import { useFaultEvidenceContext } from '@/contexts/moduleContexts/evidence'
import { useFaultsFormStateContext } from '@/contexts/moduleContexts/faultsForm'
import { useReportStatusContext } from '@/contexts/reportStatus'
import { EvidenceImageData } from '@/models/reportStatus/evidenceTypes'
import useUpdateFaultEvidenceImageData from '@/modules/report-status/hooks/useUpdateFaultEvidenceImageData'
import { UPDATE_EVIDENCE_IMAGE_DATA } from '@/modules/report-status/reducer/actions.types'
import { StyledEvidenceImageDetails } from '@/modules/report-status/styled'
import { DateFormats, formatDate } from '@/shared/dateUtils'
import useDeepCompareCallback from '@/shared/hooks/useDeepCompareCallback'
import { dataTestId } from '@/tests/testid'
import { Button, TextField } from '@skf-internal/ui-components-react'
import { FC, useState } from 'react'

interface ImageCardDetailsProps {
  imageDetails: EvidenceImageData
}

const ImageCardDetails: FC<ImageCardDetailsProps> = ({ imageDetails }) => {
  const [enableEditFileName, setEnableEditFileName] = useState(false)
  const [editedFileName, setEditedFileName] = useState<string>(imageDetails.imageInfo.fileName ?? '')

  const { faultEditModeData } = useReportStatusContext()

  const { faultId } = useFaultEvidenceContext()
  const { faultsFormDispatch } = useFaultsFormStateContext()

  const { execute: updateImage } = useUpdateFaultEvidenceImageData(imageDetails, editedFileName)

  const submitEditedFileName = useDeepCompareCallback(async () => {
    if (!editedFileName) return

    const originalFileExtension = imageDetails.imageInfo.fileName.split('.').pop()
    const newFileNameWithoutExtension = editedFileName.split('.')[0]
    const updatedFileName = `${newFileNameWithoutExtension}.${originalFileExtension}`

    await setEditedFileName(updatedFileName)
    await updateImage()
    imageDetails.imageInfo.fileName = updatedFileName
    faultsFormDispatch({
      type: UPDATE_EVIDENCE_IMAGE_DATA,
      payload: imageDetails,
    })
    setEnableEditFileName(false)
  }, [editedFileName, imageDetails, updateImage])

  return (
    <StyledEvidenceImageDetails data-testid={dataTestId.evidenceUploadedImageDetails}>
      {enableEditFileName && faultEditModeData[faultId] ? (
        <>
          <TextField
            feLabel=""
            feSize="sm"
            value={editedFileName}
            data-testid={dataTestId.evidenceUploadedImageCardEditNameInput}
            onChange={(e) => setEditedFileName(e.target.value)}
          />
          <Button
            feSize="sm"
            onClick={() => submitEditedFileName()}
            data-testid={dataTestId.evidenceUploadedImageCardEditNameSaveButton}
            disabled={!editedFileName}
          >
            Save
          </Button>
        </>
      ) : (
        <>
          <div onClick={() => setEnableEditFileName(true)}>
            <SingleLineTextEllipsis value={editedFileName} characterLength={30} fontSizeInRem={'0.75rem'} />
          </div>
          <span>{formatDate(imageDetails.imageInfo.fileCreatedAt, DateFormats.AmericanDateFormat)}</span>
        </>
      )}
    </StyledEvidenceImageDetails>
  )
}

export default ImageCardDetails
