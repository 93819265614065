import { TextEllipsis } from '@/common/text-ellipsis'
import styled from '@emotion/styled'
import { Card, Flexbox, SPACINGS, COLORS, FONT_SIZES, Text, Grid } from '@skf-internal/ui-components-react'

export const StyledCard = styled(Card)`
  width: 100%;

  button {
    color: ${COLORS.blueDark};
  }
`

export const StyledCardHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: ${SPACINGS.xs};
  font-size: ${FONT_SIZES.sm};
`
export const StyledCardHeaderDiv = styled.div`
  display: flex;
  align-items: center;
  gap: ${SPACINGS.xxs};
`
export const StyledRecommendedActionContentDiv = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  font-size: ${FONT_SIZES.sm};
`
export const StyledRecommendedActionContentInnerDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${SPACINGS.md};
`
export const StyledFaultsContentFlexbox = styled(Flexbox)`
  align-self: flex-start;
  margin-bottom: ${SPACINGS.xs};
  margin-left: ${SPACINGS.xxs};
  margin-top: ${SPACINGS.xs};
`
export const StyledObservationHeadingWithItemText = styled(Text)`
  text-align: 'left';
  font-weight: '400';
  width: '300px';
`
export const StyledStackBarDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACINGS.sm};
  align-items: flex-start;
`
export const StyledHeadingWithItemDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${SPACINGS.md};
`

export const StyledFaultsContainer = styled.div<{ backgroundColor: string }>`
  background-color: ${(props) => props.backgroundColor};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${SPACINGS.xxs};
`

export const StyledFaultLink = styled.a`
  font-weight: 700;
  align-self: flex-start;
  margin-bottom: ${SPACINGS.xs};
  margin-left: ${SPACINGS.xs};
  margin-top: ${SPACINGS.sm};
  color: ${COLORS.blueDarker};
  text-decoration: underline;
  text-decoration-color: ${COLORS.blueDarker};
  cursor: pointer;
`

export const StyledIndented = styled.div`
  display: grid;
  grid-template-columns: 30px auto;
  gap: 0 ${SPACINGS.xs};
  width: 390px;
  word-break: break-word;
`

export const StyledSVGContainer = styled.div`
  position: relative;
  width: 30px;

  & svg {
    position: absolute;
    height: 100%;

    & path,
    & line {
      stroke: ${COLORS.gray700};
    }
  }
`

export const HeaderWidgetWrapper = styled.div`
  display: flex;
  flex-direction: row;
`
export const WidgetContainer = styled.div`
  width: 100%;
  height: 48.75rem;
  display: flex;
  flex-direction: column;
  gap: ${SPACINGS.xl};
  overflow: auto;
`
export const TextEllipsisStyled = styled(TextEllipsis)<{ isSelected: boolean }>`
  text-align: center;
  font-weight: ${(props) => (props.isSelected ? '700' : '400')};
  word-break: break-word;
  font-size: 0.75rem;
`

export const FaultWrapper = styled(Flexbox)`
  height: 37.5rem;
  overflow: auto;
  margin-top: -1.25rem !important;

  ::-webkit-scrollbar {
    width: 0.625rem;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f142;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #c0c0c0;
    border-radius: 0.625rem;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #a9a8a8;
  }
`
export const WidgetAssetHealthFaultDataWrapper = styled(Flexbox)<{ isSelected: boolean; hasFaultData: boolean }>`
  width: 5.3rem;
  height: 5.3rem;
  margin-bottom: 1rem;
  border: ${(props) =>
    props.hasFaultData && props.isSelected ? `2px solid ${COLORS.blueDarker}` : `1px solid ${COLORS.gray100}`};

  &:hover {
    cursor: ${(props) => (props.hasFaultData ? 'pointer' : 'default')};
    border: ${(props) => props.hasFaultData && `2px solid ${COLORS.blueDarker}`};
    background-color: ${(props) => props.hasFaultData && COLORS.primary200};

    p {
      font-weight: 700;
    }
  }
`
export const AssetMessageWrapper = styled(Flexbox)`
  height: 100%;
`
export const AssetFaultsWrapper = styled(Grid)`
  grid-template-columns: repeat(5, 1fr) !important;
  gap: 0.3rem !important;
`
