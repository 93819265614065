import { EffectCallback, useEffect, useRef } from 'react'

type RemoveReturnType<T extends (...args: unknown[]) => unknown> = (...args: Parameters<T>) => void

export const useEffectOnce = (effect: RemoveReturnType<EffectCallback>) => {
  const done = useRef(false)

  return useEffect(() => {
    /* v8 ignore next 3 */
    if (done.current) {
      return
    }

    done.current = true
    return effect()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
