import { AssetHistorySnapshot } from '@/models/reportStatus/types'
import HistoryLinePrefix from '@/modules/report-status/components/history/Line'
import {
  StyledReconfirmCollapseContainer,
  StyledReconfirmFlex,
  StyledReconfirmRowInnerContainerChildren,
  StyledReconfirmUsernameSpan,
} from '@/modules/report-status/styled'
import { DateFormats, compareDates, formatDate } from '@/shared/dateUtils'
import { Flexbox, Icon, IconButton, SPACINGS } from '@skf-internal/ui-components-react'
import { FC, useMemo, useState } from 'react'

interface HistoryReconfirmSectionProps {
  reconfirms: AssetHistorySnapshot['reconfirms']
}

const HistoryReconfirmSection: FC<HistoryReconfirmSectionProps> = ({ reconfirms }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  return (
    <StyledReconfirmRowInnerContainerChildren feJustifyContent="space-start" feGap="xxl">
      <HistoryLinePrefix marginTop={SPACINGS.xxs} />
      <Collapse reconfirmData={reconfirms} open={isOpen} />
      <Flexbox>
        {reconfirms.length > 1 && (
          <IconButton
            as="button"
            type="button"
            feSize="sm"
            aria-label={isOpen ? 'Open' : 'Close'}
            onClick={() => setIsOpen((lastState) => !lastState)}
            feIcon={isOpen ? 'chevronUp' : 'chevronDown'}
          />
        )}
      </Flexbox>
    </StyledReconfirmRowInnerContainerChildren>
  )
}

const Collapse: FC<{ reconfirmData: AssetHistorySnapshot['reconfirms']; open: boolean }> = ({
  reconfirmData,
  open,
}) => {
  const lastReconfirm = useMemo(() => {
    return reconfirmData.reduce((latest, current) => {
      return compareDates(current.time, latest.time) === -1 ? latest : current
    })
  }, [reconfirmData])

  return (
    <div>
      <Flexbox feGap="xs">
        <Icon feIcon="history" feSize="sm" />

        {reconfirmData.length === 1 ? (
          <StyledReconfirmFlex feGap="sm">
            <span>{formatDate(lastReconfirm.time, DateFormats.AmericanAMPMFormat)}</span>
            <StyledReconfirmUsernameSpan>{lastReconfirm.userName}</StyledReconfirmUsernameSpan>
          </StyledReconfirmFlex>
        ) : open ? (
          <StyledReconfirmFlex feGap="sm">
            <span>{formatDate(lastReconfirm.time, DateFormats.AmericanAMPMFormat)}</span>
            <StyledReconfirmUsernameSpan>{lastReconfirm.userName}</StyledReconfirmUsernameSpan>
          </StyledReconfirmFlex>
        ) : (
          <StyledReconfirmFlex feGap="sm">
            <span>{formatDate(lastReconfirm.time, DateFormats.AmericanAMPMFormat)}</span>
            <span>({reconfirmData.length} reconfirms)</span>
          </StyledReconfirmFlex>
        )}
      </Flexbox>

      {open && reconfirmData.length > 1 && (
        <StyledReconfirmCollapseContainer feFlexDirection="row">
          <HistoryLinePrefix marginTop={SPACINGS.xxs} />
          <Flexbox feFlexDirection="column">
            {reconfirmData
              .filter((v) => v != lastReconfirm)
              .map((reconfirmData, i) => (
                <Row reconfirmData={reconfirmData} key={`${i}-reconfirm-row`} />
              ))}
          </Flexbox>
        </StyledReconfirmCollapseContainer>
      )}
    </div>
  )
}

const Row: FC<{ reconfirmData: AssetHistorySnapshot['reconfirms'][number] }> = ({ reconfirmData }) => {
  return (
    <StyledReconfirmFlex feGap="sm">
      <span>{formatDate(reconfirmData.time, DateFormats.AmericanAMPMFormat)}</span>
      <StyledReconfirmUsernameSpan>{reconfirmData.userName}</StyledReconfirmUsernameSpan>
    </StyledReconfirmFlex>
  )
}

export default HistoryReconfirmSection
