import { isEqual } from '@/shared/utils'
import { DependencyList, useCallback, useRef } from 'react'

/**
 * A hook that returns a memoized callback using deep comparison of dependencies.
 *
 * @template Args - The type of the callback function's arguments
 * @template Return - The return type of the callback function
 * @param {(...args: Args) => Return} callback - The callback function to memoize.
 * @param {DependencyList} dependencies - An array of dependencies for the callback, deeply compared.
 * @returns {(...args: Args) => Return} - Memoized callback function that only updates if dependencies change deeply.
 */
function useDeepCompareCallback<Args extends unknown[], Return>(
  callback: (...args: Args) => Return,
  dependencies: DependencyList
): (...args: Args) => Return {
  const lastDependenciesRef = useRef<DependencyList>()

  // Check if dependencies have changed deeply
  if (!lastDependenciesRef.current || !isEqual(lastDependenciesRef.current, dependencies)) {
    lastDependenciesRef.current = dependencies
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback(callback, [lastDependenciesRef.current])
}

export default useDeepCompareCallback
