import { useEffect, useRef } from 'react'

// /**
//  * useDebounce - A custom hook that delays the execution of an effect until the dependencies
//  * have stopped changing for a specified amount of time.
//  *
//  * @param {Function} effect - The function to be executed after the delay.
//  * @param {React.DependencyList} deps - The list of dependencies that trigger the effect when changed.
//  * @param {number} delay - The delay in milliseconds before the effect is executed.
//  */

export const useDebounce = (effect: () => void, deps: React.DependencyList, delay: number) => {
  const timeoutId = useRef<number>()

  useEffect(() => {
    timeoutId.current = window.setTimeout(effect, delay)

    return () => clearTimeout(timeoutId.current)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps)
}
