import { WidgetType } from '@/models/dashboard/types'
import { LoadingType } from './useApi'
import { addWidgetType, startGlobalLoading, startHeaderLoading, stopLoading } from '@/store/loading/action'
import { useAppDispatch } from '@/store/store'

/**
 * useLoadingDispatch is a custom hook that provides functions to dispatch loading actions
 * based on the specified loader type.
 *
 * @returns {(params: { loaderType?: number }[]) => void} - Function to handle loading actions.
 */

const useLoadingDispatch = () => {
  const loadingDispatch = useAppDispatch()

  /**
   * handleLoading dispatches loading actions based on the provided loaderType.
   *
   * @param {Object[]} params - Array of objects containing loaderType.
   * @param {number} params[].loaderType - Type of loader (GLOBAL, HEADER, NONE).
   */

  const handleLoading = (params: { loaderType?: number; widgetType?: WidgetType }[]) => {
    params.forEach((item) => {
      if (item.loaderType === LoadingType.GLOBAL) {
        loadingDispatch(startGlobalLoading())
      } else if (item.loaderType === LoadingType.HEADER) {
        loadingDispatch(startHeaderLoading())
      } else if (item.loaderType === LoadingType.WIDGET) {
        if (item.widgetType) {
          loadingDispatch(addWidgetType(item.widgetType))
        }
      } else {
        loadingDispatch(stopLoading())
      }
    })
  }

  return handleLoading
}

export default useLoadingDispatch
