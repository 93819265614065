import { useFaultEvidenceContext } from '@/contexts/moduleContexts/evidence'
import { EvidenceImageData } from '@/models/reportStatus/evidenceTypes'
import {
  StyledEvidenceTitleContainer,
  StyledEvidenceTitleSuffix,
  StyledEvidenceWrapper,
} from '@/modules/report-status/styled'
import useDeepCompareEffect from '@/shared/hooks/useDeepCompareEffect'
import { dataTestId } from '@/tests/testid'
import { FC } from 'react'
import DragAndDropUploader from './DragAndDrop'
import EvidenceImagesContainer from '@/modules/report-status/components/evidence/evidenceImagesContainer'
import { Flexbox } from '@skf-internal/ui-components-react'
import { useReportStatusContext } from '@/contexts/reportStatus'

interface EvidenceProps {
  isInEditMode: boolean
  existedEvidences: EvidenceImageData[]
}
const Evidence: FC<EvidenceProps> = ({ isInEditMode, existedEvidences = [] }) => {
  const { setFaultImages, faultId } = useFaultEvidenceContext()
  const { faultEditModeData, setFaultEditModeData } = useReportStatusContext()

  useDeepCompareEffect(() => {
    setFaultImages(existedEvidences)
    setFaultEditModeData({ ...faultEditModeData, [faultId]: isInEditMode })
  }, [existedEvidences, isInEditMode])

  return (
    <StyledEvidenceWrapper data-testid={dataTestId.reportStatusEvidence}>
      <StyledEvidenceTitleContainer>
        Evidence {existedEvidences.length ? `(${existedEvidences.length})` : ''}
        {isInEditMode && (
          <StyledEvidenceTitleSuffix>
            {'(Add up to 5 images to support your observation. Jpeg, Png or GIF format, up to 10MB per image)'}
          </StyledEvidenceTitleSuffix>
        )}
      </StyledEvidenceTitleContainer>
      <Flexbox>
        <DragAndDropUploader />
        <EvidenceImagesContainer />
      </Flexbox>
    </StyledEvidenceWrapper>
  )
}

export default Evidence
