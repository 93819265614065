import { getUsername } from '@/api/baseClientUtils'
import { useFaultsFormStateContext } from '@/contexts/moduleContexts/faultsForm'
import { useReportStatusContext } from '@/contexts/reportStatus'
import { Fault, RecommendedAction, RecommendedActionState } from '@/models/reportStatus/faultsFormStateTypes'
import FormMenu from '@/modules/report-status/components/formMenu'
import { MenuItem } from '@/modules/report-status/components/formMenu/types'
import { UPDATE_RECOMMENDED_ACTION } from '@/modules/report-status/reducer/actions.types'
import { StyledFaultMenu } from '@/modules/report-status/styled'
import { getEntityId } from '@/modules/report-status/utils/formUtils'
import { DateFormats, formatDate } from '@/shared/dateUtils'
import useDeepCompareCallback from '@/shared/hooks/useDeepCompareCallback'
import useDeepCompareMemo from '@/shared/hooks/useDeepCompareMemo'
import { FC, useCallback, useRef } from 'react'

interface RecommendedActionMenuProps {
  recommendedAction: RecommendedAction
  parentFault: Fault
}

const RecommendedActionMenu: FC<RecommendedActionMenuProps> = ({ recommendedAction, parentFault }) => {
  const { state } = recommendedAction
  const faultId = useRef<string>(getEntityId(parentFault))

  const isInEditMode = useDeepCompareMemo(() => {
    return [RecommendedActionState.EDIT, RecommendedActionState.PENDING_CLOSE, RecommendedActionState.NEW].includes(
      state
    )
  }, [state])

  const { faultsFormDispatch } = useFaultsFormStateContext()
  const { setAnalyticsCancelButtonBehavior } = useReportStatusContext()

  const editRecommendedAction = useCallback(
    (state: RecommendedActionState) => {
      faultsFormDispatch({
        type: UPDATE_RECOMMENDED_ACTION,
        payload: {
          id: getEntityId(recommendedAction),
          state,
          faultId: faultId.current,
        },
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [recommendedAction, faultId]
  )

  const closeRecommendedAction = useDeepCompareCallback(async () => {
    if (state === RecommendedActionState.OPEN) {
      const username = await getUsername()
      faultsFormDispatch({
        type: UPDATE_RECOMMENDED_ACTION,
        payload: {
          id: getEntityId(recommendedAction),
          state: RecommendedActionState.PENDING_CLOSE,
          faultId: faultId.current,
          username,
          closeDate: formatDate(new Date(), DateFormats.ISO8601Format),
        },
      })
    }
  }, [recommendedAction, faultId])

  const menuItems = useDeepCompareMemo<MenuItem[]>(() => {
    const items: MenuItem[] = []

    if (state === RecommendedActionState.OPEN && !isInEditMode) {
      items.push({
        label: 'Edit Recommended Action',
        icon: 'edit',
        onClick: () => {
          editRecommendedAction(RecommendedActionState.EDIT)
          setAnalyticsCancelButtonBehavior({ isDisabled: false })
        },
      })
      if (parentFault.recommendedActions.filter((ra) => ra.state === RecommendedActionState.OPEN).length > 1) {
        items.push({
          label: 'Close Recommended Action',
          icon: 'close',
          onClick: () => {
            setAnalyticsCancelButtonBehavior({ isDisabled: false })
            closeRecommendedAction()
          },
        })
      }
    }

    return items
  }, [isInEditMode, state, parentFault])

  return (
    <StyledFaultMenu feAlignItems="center" feJustifyContent="center">
      {menuItems.length > 0 && <FormMenu items={menuItems} />}
    </StyledFaultMenu>
  )
}

export default RecommendedActionMenu
