import { dataTestId } from '@/tests/testid'
import { Icon, IconColor, IconName } from '@skf-internal/ui-components-react'
import { FC } from 'react'

interface ToastContentProps {
  startTextContent: string
  iconName?: IconName
  iconColor?: IconColor
  endTextContent?: string
}

/**
 * The ToastContent component is a reusable UI component designed to display a text message
 * with an optional icon in a toast notification. It accepts text content for both the start
 * and end positions, as well as optional icon properties to display an icon between the texts.
 *
 * The ToastContent component can be used to create consistent toast messages across the application.
 * It leverages the Icon component from @skf-internal/ui-components-react for displaying icons.
 *
 * Props:
 * - `startTextContent` (**required**): The text content to be displayed at the start of the toast.
 * - `iconName` (optional): The name of the icon to be displayed. If provided, `iconColor` must also be provided.
 * - `iconColor` (optional): The color of the icon to be displayed. If provided, `iconName` must also be provided.
 * - `endTextContent` (optional): The text content to be displayed at the end of the toast.
 *
 * Example usage:
 * ```jsx
 * <ToastContent
 *   startTextContent="Data saved"
 *   iconName="checkCircle"
 *   iconColor="green"
 *   endTextContent="successfully!"
 * />
 * ```
 *
 * @param {ToastContentProps} props - The properties to configure the ToastContent component.
 * @returns {JSX.Element} The rendered ToastContent component.
 */

export const ToastContent: FC<ToastContentProps> = ({ iconName, iconColor, startTextContent, endTextContent }) => (
  <div style={{ display: 'flex', alignItems: 'center' }} data-testid={dataTestId.toastContent}>
    {startTextContent}
    {iconName && iconColor && (
      <Icon
        feIcon={iconName}
        feColor={iconColor}
        feSize="sm"
        style={{ marginLeft: '5px', marginRight: '5px' }}
        data-testid="toast-icon"
      />
    )}
    {endTextContent}
  </div>
)
