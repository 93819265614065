import { ReportStatusLoaderData } from '@/app/routes/types'
import { FaultsFormStateContextProvider } from '@/contexts/moduleContexts/faultsForm'
import { useReportStatusContext } from '@/contexts/reportStatus'
import { Assets } from '@/models/analysisBacklog/types'
import ConflictDialog from '@/modules/report-status/components/conflictDialog'
import ReportStatusDialogContent from '@/modules/report-status/components/reportStatusDialogContent'
import { getLastCollectedDate } from '@/modules/report-status/utils/formUtils'
import { ROUTES } from '@/shared/constants'
import useEscapeKey from '@/shared/hooks/useEscapeKey'
import { STORAGE_REPORT_STATUS_ORIGIN } from '@/shared/localStorageUtils'
import { openToast } from '@/store/genericToast/actions'
import { useAppDispatch, useTypedSelector } from '@/store/store'
import { Dialog, DialogProps } from '@skf-internal/ui-components-react'
import { ToastProps } from '@skf-internal/ui-components-react/dist/components/toast/Toast'
import { useEffect } from 'react'
import { useLoaderData, useLocation, useNavigate, useParams } from 'react-router-dom'
import EvidenceModal from '@/modules/report-status/components/evidenceDailog'

const reportStatusDialogProps: Omit<DialogProps, 'children' | 'open'> = {
  feTitle: 'Report Status',
  feNoPadding: true,
  feFullscreen: true,
}

const ReportStatusDialog = () => {
  const { assetId } = useParams()

  const { assetRow, assetFaultsAndHistory } = useLoaderData() as ReportStatusLoaderData

  const reportStatusDialogDispatch = useAppDispatch()

  const {
    reportStatusDialogData: { closeDialog, isOpen },
    assetsData,
    setReportStatusDialogData,
    isConflictDialogOpen,
    isEvidenceFaultImageModalOpen,
  } = useReportStatusContext()

  const navigate = useNavigate()
  const { state } = useLocation()

  const {
    asyncPoller: { isAsyncPollerActive },
    genericConfirmDialogData: { isOpen: isConfirmDialogOpen },
  } = useTypedSelector((state) => ({ ...state }))

  useEffect(() => {
    if (assetId && !isOpen) {
      setReportStatusDialogData({
        isOpen: true,
        closeDialog: (toastProps: ToastProps | undefined, assetsToState?: Assets) => {
          if (toastProps !== undefined) {
            reportStatusDialogDispatch(openToast({ ...toastProps }))
          }
          setReportStatusDialogData({ isOpen: false })
          const backUrl = sessionStorage.getItem(STORAGE_REPORT_STATUS_ORIGIN) as ROUTES

          // sessionStorage.removeItem(STORAGE_REPORT_STATUS_ORIGIN)

          navigate(backUrl || ROUTES.HOME, {
            state: { ...state, from: ROUTES.REPORT_STATUS, assetsData: assetsToState },
          })
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetId])

  useEffect(() => {
    if (closeDialog && assetRow === undefined) {
      closeDialog({
        feSeverity: 'error',
        children: 'Could not find asset',
      })
    }
  }, [assetRow, closeDialog])

  useEscapeKey(
    () =>
      !isConfirmDialogOpen && !isConflictDialogOpen && !isEvidenceFaultImageModalOpen && closeDialog && closeDialog(),
    [closeDialog]
  )

  return (
    <Dialog
      open={isOpen}
      feCloseButton={{
        disabled: isAsyncPollerActive,
      }}
      feOnClose={() => closeDialog && closeDialog(undefined, assetsData)}
      {...reportStatusDialogProps}
    >
      <FaultsFormStateContextProvider
        initialFaultsFormState={assetFaultsAndHistory.openFaults.map((v) => v.fault)}
        lastCollectionDate={getLastCollectedDate(assetRow?.lastCollectedDate)}
      >
        <ReportStatusDialogContent />
        <ConflictDialog />
        <EvidenceModal />
      </FaultsFormStateContextProvider>
    </Dialog>
  )
}
export default ReportStatusDialog
