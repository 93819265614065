import { css } from '@emotion/react'
import { IconButton, Collapse } from '@skf-internal/ui-components-react'
import { addMinutes, format, parseISO } from 'date-fns'
import { FC, useMemo, useState } from 'react'
import { useTypedSelector } from '@/store/store'
import { AssetHistorySnapshot, CustomerNotesMessage } from '@/models/reportStatus/types'
import { useLoaderData, useParams } from 'react-router-dom'
import { ReportStatusLoaderData } from '@/app/routes/types'
import { formatSkfCustomerNote, getFaults, getReversedHistory } from '@/modules/report-status/utils'
import { orderBy, uniqueIdPrefixable } from '@/shared/utils'
import { AssetStatusIcon } from '@/modules/analysis-backlog/status-icon'
import { TextEllipsis } from '@/common/text-ellipsis'
import {
  StyledFaultHistoryCollapseContainer,
  StyledFaultHistoryCollapseInnerContainer,
  StyledFaultHistoryCollectionDateRow,
  StyledFaultHistoryCollectionDateRowChildren,
  StyledFaultHistoryContainer,
  StyledFaultHistoryCustomerNotesRow,
  StyledFaultHistoryEvidenceRow,
  StyledFaultHistoryMainContainer,
  StyledFaultHistoryObservation,
  StyledFaultHistoryOpenIconButton,
  StyledFaultHistoryRecommendedActionRow,
  StyledFaultHistoryTopContainer,
  StyledFaultHistoryTypeText,
} from '@/modules/report-status/styled'
import RecommendedActionHistory from '@/modules/report-status/components/history/RecommendedActionHistory'
import CustomerNotesHistory from '@/modules/report-status/components/history/customer-notes/CustomerNotesHistory'
import { RecommendedActionDateDisplay } from '@/modules/report-status/components/history/DateDisplay'
import EvidenceHistory from '@/modules/report-status/components/history/evidence-history'
import { dataTestId } from '@/tests/testid'

const stateLabel: Record<'new' | 'open' | 'closed', string> = {
  new: 'Created',
  open: 'Open',
  closed: 'Closed',
}

const FaultHistory: FC<{
  fault: AssetHistorySnapshot['faults'][number]
  assetId: string
  reportTimestamp: string
  userName: string
}> = ({ fault, reportTimestamp, userName }) => {
  const {
    reportFaultTypes,
    assetFaultsAndHistory,
    customerNotes: customerNotesData,
  } = useLoaderData() as ReportStatusLoaderData

  const [open, setOpen] = useState(false)
  const { history, openFaults } = assetFaultsAndHistory
  const { assetId } = useParams()

  const getFaultTypeString = () => {
    return reportFaultTypes !== undefined
      ? `${fault.id} ${reportFaultTypes.find((v) => v.code === fault.fault)?.text}`
      : ''
  }

  const {
    selectedCustomer: { customerNotesEnabled },
  } = useTypedSelector((state) => state)

  const recommendedActionIds = useMemo(
    () => (fault ? fault.recommendedActions.map((_) => uniqueIdPrefixable('recAction_')) : []),
    [fault]
  )

  if (history === undefined || openFaults === undefined) {
    return null
  }

  const validSnapshots = getReversedHistory(history).filter((snapshot) => {
    return snapshot.timestamp <= reportTimestamp
  })

  const customerNotes: CustomerNotesMessage[] = validSnapshots.flatMap((snapshot) => {
    return snapshot.faults
      .filter((f) => f.id === fault.id)
      .map((snapshotFault) => formatSkfCustomerNote(snapshotFault, snapshot.timestamp, snapshot.username))
  })

  const validFaultsWithCustomerNotes = getFaults(assetFaultsAndHistory, customerNotesData, assetId).find(
    (openFault) => openFault.fault.id === fault.id
  )
  const parsedCollectionDate = parseISO(fault.collectionDate)
  const formattedCollectionDate = format(
    addMinutes(parsedCollectionDate, parsedCollectionDate.getTimezoneOffset()),
    'M/d/yyyy'
  )

  if (validFaultsWithCustomerNotes) {
    validFaultsWithCustomerNotes.metadata.customerNotes.forEach((customerNote) => {
      if (parseISO(customerNote.noteDate) <= parseISO(reportTimestamp) && customerNote.acknowledgment === true) {
        customerNotes.push(customerNote)
      }
    })
  }

  return (
    <StyledFaultHistoryMainContainer
      feFlexDirection="column"
      feJustifyContent="space-between"
      data-testid={dataTestId.faultHistory}
    >
      <StyledFaultHistoryTopContainer feFlexDirection="row" feJustifyContent="space-between">
        <StyledFaultHistoryContainer feAlignItems="center" feGap="xs">
          <AssetStatusIcon value={fault.status} />
          <StyledFaultHistoryTypeText rowsUntilEllipsis={1} value={getFaultTypeString()} />
        </StyledFaultHistoryContainer>
        <StyledFaultHistoryObservation feFlexDirection="column" feGap="xs" feJustifyContent="left">
          <TextEllipsis label="Observation" value={fault.observation} rowsUntilEllipsis={3} />
        </StyledFaultHistoryObservation>
        <StyledFaultHistoryOpenIconButton feAlignItems="center" feGap="sm" feJustifyContent="end">
          <span>
            <b>{stateLabel[fault.state]}</b>
          </span>
          <IconButton
            as="button"
            type="button"
            feSize="md"
            aria-label={open ? 'Open' : 'Close'}
            onClick={() => setOpen((o) => !o)}
            feIcon={open ? 'chevronUp' : 'chevronDown'}
          />
        </StyledFaultHistoryOpenIconButton>
      </StyledFaultHistoryTopContainer>
      {open && (
        <StyledFaultHistoryCollapseContainer feFlexDirection="column" feJustifyContent="space-between">
          <StyledFaultHistoryCollapseInnerContainer feFlexDirection="column" feJustifyContent="space-between">
            <StyledFaultHistoryCollectionDateRow feFlexDirection="row" feJustifyContent="flex-start">
              <StyledFaultHistoryCollectionDateRowChildren>
                <RecommendedActionDateDisplay label="Collection date" value={formattedCollectionDate} />
              </StyledFaultHistoryCollectionDateRowChildren>
              <StyledFaultHistoryCollectionDateRowChildren>
                {fault.state === 'closed' && open && (
                  <TextEllipsis
                    label="Was there a correct diagnosis?"
                    value={fault.correctDiagnostic ? 'Yes' : 'No'}
                    rowsUntilEllipsis={0}
                  />
                )}
              </StyledFaultHistoryCollectionDateRowChildren>

              <StyledFaultHistoryCollectionDateRowChildren>
                {fault.state === 'closed' && open && !fault.correctDiagnostic && (
                  <TextEllipsis label="Explanation" value={fault.explanation} rowsUntilEllipsis={3} />
                )}
              </StyledFaultHistoryCollectionDateRowChildren>
            </StyledFaultHistoryCollectionDateRow>

            <StyledFaultHistoryEvidenceRow feFlexDirection="column" feJustifyContent="space-around">
              <EvidenceHistory evidences={fault.evidences} faultId={fault.id} />
            </StyledFaultHistoryEvidenceRow>

            <StyledFaultHistoryRecommendedActionRow feFlexDirection="column" feJustifyContent="space-around">
              {fault.recommendedActions.map((recommendedAction, index) => (
                <RecommendedActionHistory key={recommendedActionIds[index]} recommendedAction={recommendedAction} />
              ))}
            </StyledFaultHistoryRecommendedActionRow>
          </StyledFaultHistoryCollapseInnerContainer>
          {customerNotesEnabled && customerNotes.length > 0 && (
            <StyledFaultHistoryCustomerNotesRow feFlexDirection="row" feJustifyContent="space-between">
              <Collapse
                css={css`
                  background: #e2e4e7 !important;
                  border: none !important;

                  h2 {
                    padding-left: 2rem;
                    background-color: #d7dadd;
                    border-radius: 0.5rem 0.5rem 0 0;
                  }

                  width: 100%;
                  margin: 1rem 0;
                `}
                feTitle={'Customer Notes'}
                feTitleAs="h2"
              >
                {orderBy(customerNotes, ['noteDate'], ['desc']).map((customerNote, i) => (
                  <CustomerNotesHistory key={i} customerNote={customerNote} userName={userName} />
                ))}
              </Collapse>
            </StyledFaultHistoryCustomerNotesRow>
          )}
        </StyledFaultHistoryCollapseContainer>
      )}
    </StyledFaultHistoryMainContainer>
  )
}
export default FaultHistory
