import { Flexbox, COLORS } from '@skf-internal/ui-components-react'
import { FC, useState } from 'react'
import { AnalysisReasonStringType, AssetRow, ReasonIndicatorData } from '@/models/analysisBacklog/types'
import { Row } from '@tanstack/react-table'
import ReasonIndicatorFilter from '@/modules/analysis-backlog/asset-table/reasonIndicator/filter'
import { withColumnFilterPopover } from '@/common/table/filters/filter-popover-wrapper'
import { ReasonIndicatorFilterProps } from '@/modules/analysis-backlog/asset-table/reasonIndicator/types'
import { StyledAnalysisReasonFilterList } from '@/modules/analysis-backlog/asset-table/filters/analysis-reason-filter/styled'

const analysisReasonsString: Record<AnalysisReasonStringType, string> = {
  ai: 'AI alarm',
  band_alarm: 'Band alarm',
  band_alert: 'Band alert',
  device_fault: 'Device fault',
  diagnostics_alarm: 'Diagnostics alarm',
  diagnostics_alert: 'Diagnostics alert',
  feedback: 'Feedback',
  no_sensor_data: 'No sensor data',
  overall_alarm: 'Overall alarm',
  overall_alert: 'Overall alert',
  protean_alarm: 'Protean alarm',
}

export const analysisReasonFilterFn = (
  row: Row<AssetRow>,
  columnId: string,
  filteredAnalysisReasons: AnalysisReasonStringType[]
) => {
  const rowValue = row.getValue<ReasonIndicatorData[] | undefined>(columnId)

  if (!rowValue) {
    return false
  }

  return filteredAnalysisReasons.every((filteredAnalysisReasonValue) =>
    rowValue.some((rowValue) => {
      const reasonType = rowValue.type
      if ('severity' in rowValue && filteredAnalysisReasonValue === `${reasonType}_${rowValue.severity}`) {
        return true
      } else if (!('severity' in rowValue) && filteredAnalysisReasonValue === reasonType) {
        return true
      }
    })
  )
}

const AnalysisReasonPopover: FC<{
  initialValue?: AnalysisReasonStringType[]
  onFilterValueChange: (value?: AnalysisReasonStringType[]) => void
}> = ({ initialValue, onFilterValueChange }) => {
  const [filteredAnalysisReasons, setFilteredAnalysisReasons] = useState<AnalysisReasonStringType[]>(initialValue || [])

  const findAnalysisReasonValue = (value: AnalysisReasonStringType): boolean =>
    filteredAnalysisReasons.filter((v) => v === value)[0] !== undefined

  const handleOnChange = (reason: AnalysisReasonStringType, checked: boolean) => {
    let newFilterValue: AnalysisReasonStringType[] | undefined = undefined

    if (checked) {
      newFilterValue = [...filteredAnalysisReasons, reason]
      setFilteredAnalysisReasons(newFilterValue)
    } else {
      newFilterValue = filteredAnalysisReasons.filter((v) => v !== reason)
      setFilteredAnalysisReasons(newFilterValue)
    }

    if (newFilterValue.length === 0) {
      newFilterValue = undefined
    }

    onFilterValueChange(newFilterValue)
  }

  const reasonFiltersLeft = [
    { reasonSeverity: 'alarm', reasonText: 'Band', reasonType: 'band_alarm' },
    { reasonIcon: { iconColor: 'red', iconName: 'danger' }, reasonText: 'AI alarm', reasonType: 'ai' },
    { reasonSeverity: 'alert', reasonText: 'DiagX', reasonType: 'diagnostics_alert' },
    { reasonSeverity: 'alarm', reasonText: 'Overall', reasonType: 'overall_alarm' },
    { reasonIcon: { iconColor: 'purple', iconName: 'sensor' }, reasonText: 'Device fault', reasonType: 'device_fault' },
    {
      reasonIcon: { iconColor: 'white', iconName: 'unknownOutline' },
      reasonText: 'Sensor data missing',
      reasonType: 'no_sensor_data',
      customStyle: { backgroundColor: COLORS.gray600, color: COLORS.white },
    },
  ] as ReasonIndicatorFilterProps[]

  const reasonFiltersRight = [
    { reasonSeverity: 'alert', reasonText: 'Band', reasonType: 'band_alert' },
    { reasonSeverity: 'alarm', reasonText: 'DiagX', reasonType: 'diagnostics_alarm' },
    { reasonIcon: { iconColor: 'red', iconName: 'danger' }, reasonText: 'Protean alarm', reasonType: 'protean_alarm' },
    { reasonSeverity: 'alert', reasonText: 'Overall', reasonType: 'overall_alert' },
    {
      reasonIcon: { iconColor: 'blue', iconName: 'email' },
      reasonText: 'Feedback',
      reasonType: 'feedback',
      withBadge: true,
    },
  ] as ReasonIndicatorFilterProps[]

  return (
    <Flexbox feFlexDirection="row" feGap="xxs">
      {[reasonFiltersLeft, reasonFiltersRight].map((filters, index) => (
        <Flexbox key={index} feFlexDirection="column" feGap="xs">
          {filters.map(({ reasonIcon, reasonSeverity, reasonText, reasonType, customStyle, withBadge }) => (
            <ReasonIndicatorFilter
              key={reasonType}
              reasonIcon={reasonIcon}
              reasonSeverity={reasonSeverity}
              reasonText={reasonText}
              checked={findAnalysisReasonValue(reasonType)}
              onChange={handleOnChange}
              reasonType={reasonType}
              customStyle={customStyle}
              withBadge={withBadge}
            />
          ))}
        </Flexbox>
      ))}
    </Flexbox>
  )
}

export const AnalysisReasonFilter = withColumnFilterPopover<AssetRow, AnalysisReasonStringType[]>(
  AnalysisReasonPopover,
  (filterValue: AnalysisReasonStringType[]) => (
    <StyledAnalysisReasonFilterList>
      {filterValue.map((v, index) => (
        <li key={index}>{analysisReasonsString[v]}</li>
      ))}
    </StyledAnalysisReasonFilterList>
  )
)
