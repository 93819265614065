import { StyledHistoryRowIconContainer } from '../../styled'
import { AssetStatusIcon } from '@/modules/analysis-backlog/status-icon'
import { getWorstStatus } from '../../utils'
import { Status } from '@/models/reportStatus/faultsFormStateTypes'
import { DataEntry } from '@/models/reportStatus/types'
import { dataTestId } from '@/tests/testid'

export interface AssetStatus {
  snapshot: DataEntry | DataEntry[]
}

export const AssetStatus = ({ snapshot }: AssetStatus) => {
  const statusValue = !Array.isArray(snapshot) ? getWorstStatus(snapshot) : Status.NORMAL

  return (
    <StyledHistoryRowIconContainer feAlignItems="center">
      <AssetStatusIcon value={statusValue} data-testid={dataTestId.assetStatusIcon} />
    </StyledHistoryRowIconContainer>
  )
}
