import { CSSProperties } from 'react'
import { BadgeProps } from '@/shared/components/Badge'

export const getStyles = (props: BadgeProps) => {
  const badgeStyles: CSSProperties = {
    top: props.verticalAlignment === 'top' ? -3 : 'auto',
    bottom: props.verticalAlignment === 'bottom' ? 0 : 'auto',
    left: props.horizontalAlignment === 'left' ? -3 : 'auto',
    right: props.horizontalAlignment === 'right' ? -3 : 'auto',
    height: props.size || !props.badgeContent ? '7px' : '20px',
    width: props.size || !props.badgeContent ? '7px' : '20px',
    backgroundColor: props.bgColor,
    color: props.contentColor,
  }
  return badgeStyles
}

export const containerStyles: CSSProperties = {
  display: 'inline-flex',
  position: 'relative',
  flexShrink: 0,
  verticalAlign: 'middle',
}

export const rawBadgeStyles: CSSProperties = {
  display: 'flex',
  position: 'absolute',
  flexWrap: 'wrap',
  fontSize: '0.75rem',
  boxSizing: 'border-box',
  transition: 'transform 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  alignItems: 'center',
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  fontWeight: 500,
  lineHeight: 1,
  alignContent: 'center',
  borderRadius: '10px',
  flexDirection: 'row',
  justifyContent: 'center',
}
