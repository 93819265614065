import routesConfig from '@/app/routes/routesConfig'
import { initDatadog, setDatadogUser } from '@/datadogConfig'
import { useEffectOnce } from '@/shared/hooks/useEffectOnce'
import {
  clearModuleHeaderActionPanelData,
  clearModuleHeaderConfigData,
  clearModuleHeaderCSVData,
} from '@/store/moduleHeader/actions'
import { useAppDispatch } from '@/store/store'
import { useEffect } from 'react'
import { createBrowserRouter, NavigationType, RouterProvider } from 'react-router-dom'

const RouterConsumer = () => {
  const routerConsumerDispatch = useAppDispatch()
  const router = createBrowserRouter(routesConfig())

  useEffect(() => {
    const unsubscribe = router.subscribe((state) => {
      if (state.historyAction === NavigationType.Pop) {
        routerConsumerDispatch(clearModuleHeaderActionPanelData())
        routerConsumerDispatch(clearModuleHeaderConfigData())
        routerConsumerDispatch(clearModuleHeaderCSVData())
      }
    })

    return () => unsubscribe()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffectOnce(() => {
    initDatadog()
    setDatadogUser()
  })

  return (
    <>
      <RouterProvider router={router} />
      {/* {children} */}
    </>
  )
}

export default RouterConsumer
