import { formatISO } from 'date-fns'
import {
  AssetRowHandled,
  AssetRowUnhandled,
  ReasonIndicatorData,
  ReportedAssetRowUnhandled,
} from '@/models/analysisBacklog/types'
import { sumBy } from 'lodash-es'

type Assets = { handled: AssetRowHandled[]; unhandled: AssetRowUnhandled[] }

// Moves an unhandled asset row, that is normal or never reported, to handled.
// or
// Updates a handled asset, that is normal or never reported
// Mutates assets.
export function markAsNormal(assetId: string, assets: Assets): void {
  const unhandledAsset = assets.unhandled.find((assetRow) => assetRow.asset.id === assetId)

  // Attempt to mark handled asset as normal again
  if (unhandledAsset === undefined) {
    const handledAsset = assets.handled.find((assetRow) => assetRow.asset.id === assetId)

    if (handledAsset === undefined) {
      throw new Error('cannot find asset with id: ' + assetId)
    }

    handledAsset.status = 'normal'
    handledAsset.lastReportedDate = formatISO(new Date())
  }
  // Mark unhandled asset as handled, and mark it as normal.
  else {
    moveFromUnhandledToHandled(
      {
        active: unhandledAsset.active,
        analysisReasons: unhandledAsset.analysisReasons,
        asset: unhandledAsset.asset,
        belongsTo: unhandledAsset.belongsTo,
        criticality: unhandledAsset.criticality,
        handled: unhandledAsset.handled,
        lastCollectedDate: unhandledAsset.lastCollectedDate,
        lastReportedDate: formatISO(new Date()),
        priority: unhandledAsset.priority,
        status: 'normal',
        isInaccuratePriority: unhandledAsset.isInaccuratePriority,
      },
      assets
    )
  }
}

export function moveFromUnhandledToHandled(unhandledAsset: ReportedAssetRowUnhandled, assets: Assets) {
  const unhandledIndex = unhandledAsset.priority - 1

  // Removes from unhandled
  assets.unhandled.splice(unhandledIndex, 1)

  for (let i = unhandledIndex; i < assets.unhandled.length; i++) {
    assets.unhandled[i].priority -= 1
  }

  assets.handled.push(transformUnhandledAssetToHandled(unhandledAsset))
}

function transformUnhandledAssetToHandled(unhandledAsset: ReportedAssetRowUnhandled): AssetRowHandled {
  return {
    active: unhandledAsset.active,
    asset: unhandledAsset.asset,
    belongsTo: unhandledAsset.belongsTo,
    criticality: unhandledAsset.criticality,
    lastCollectedDate: unhandledAsset.lastCollectedDate,
    lastReportedDate: unhandledAsset.lastReportedDate,
    status: unhandledAsset.status,
    handled: true,
  } as AssetRowHandled
}

export function getCountType(reason: ReasonIndicatorData): 'alarm' | 'alert' | 'none' {
  switch (reason.type) {
    case 'band':
      return reason.severity
    case 'diagnostics':
      return 'alarm'
    case 'protean':
      return 'alarm'
    case 'ai':
      return 'alarm'
    case 'overall':
      return reason.severity
    case 'feedback':
      return 'none'
    case 'device_fault':
      return 'none'
    case 'no_sensor_data':
      return 'none'
  }
}

export const count = <T>(list: T[], f: (arg: T) => boolean) => sumBy(list, (i) => (f(i) ? 1 : 0))
