import { AssetStatus } from '@/models/unresolvedRecommendations/types'
import AssetStatusIcon from '@/modules/unresolved-recommendation/components/status-icon'
import { StyledURStatusCheckbox } from '@/modules/unresolved-recommendation/styled'
import { dataTestId } from '@/tests/testid'
import { Flexbox } from '@skf-internal/ui-components-react'
import { FC } from 'react'

interface StatusCheckboxProps {
  status: AssetStatus
  checked: boolean
  onChange: (status: AssetStatus, checked: boolean) => void
}
const StatusCheckbox: FC<StatusCheckboxProps> = ({ status, checked, onChange }: StatusCheckboxProps) => {
  return (
    <Flexbox feAlignItems="center" feJustifyContent="space-between" feGap="md">
      <StyledURStatusCheckbox
        name={status}
        checked={checked}
        feLabel={status}
        onChange={(_, checked) => onChange(status, checked)}
        data-testid={dataTestId.unresolvedRecommendationsStatusCheckbox}
      />
      <AssetStatusIcon value={status} />
    </Flexbox>
  )
}

export default StatusCheckbox
