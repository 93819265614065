import { Button } from '@skf-internal/ui-components-react'
import { FC } from 'react'

type GenericConfirmationDialogButtonProps<T> = {
  label: string
  type: 'primary' | 'secondary'
  onClick: (event: React.MouseEvent<T, MouseEvent>) => void
}

const GenericConfirmationDialogButton: FC<GenericConfirmationDialogButtonProps<HTMLButtonElement>> = ({
  label,
  type,
  onClick,
}: GenericConfirmationDialogButtonProps<HTMLButtonElement>) => {
  return (
    <Button feType={type} onClick={onClick}>
      {label}
    </Button>
  )
}

export default GenericConfirmationDialogButton
