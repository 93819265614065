import GenericLoader, { GenericLoaderType } from '@/shared/components/GenericLoader'
import { useFetchDataContext } from '@/contexts/common/fetchDataContext'
import BearingSpinner from '@/shared/components/BearingSpinner'
import HeaderClearFiltersButton from '@/shared/components/ModuleHeader/clearFiltersButton'
import HeaderExportCSVButton from '@/shared/components/ModuleHeader/exportButton'
import HeaderRefreshButton from '@/shared/components/ModuleHeader/refreshButton'
import HeaderTableStateChangeSwitch from '@/shared/components/ModuleHeader/stateChangeSwitch'
import { useTypedSelector } from '@/store/store'
import { dataTestId } from '@/tests/testid'
import { Flexbox, SPACINGS, css } from '@skf-internal/ui-components-react'

/**
 * HeaderActionPanel component holds action buttons of the module header
 *
 * HeaderActionPanel shows its children by passed headerActionPanelConfig redux object
 * @returns {JSX.Element} The rendered component.
 */

const HeaderActionPanel = () => {
  const {
    moduleHeaderData: {
      headerActionPanelConfig: {
        withClearFiltersButton,
        withTableStateChangeSwitch,
        withRefreshButton,
        withCSVDownloadButton,
      },
    },
  } = useTypedSelector((store) => ({ ...store }))
  const { fetchDataStatus } = useFetchDataContext()

  return (
    <Flexbox
      feJustifyContent="space-evenly"
      feAlignItems="center"
      css={css`
        gap: ${SPACINGS.md};
        padding-top: 20px;
      `}
      data-testid={dataTestId.headerActionPanel}
    >
      <BearingSpinner />
      <GenericLoader fetchDataStatus={fetchDataStatus} loaderType={GenericLoaderType.HEADER} />
      {withClearFiltersButton && <HeaderClearFiltersButton />}
      {withTableStateChangeSwitch && <HeaderTableStateChangeSwitch />}
      {withRefreshButton && <HeaderRefreshButton />}
      {withCSVDownloadButton && <HeaderExportCSVButton />}
    </Flexbox>
  )
}

export default HeaderActionPanel
