import { dataTestId } from '@/tests/testid'
import { css } from '@emotion/react'
import { Loader } from '@skf-internal/ui-components-react'
import { useTypedSelector } from '@/store/store'
import { AsyncStatus } from '@/shared/hooks/useAsync'
import { StyledGlobalLoaderWrapper, StyledHeaderLoaderWrapper } from './styled'
import { CenteredLoader } from '@/common/centered-loader'

export enum GenericLoaderType {
  HEADER = 'header',
  WIDGET = 'widget',
  GLOBAL = 'global',
}

interface GenericLoader {
  fetchDataStatus?: AsyncStatus
  loaderType?: GenericLoaderType
}

/**
 * GenericLoader is a React component that conditionally renders different types of loaders
 * (global, header, or widget) based on the loading state and loader type.
 *
 * It renders:
 * - A global loader when the `isGlobalLoading` flag is true.
 * - A header loader when the `isHeaderLoading` flag is true, `loaderType` is 'header',
 *   and the `fetchDataStatus` is 'loading'.
 * - A widget loader when there are active `widgetTypes` and the `loaderType` is 'widget'.
 *
 * @param {Object} props - The component props.
 * @param {AsyncStatus} [props.fetchDataStatus] - The status of the async data fetching process.
 *   Possible values: 'loading', 'success', 'error', 'not-executed'.
 * @param {GenericLoaderType} [props.loaderType=GenericLoaderType.GLOBAL] - The type of loader to render.
 *   Can be 'header', 'widget', or 'global'.
 * @returns {JSX.Element | null} A JSX element representing the loader or null if no loader should be shown.
 */

const GenericLoader: React.FC<GenericLoader> = ({ fetchDataStatus, loaderType = GenericLoaderType.GLOBAL }) => {
  const {
    globalLoading: { isGlobalLoading },
    headerLoading: { isHeaderLoading },
    widgetLoading: { widgetTypes },
  } = useTypedSelector((store) => ({
    ...store,
  }))

  const {
    moduleHeaderData: {
      headerActionPanelConfig: { withLoader, isAsyncIndicatorShown },
    },
  } = useTypedSelector((store) => ({ ...store }))

  if (widgetTypes.length > 0 && loaderType === GenericLoaderType.WIDGET) {
    return <CenteredLoader />
  } else if (isHeaderLoading && loaderType === GenericLoaderType.HEADER) {
    return (
      <StyledHeaderLoaderWrapper
        data-testid={dataTestId.genericHeaderLoader}
        isVisible={!isAsyncIndicatorShown && withLoader && fetchDataStatus === 'loading'}
      >
        {isHeaderLoading && <Loader feSize="sm" data-testid={dataTestId.headerLoader} />}
      </StyledHeaderLoaderWrapper>
    )
  } else if (isGlobalLoading) {
    return (
      <StyledGlobalLoaderWrapper data-testid={dataTestId.genericGlobalLoader}>
        <Loader
          css={css`
            display: flex;
            height: 100vh;
            margin: 0 auto;
          `}
        />
      </StyledGlobalLoaderWrapper>
    )
  }
}

export default GenericLoader
