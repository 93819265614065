import { getSiteDetailsLogo } from '@/api/paths/documents/files'
import { DarwinDetachedModuleError } from '@/common/error-boundary/DarwinError'
import { useSiteDetailsContext } from '@/contexts/siteDetails'
import { SiteDetailsLogoResponse } from '@/models/siteDetails/types'
import { useApi } from '@/shared/hooks/useApi'
import { useAsyncThrow } from '@/shared/hooks/useAsyncThrow'
import { AxiosError } from 'axios'

const useGetSiteDetailsLogo = (fileId: string) => {
  const throwError = useAsyncThrow()
  const { setLogoURL } = useSiteDetailsContext()
  return useApi(() => {
    return getSiteDetailsLogo(
      {
        onSuccess: async (result: SiteDetailsLogoResponse) => {
          setLogoURL(result.length ? result[0].fileURL : '')
        },
        onError: async (error: AxiosError) => {
          throwError(new DarwinDetachedModuleError(error))
        },
      },
      {
        fileId,
      }
    )
  })
}

export default useGetSiteDetailsLogo
