import { useFetchDataContext } from '@/contexts/common/fetchDataContext'
import { useTypedSelector } from '@/store/store'
import { dataTestId } from '@/tests/testid'
import { Switch } from '@skf-internal/ui-components-react'

/**
 * HeaderTableStateChangeSwitch is the switch that located in header action panel
 * and it responsible for trigger related module table state change function
 *
 * HeaderTableStateChangeSwitch can be configured by label and related state
 * @returns {JSX.Element} The rendered component.
 */
const HeaderTableStateChangeSwitch = () => {
  const { fetchDataStatus } = useFetchDataContext()

  const {
    moduleHeaderData: {
      headerActionPanelConfig: {
        tableStateChangeSwitchLabel,
        tableStateChangeSwitchCurrentState,
        tableStateChangeSwitchFn,
        isAsyncIndicatorShown,
      },
    },
  } = useTypedSelector((store) => ({ ...store }))

  return (
    <Switch
      disabled={fetchDataStatus === 'loading' && !isAsyncIndicatorShown}
      feLabel={tableStateChangeSwitchLabel || ''}
      checked={tableStateChangeSwitchCurrentState}
      onChange={(_, showHandled) => {
        tableStateChangeSwitchFn && tableStateChangeSwitchFn(showHandled)
      }}
      data-testid={dataTestId.headerTableStateChangeSwitch}
    />
  )
}

export default HeaderTableStateChangeSwitch
