import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { COLORS, SPACINGS } from '@skf-internal/ui-components-react'
import { FC, PropsWithChildren, forwardRef } from 'react'

interface ScrollableInsetProps extends PropsWithChildren {
  bottomMargin?: string
}

export const ScrollableInset = forwardRef<HTMLDivElement, ScrollableInsetProps>(
  ({ children, bottomMargin = '16rem' }, ref) => (
    <div
      ref={ref}
      css={css`
        overflow: auto;
        position: relative;
        max-height: calc(100vh - ${bottomMargin});
      `}
    >
      {children}
    </div>
  )
)

export const StyledTable = styled.table`
  border-spacing: 0;
  width: 100%;
  table-layout: fixed;

  thead {
    background-color: ${COLORS.gray100};
    margin: 0;
    position: sticky;
    top: 0;
  }

  tbody tr {
    width: 100%;
    height: 46px;
  }

  th {
    text-align: left;
    user-select: none;
    white-space: nowrap;
  }

  th > div {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  th,
  td {
    padding: ${SPACINGS.xs} ${SPACINGS.md};
  }
`

export const TablePadding: FC<{ height?: number }> = ({ height }) => {
  if (!height) {
    return null
  }

  return (
    <tr>
      <td
        css={css`
          height: ${height}px;
        `}
      />
    </tr>
  )
}
