import { isEqual } from '@/shared/utils'
import { DependencyList, EffectCallback, useEffect, useRef } from 'react'

const useDeepCompareEffect = (callback: EffectCallback, dependencies: DependencyList) => {
  const currentDependenciesRef = useRef<DependencyList>()
  if (!isEqual(currentDependenciesRef.current, dependencies)) {
    currentDependenciesRef.current = dependencies
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(callback, [currentDependenciesRef.current])
}

export default useDeepCompareEffect
