import { FONT_SIZES, styled } from '@skf-internal/ui-components-react'

export const StyledAnalysisReasonFilterList = styled.ul`
  list-style-type: disc;
  list-style-position: inside;
  margin: 0;
  padding: 0;

  & > li {
    font-size: ${FONT_SIZES.md};
  }
`
