import { format, Locale } from 'date-fns'
import { enUS } from 'date-fns/locale'
import { useMemo } from 'react'

const getLocale = (): Locale =>
  /* TODO: When language selection is introduced a matching locale should be selected here  */
  enUS

export const useMonthFormatter = () =>
  useMemo(
    () => ({
      format: (month: number) =>
        format(new Date(2000, month - 1, 1), 'MMM', { locale: getLocale() })
          .toUpperCase()
          .replace(/\.$/, ''),
    }),
    []
  )
