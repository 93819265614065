import { getUsername } from '@/api/baseClientUtils'
import {
  ClosedFault,
  ClosedRecommendedAction,
  Fault,
  FaultErrorState,
  FaultState,
  FieldErrorState,
  RecommendedAction,
  RecommendedActionErrorState,
  RecommendedActionState,
} from '@/models/reportStatus/faultsFormStateTypes'
import { Optional } from '@/models/reportStatus/types'
import { UPDATE_FAULT, UPDATE_RECOMMENDED_ACTION } from '@/modules/report-status/reducer/actions.types'
import {
  commonFaultErrorStruct,
  commonRecommendedActionErrorStruct,
  ErrorTexts,
} from '@/modules/report-status/utils/constants'
import { DateFormats, formatDate, isBefore, moveDatePartToStart, parseDate } from '@/shared/dateUtils'
import { isAfter } from 'date-fns'
import { Dispatch } from 'react'

const getLastCollectedDate = (date: string | undefined) => {
  if (date === 'no date available' || date === undefined) {
    return formatDate(new Date(), DateFormats.ISO8601Format)
  }
  return formatDate(date, DateFormats.ISO8601Format)
}

const getEntityId = (fault: Fault | RecommendedAction): string => {
  if ('id' in fault) {
    return fault.id
  }
  return ''
}

const transformRecommendedActionToClosedRecommendedAction = async (
  recommendedAction: RecommendedAction
): Promise<RecommendedAction | Optional<ClosedRecommendedAction, 'outcome'>> => {
  switch (recommendedAction.state) {
    case 'new':
      throw new Error('cannot transform new recommended action to closed recommended action')
    case 'open':
      return {
        id: recommendedAction.id,
        recommendedAction: recommendedAction.recommendedAction,
        additionalNotes: recommendedAction.additionalNotes,
        workOrder: recommendedAction.workOrder,
        dueDate: recommendedAction.dueDate,
        createdDate: recommendedAction.createdDate,
        state: RecommendedActionState.PENDING_CLOSE,
        closeDate: formatDate(new Date(), DateFormats.ISO8601Format),
        username: await getUsername(),
      }
    default:
      return recommendedAction
  }
}

/**
 * Recursively updates an object or array by applying changes from the updated input.
 * It decides whether to handle the input as an array or an object and delegates the operation accordingly.
 *
 * @template T - The type of the object or array being updated.
 * @param {T} original - The original object or array to be updated.
 * @param {T} updated - The updated object or array containing new values.
 * @returns {{ updated: T; hasChanges: boolean }} An object containing the updated object or array, and a flag indicating if changes were made.
 */
const updateEntity = <T>(original: T, updated: T): { updated: T; hasChanges: boolean } => {
  if (Array.isArray(original) && Array.isArray(updated)) {
    const { updated: updatedArray, hasChanges } = updateArray(original, updated)
    return { updated: updatedArray as T, hasChanges }
  }

  if (typeof original === 'object' && typeof updated === 'object' && original !== null && updated !== null) {
    return updateObject(original, updated)
  }

  // Fallback for primitive types
  return { updated, hasChanges: original !== updated }
}

/**
 * Recursively updates an array by comparing elements from the updated input with the original input.
 * If elements are objects, the function recurses into them to detect changes.
 *
 * @template T - The type of the elements in the array.
 * @param {T[]} original - The original array to be updated.
 * @param {T[]} updated - The updated array containing new elements.
 * @returns {{ updated: T[]; hasChanges: boolean }} An object containing the updated array and a flag indicating if changes were made.
 */

const updateArray = <T extends { id: string; uniqueId: string }>(
  original: T[],
  updated: T[]
): { updated: T[]; hasChanges: boolean } => {
  let hasChanges = false

  // Iterate over the longest array (either original or updated)
  const resultArray = original.map((item) => {
    let updatedItem = undefined
    if ('id' in item) {
      updatedItem = updated.find((itemToUpdate) => itemToUpdate.id === item.id)
    }
    if ('uniqueId' in item) {
      updatedItem = updated.find((itemToUpdate) => itemToUpdate.uniqueId === item.uniqueId)
    }

    // If updated array is shorter, keep original elements
    if (updatedItem === undefined) {
      return item
    }

    // If the item is an object or array, recurse into it
    if (typeof updatedItem === 'object' && updatedItem !== null) {
      const { updated: nestedUpdated, hasChanges: nestedHasChanges } = updateEntity(item, updatedItem)
      if (nestedHasChanges) hasChanges = true
      return nestedUpdated
    }

    // Direct comparison for primitive values
    if (updatedItem !== item) {
      hasChanges = true
      return updatedItem
    }

    return item // Keep original if no changes
  })

  // Handle cases where the updated array is longer than the original
  if (updated.length > original.length) {
    hasChanges = true
    resultArray.push(...updated.slice(original.length)) // Append the extra items
  }

  return { updated: resultArray, hasChanges }
}

/**
 * Recursively updates an object by comparing its properties with those of the updated input.
 * If properties are objects or arrays, the function recurses into them to detect changes.
 *
 * @template T - The type of the object being updated.
 * @param {T} original - The original object to be updated.
 * @param {T} updated - The updated object containing new properties.
 * @returns {{ updated: T; hasChanges: boolean }} An object containing the updated object and a flag indicating if changes were made.
 */
const updateObject = <T>(original: T, updated: T): { updated: T; hasChanges: boolean } => {
  let hasChanges = false
  if (original instanceof File) {
    return { updated: original, hasChanges: false }
  }
  const result = { ...original }

  // Iterate over keys of the updated object
  for (const key in updated) {
    if (Object.prototype.hasOwnProperty.call(updated, key)) {
      const originalValue = (original as T)[key]
      const updatedValue = (updated as T)[key]

      // If both are arrays, recurse into the array update function
      if (Array.isArray(originalValue) && Array.isArray(updatedValue)) {
        const { updated: nestedUpdated, hasChanges: nestedHasChanges } = updateArray(originalValue, updatedValue)
        result[key] = nestedUpdated as typeof originalValue
        if (nestedHasChanges) hasChanges = true
      }
      // If both are objects, recurse into the object update function
      else if (
        typeof originalValue === 'object' &&
        typeof updatedValue === 'object' &&
        originalValue !== null &&
        updatedValue !== null
      ) {
        const { updated: nestedUpdated, hasChanges: nestedHasChanges } = updateEntity(originalValue, updatedValue)
        result[key] = nestedUpdated
        if (nestedHasChanges) hasChanges = true
      }
      // Otherwise, compare primitive values directly
      else if (originalValue !== updatedValue) {
        result[key] = updatedValue
        hasChanges = true
      }
    }
  }

  return { updated: result as T, hasChanges }
}

/**
 * Handles changes to a component within a faults form.

 * Dispatches either an `UPDATE_RECOMMENDED_ACTION` or `UPDATE_FAULT` action based on whether a parent field ID is provided.

 * @template T The type of the new value.
 * @param {T} newValue The new value for the component.
 * @param {Dispatch<unknown>} faultsFormDispatch The dispatch function for the faults form.
 * @param {string} fieldId The ID of the field being updated.
 * @param {string} fieldName The name of the field being updated.
 * @param {string | undefined} parentFieldId The ID of the parent field, if applicable.
 */
const onComponentChange = <T>(
  newValue: T,
  faultsFormDispatch: Dispatch<unknown>,
  fieldId: string,
  fieldName: string,
  parentFieldId?: string | undefined
) => {
  if (parentFieldId) {
    faultsFormDispatch({
      type: UPDATE_RECOMMENDED_ACTION,
      payload: {
        id: fieldId,
        [fieldName]: newValue,
        faultId: parentFieldId,
      },
    })
  } else {
    faultsFormDispatch({
      type: UPDATE_FAULT,
      payload: {
        id: fieldId,
        [fieldName]: newValue,
      },
    })
  }
}

/**
 * Builds an error structure for a given set of faults.

 * @param {Fault[]} faults The faults to build the error structure for.
 * @returns {FaultErrorState[]} An array of error structures for each fault.
 */
const buildFormErrorStruct = (faults: Fault[]) => {
  return faults.reduce((faultAcc, current) => {
    const errorFault = structuredClone(commonFaultErrorStruct)
    errorFault.id = getEntityId(current)
    errorFault.recommendedActions = current.recommendedActions
      .filter((ra) => ra.state !== RecommendedActionState.CLOSED)
      .reduce((raAcc, raCurrent) => {
        const errorRecommendedAction = structuredClone(commonRecommendedActionErrorStruct)
        errorRecommendedAction.id = getEntityId(raCurrent)
        raAcc.push(errorRecommendedAction)
        return raAcc
      }, [] as RecommendedActionErrorState[])
    faultAcc.push(errorFault)
    return faultAcc
  }, [] as FaultErrorState[])
}

/**
 * Sets the error message to "REQUIRED" and sets the `isValid` flag to `false` for a given `FieldErrorState` object.

 * This function is typically used to indicate that a field is required but has not been filled in.

 * @param {FieldErrorState} errorField The field error state object to be updated.
 */
const setRequiredError = (errorField: FieldErrorState) => {
  errorField.error = ErrorTexts.REQUIRED
  errorField.isValid = false
}

/**
 * Clears any existing error message and sets the `isValid` flag to `true` for a given `FieldErrorState` object.

 * @param {FieldErrorState} errorField The field error state object to be cleared.
 */
const clearError = (errorField: FieldErrorState) => {
  errorField.error = ''
  errorField.isValid = true
}
/**
 * Sets a custom error message and sets the `isValid` flag to `false` for a given `FieldErrorState` object.

 * @param {FieldErrorState} errorField The field error state object to set a custom error for.
 * @param {string} customError The custom error message to be set.
 */
const setCustomError = (errorField: FieldErrorState, customError: string) => {
  errorField.error = customError
  errorField.isValid = false
}
/**
 * Updates the error state for a given field based on the provided value and optional custom error.

 * Sets the error state to clear (valid) if a value (string or number) is provided.
 * Sets the error state to a custom error message (invalid) if a custom error message is provided.
 * Sets the error state to required (invalid) if no value or custom error is provided.

 * @param {FieldErrorState} errorField The field error state object to be updated.
 * @param {string | number | undefined | null} value The value to check for validity.
 * @param {string} customError (optional) A custom error message to set if provided.
 */
const updateErrorState = (
  errorField: FieldErrorState,
  value: string | number | undefined | null,
  customError?: string
) =>
  value?.toString().trim()
    ? clearError(errorField)
    : customError
      ? setCustomError(errorField, customError)
      : setRequiredError(errorField)

/**
 * Validates a `Fault` object and updates the corresponding error state if necessary.
 *
 * @param {Fault} updatedFault The fault object to validate.
 * @param {FaultErrorState[] | undefined} currentErrorState The current error state.
 * @returns {FaultErrorState[] | undefined} The updated error state (if any).
 */
const validateFault = (
  updatedFault: Fault,
  currentErrorState: FaultErrorState[] | undefined,
  withRecommendedActionsValidation: boolean = false
): FaultErrorState[] | undefined => {
  if (!currentErrorState) return undefined

  let errorState = currentErrorState

  if (withRecommendedActionsValidation) {
    const newErrorState = buildFormErrorStruct([updatedFault])
    const updatedErrorStateData = updateEntity(currentErrorState, newErrorState)
    errorState = updatedErrorStateData.updated
  }

  const faultErrorStateToUpdate = errorState.find((errorState) => errorState.id === getEntityId(updatedFault))

  //Add new Fault
  if (!faultErrorStateToUpdate) {
    const newErrorState = buildFormErrorStruct([updatedFault]).map((errorData) => {
      setRequiredError(errorData.fault)
      setRequiredError(errorData.status)
      setRequiredError(errorData.observation)

      errorData.recommendedActions = errorData.recommendedActions.map((ra) => {
        setRequiredError(ra.recommendedAction)
        return ra
      })
      return errorData
    })

    return [...errorState, ...newErrorState]
  }
  //Update existing fault (edit, close, or new that its values were changed)
  else {
    const { fault, status, collectionDate, observation, correctDiagnostic, explanation, state, recommendedActions } =
      updatedFault as ClosedFault

    updateErrorState(faultErrorStateToUpdate.fault, fault)
    updateErrorState(faultErrorStateToUpdate.status, status)
    //updateErrorState(faultErrorStateToUpdate.collectionDate,collectionDate)
    updateErrorState(faultErrorStateToUpdate.observation, observation)

    if (state.toString() === FaultState.PENDING_CLOSE && !correctDiagnostic) {
      updateErrorState(faultErrorStateToUpdate.explanation, explanation)
    }

    validateFormDates(faultErrorStateToUpdate, collectionDate, recommendedActions)

    if (state.toString() === FaultState.PENDING_CLOSE) {
      faultErrorStateToUpdate.recommendedActions.map((ra) => {
        const recommendedAction = recommendedActions.find((item) => getEntityId(item) === ra.id)

        updateErrorState(ra.outcome, recommendedAction?.outcome)
      })
    }
    if (withRecommendedActionsValidation) {
      recommendedActions.forEach((ra) => validateRecommendedAction(ra, updatedFault, errorState))
    }
  }

  return errorState
}

/**
 * Validates a `RecommendedAction` object and updates the corresponding error state within a `Fault` if necessary.
 *
 * @param {RecommendedAction} updatedRecommendedAction The recommended action object to validate.
 * @param {Fault} recommendedActionFault The fault object associated with the recommended action.
 * @param {FaultErrorState[] | undefined} currentErrorState The current error state for faults.
 * @returns {FaultErrorState[] | undefined} The updated error state (if any).
 */
const validateRecommendedAction = (
  updatedRecommendedAction: RecommendedAction,
  recommendedActionFault: Fault,
  currentErrorState: FaultErrorState[] | undefined
): FaultErrorState[] | undefined => {
  if (!currentErrorState) return undefined
  const faultErrorStateToUpdate = currentErrorState.find(
    (errorState) => errorState.id === getEntityId(recommendedActionFault)
  )
  const recommendedActionStateToUpdate = faultErrorStateToUpdate?.recommendedActions.find(
    (ra) => ra.id === getEntityId(updatedRecommendedAction)
  )

  if (!recommendedActionStateToUpdate || !faultErrorStateToUpdate) return undefined

  const { recommendedAction, state, outcome, additionalNotes } = updatedRecommendedAction as ClosedRecommendedAction

  updateErrorState(recommendedActionStateToUpdate.recommendedAction, recommendedAction)

  if (state.toString() === RecommendedActionState.PENDING_CLOSE) {
    updateErrorState(recommendedActionStateToUpdate.outcome, outcome)
    clearError(recommendedActionStateToUpdate.additionalNotes)
  }

  validateFormDates(faultErrorStateToUpdate, recommendedActionFault.collectionDate, [updatedRecommendedAction])

  if (state.toString() === RecommendedActionState.PENDING_CLOSE && outcome === 'rejected' && !additionalNotes) {
    updateErrorState(
      recommendedActionStateToUpdate.additionalNotes,
      '',
      'Additional comments are required for rejected recommended actions'
    )
  }

  return currentErrorState
}

/**
 * Validates form dates by updating error states for collection and recommended action due dates.
 *
 * This function checks if the collection date and recommended action due dates are valid and updates
 * the error state accordingly. It performs the following:
 * 1. Updates the error state of the collection date.
 * 2. Checks each recommended action's due date against the collection date and updates error states if needed.
 * 3. Validates that no collection date is later than any recommended action due date.
 *
 * @param {FaultErrorState} faultErrorState - The current error state object containing collection and recommended action dates.
 * @param {string} collectionDate - The date of the collection to validate against recommended action due dates.
 * @param {RecommendedAction[]} recommendedActions - An array of recommended actions containing due dates to validate.
 */
const validateFormDates = (
  faultErrorState: FaultErrorState,
  collectionDate: string,
  recommendedActions: RecommendedAction[]
) => {
  // Cache parsed collection date once to avoid repetitive parsing.
  const parsedCollectionDate = parseDate(moveDatePartToStart(collectionDate, 'd'))

  // Update the error state of the collection date with the current value.
  updateErrorState(faultErrorState.collectionDate, collectionDate)

  // Flag to indicate if any recommended action has a due date error relative to the collection date.
  let hasDueDateError = false

  // Iterate over each recommended action to update states and validate date relationships.
  recommendedActions.forEach((ra) => {
    // Find the corresponding error state object for the current recommended action.
    const raStateToUpdate = faultErrorState.recommendedActions.find((fra) => fra.id === getEntityId(ra))

    // Parse the due date of the recommended action once.
    const parsedDueDate = parseDate(moveDatePartToStart(ra.dueDate, 'd'))

    // Update the error state of the recommended action due date if the state exists.
    if (raStateToUpdate) {
      updateErrorState(raStateToUpdate.dueDate, ra.dueDate)

      // Check if the recommended action's due date is before the collection date.
      if (isBefore(parsedDueDate, parsedCollectionDate)) {
        updateErrorState(raStateToUpdate.dueDate, '', 'Recommended action due date must be after fault collection date')
      }
    }

    // Check if the collection date is after any recommended action due date.
    if (isAfter(parsedCollectionDate, parsedDueDate)) {
      hasDueDateError = true
    }
  })

  // If any due date is found to be earlier than the collection date, update the error state.
  if (hasDueDateError) {
    updateErrorState(faultErrorState.collectionDate, '', 'Collection date should not be greater than due dates')
  }
}

/**
 * Revalidate form errors for a list of faults and updates the error state accordingly.
 *
 * This function iterates through each fault, validates it using the `validateFault` function, and
 * collects the resulting error states, filtering out any undefined results.
 *
 * DO NOT RUN THIS FUNCTION IF IT IS NOT REALLY NECESSARY. NOW IN USE ONLY ON FORM WORK RESET
 *
 * @param {Fault[]} faults - An array of faults to validate.
 * @param {FaultErrorState[]} currentErrorState - The current error states to be updated.
 * @returns {FaultErrorState[]} - An array of updated fault error states after validation.
 */
const revalidateFaultFormErrors = (faults: Fault[], currentErrorState: FaultErrorState[]): FaultErrorState[] => {
  // Iterate over faults and validate each, filtering out undefined results.
  return faults
    .flatMap((fault) => validateFault(fault, currentErrorState, true) || []) // Use flatMap to merge results and handle undefined.
    .filter((errorState) => errorState !== undefined) // Explicitly filter out any undefined values (optional for added type safety).
}
/**
 * Recursively checks if all `isValid` fields are `true` within an object or array.
 *
 * This function traverses deeply nested structures, including arrays and objects,
 * to verify that all `isValid` properties are `true`. It stops checking as soon as
 * it finds an `isValid` field that is `false`, returning `false` immediately.
 *
 * @param obj - The input object or array to check for validation states.
 * @returns `true` if all `isValid` fields are `true`; otherwise, `false`.
 *
 */
const isFaultFormValid = (obj: unknown): boolean => {
  if (Array.isArray(obj)) {
    // If the input is an array, recursively check each item in the array
    return obj.every((item) => isFaultFormValid(item))
  } else if (obj && typeof obj === 'object') {
    // Check if the object has an `isValid` property and it's false
    if ('isValid' in obj && obj.isValid === false) {
      return false
    }

    // Recursively check all values of the object to find invalid fields
    return Object.values(obj).every((value) => isFaultFormValid(value))
  }

  // Return true if it's not an object, array, or a valid `FieldErrorState`
  return true
}

/**
 * Recursively removes specified keys from an object or array.
 *
 * @template T - The type of the object or array being processed.
 * @param {T} obj - The original object or array to be processed.
 * @param {string[]} excludeKeys - An array of keys that should be excluded from the object.
 * @returns {T} A new object or array with the specified keys removed.
 */
const removeKeysRecursively = <T>(obj: T, excludeKeys: string[]): T => {
  if (Array.isArray(obj)) {
    // Recursively process each item in the array
    return obj.map((item) => removeKeysRecursively(item, excludeKeys)) as T
  } else if (typeof obj === 'object' && obj !== null) {
    // If the current object is an object, create a new object without the excluded keys
    const newObj: Record<string, unknown> = {}

    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key) && !excludeKeys.includes(key)) {
        // Recursively process each key except those in the exclude list
        newObj[key] = removeKeysRecursively(obj[key], excludeKeys)
      }
    }

    return newObj as T
  }

  // Return the value directly if it's neither an object nor an array
  return obj
}

/**
 * Recursively checks if any object within the structure has a specified state.
 *
 * @param {any} obj - The object or array to be checked.
 * @param {string} targetState - The state to search for within the structure.
 * @returns {boolean} True if any object within the structure has the specified state; otherwise, false.
 */
const isAnyInSpecifiedStates = (obj: unknown, targetStates: string[]): boolean => {
  if (Array.isArray(obj)) {
    // If the object is an array, check each element recursively
    return obj.some((item) => isAnyInSpecifiedStates(item, targetStates))
  } else if (typeof obj === 'object' && obj !== null && 'state' in obj) {
    // If the object is a regular object, check if it has the target state
    if (targetStates.includes(obj.state as string)) {
      return true
    }

    // Recursively check all properties of the object
    return Object.values(obj).some((value) => isAnyInSpecifiedStates(value, targetStates))
  }

  // Return false if it's neither an object nor an array
  return false
}

export {
  getLastCollectedDate,
  getEntityId,
  updateEntity,
  transformRecommendedActionToClosedRecommendedAction,
  onComponentChange,
  buildFormErrorStruct,
  validateFault,
  validateRecommendedAction,
  isFaultFormValid,
  removeKeysRecursively,
  isAnyInSpecifiedStates,
  revalidateFaultFormErrors,
}
