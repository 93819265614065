import { saveHalMockCustomerNote } from '@/api/paths/reportStatus'
import { useApi } from '@/shared/hooks/useApi'
import { openToast } from '@/store/genericToast/actions'
import { useAppDispatch } from '@/store/store'
import { useAsyncThrow } from '@/shared/hooks/useAsyncThrow'
import { AxiosError } from 'axios'
import { DarwinError } from '@/common/error-boundary/DarwinError'
import { HalMockCustomerNote } from '@/models/reportStatus/types'

const useCreateHalMockCustomerNote = (publishedCustomerNote: HalMockCustomerNote, successCallback: () => void) => {
  const createHalMockCustomerNoteDispatch = useAppDispatch()
  const throwError = useAsyncThrow()
  return useApi(async () => {
    return saveHalMockCustomerNote(
      {
        onSuccess: async () => {
          createHalMockCustomerNoteDispatch(
            openToast({
              feSeverity: 'success',
              children: 'Customer note was successfully created',
            })
          )
          successCallback()
        },
        onError: async (error: AxiosError) => {
          throwError(new DarwinError(error))
        },
      },
      publishedCustomerNote
    )
  })
}

export default useCreateHalMockCustomerNote
