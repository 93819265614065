import { GetAnalysisBacklogAssetsResponse } from '@/api/paths/analysis/types'
import { GetReportFaultTypesResponse } from '@/api/paths/reportStatus/types'
import {
  AssetRow,
  AssetRowHandled,
  AssetRowUnhandled,
  Assets,
  InitialAsset,
  ReasonIndicatorData,
  ReportedAssetRowUnhandled,
} from '@/models/analysisBacklog/types'
import { RecommendationTypes, ResponseRecommendationTypes } from '@/models/reportStatus/types'
import { HeaderInfoPanelIconData } from '@/shared/components/ModuleHeader/infoPanel/HeaderInfoPanelIcon'
import { DateFormats, formatDate } from '@/shared/dateUtils'
import { IconProps } from '@skf-internal/ui-components-react'
import { ColumnFiltersState, Table } from '@tanstack/react-table'
import { differenceInDays, format, fromUnixTime, parseISO } from 'date-fns'
import { startCase, sumBy, upperFirst } from 'lodash-es'
import { formatISO } from 'date-fns'
import { getCountType } from '@/modules/analysis-backlog/analysisBacklogUtils'

// Moves an unhandled asset row, that is normal or never reported, to handled.
// or
// Updates a handled asset, that is normal or never reported
// Mutates assets.
function markAsNormal(assetId: string, assets: Assets): void {
  const unhandledAsset = assets.unhandled.find((assetRow) => assetRow.asset.id === assetId)

  // Attempt to mark handled asset as normal again
  if (unhandledAsset === undefined) {
    const handledAsset = assets.handled.find((assetRow) => assetRow.asset.id === assetId)

    if (handledAsset === undefined) {
      throw new Error('cannot find asset with id: ' + assetId)
    }

    handledAsset.status = 'normal'
    handledAsset.lastReportedDate = formatISO(new Date())
  }
  // Mark unhandled asset as handled, and mark it as normal.
  else {
    moveFromUnhandledToHandled(
      {
        active: unhandledAsset.active,
        analysisReasons: unhandledAsset.analysisReasons,
        asset: unhandledAsset.asset,
        belongsTo: unhandledAsset.belongsTo,
        criticality: unhandledAsset.criticality,
        handled: unhandledAsset.handled,
        lastCollectedDate: unhandledAsset.lastCollectedDate,
        lastReportedDate: formatISO(new Date()),
        priority: unhandledAsset.priority,
        status: 'normal',
        isInaccuratePriority: unhandledAsset.isInaccuratePriority,
      },
      assets
    )
  }
}

function moveFromUnhandledToHandled(unhandledAsset: ReportedAssetRowUnhandled, assets: Assets) {
  const unhandledIndex = unhandledAsset.priority - 1

  // Removes from unhandled
  assets.unhandled.splice(unhandledIndex, 1)

  for (let i = unhandledIndex; i < assets.unhandled.length; i++) {
    assets.unhandled[i].priority -= 1
  }

  assets.handled.push(transformUnhandledAssetToHandled(unhandledAsset))
}

function transformUnhandledAssetToHandled(unhandledAsset: ReportedAssetRowUnhandled): AssetRowHandled {
  return {
    active: unhandledAsset.active,
    asset: unhandledAsset.asset,
    belongsTo: unhandledAsset.belongsTo,
    criticality: unhandledAsset.criticality,
    lastCollectedDate: unhandledAsset.lastCollectedDate,
    lastReportedDate: unhandledAsset.lastReportedDate,
    status: unhandledAsset.status,
    handled: true,
  } as AssetRowHandled
}

const count = <T>(list: T[], f: (arg: T) => boolean) => sumBy(list, (i) => (f(i) ? 1 : 0))

const processDate = (date: number) =>
  date !== 0 ? formatDate(date, DateFormats.ISO8601Format, true) : 'no date available'

const mapAssetsData = (response: GetAnalysisBacklogAssetsResponse) => {
  const handled = response?.handled.map((a: InitialAsset) => ({
    asset: {
      id: a.id,
      name: a.name,
    },
    belongsTo: a.belongsTo,
    active: a.active,
    lastCollectedDate: processDate(a.lastCollectedDateTimestamp),
    lastReportedDate: processDate(a.lastReportedDateTimestamp),
    status: a.status,
    handled: true,
    criticality: a.criticality,
  })) as AssetRowHandled[]

  const unhandled = response?.unhandled.map((a: InitialAsset) => ({
    asset: {
      id: a.id,
      name: a.name,
    },
    belongsTo: a.belongsTo,
    active: a.active,
    lastCollectedDate: processDate(a.lastCollectedDateTimestamp),
    lastReportedDate: processDate(a.lastReportedDateTimestamp),
    status: a.status,
    handled: false,
    analysisReasons: a.analysisReasons,
    priority: a.priority,
    criticality: a.criticality,
    isInaccuratePriority: a.isInaccuratePriority,
  })) as unknown as AssetRowUnhandled[]

  return { handled, unhandled } as Assets
}

const getAssetRow = (assets: Assets, assetId: string | undefined) => {
  return (
    assets.unhandled.find((assetRow) => assetRow.asset.id === assetId) ||
    assets.handled.find((assetRow) => assetRow.asset.id === assetId)
  )
}

const calculateInfoPanelData = (assetTable: Table<AssetRow>, isEmptyTable: boolean = false) => {
  let result: HeaderInfoPanelIconData[] = []
  const assetRows = assetTable.getFilteredRowModel().flatRows
  const tableFilters = assetTable.getState().columnFilters

  if (assetRows.length > 0 || tableFilters.length > 0 || isEmptyTable) {
    result = [
      {
        //pending
        count: assetRows.filter((asset) => asset.original.handled === false).length,
        icon: 'asset' as IconProps['feIcon'],
        iconColor: 'blue' as IconProps['feColor'],
        hoverText: 'Number of unhandled assets',
      },
      {
        //alarm
        count: assetRows.reduce((acc, asset) => {
          if (!asset.original.handled && asset.original.analysisReasons.some((r) => getCountType(r) === 'alarm')) {
            acc += 1
          }
          return acc
        }, 0),
        icon: 'danger' as IconProps['feIcon'],
        iconColor: 'red' as IconProps['feColor'],
        hoverText: 'Number of assets that have at least one alarm',
      },
      {
        //alert
        count: assetRows.reduce((acc, asset) => {
          if (!asset.original.handled && asset.original.analysisReasons.some((r) => getCountType(r) === 'alert')) {
            acc += 1
          }
          return acc
        }, 0),
        icon: 'warning' as IconProps['feIcon'],
        iconColor: 'orange' as IconProps['feColor'],
        hoverText: 'Number of assets that have at least one alert',
      },
    ]
  }
  return result
}

type DateFilter = {
  dateFilterType: string
  endDate: Date | undefined
  startDate: Date | undefined
}

const filtersTypes = ['asset', 'analysisReasons', 'status', 'belongsTo', 'lastCollectedDate', 'lastReportedDate']

const formatFilters = (filters: ColumnFiltersState): Record<string, string> => {
  const result: Record<string, string> = {
    asset: 'none',
    analysisReasons: 'none',
    status: 'none',
    belongsTo: 'none',
    lastCollectedDate: 'none',
    lastReportedDate: 'none',
  }

  filters.forEach((filter) => {
    if (filter.value && filtersTypes.includes(filter.id)) {
      if (Array.isArray(filter.value)) {
        result[filter.id] = filter.value.reduce((result, value) => (result += startCase(value) + '.'), '')
      } else if (filter.id === 'lastReportedDate' || filter.id === 'lastCollectedDate') {
        const dateFilterObject = filter.value as DateFilter
        const startDate = `Start Date: ${dateFilterObject.startDate?.toString() ?? 'none'}`
        const endDate = `End Date: ${dateFilterObject.endDate?.toString() ?? 'none'}`
        result[filter.id] = startDate.concat('.', endDate)
      } else {
        result[filter.id] = filter.value.toString()
      }
    }
  })

  return result
}

const formatAssets = (data: AssetRow[]): Assets => {
  const handledAssets: AssetRowHandled[] = data.filter((asset): asset is AssetRowHandled => asset.handled === true)
  const unhandledAssets = data.filter((asset): asset is AssetRowUnhandled => asset.handled === false)

  return {
    handled: handledAssets,
    unhandled: unhandledAssets,
  }
}

const getCompleteReasonString = (reason: ReasonIndicatorData): string => {
  let completeReasonString
  const countType = getCountType(reason)

  if (countType !== 'none') {
    completeReasonString = [reason.type, countType].join(' ')
  } else {
    completeReasonString = reason.type
  }

  return startCase(completeReasonString)
}

const formatCsvValues = (data: string): string => {
  if (data.includes(',') || data.includes('\n')) {
    return `"${data.replace(/"/g, '""').replace(/\n/g, ' ')}"`
  }
  return data
}

const exportAnalysisBacklogDataToCsv = (
  data: AssetRow[],
  customerName: string,
  filters: ColumnFiltersState
): string => {
  const formattedData = formatAssets(data)
  const formattedFilters = formatFilters(filters)
  return [
    ['Site Name:', customerName].join(','),
    ['Dashboard:', 'Analysis Backlog'].join(','),
    ['Export date:', formatDate(new Date(), DateFormats.AmericanDateTimeFormat)].join(','),
    [],
    [],
    [],
    [
      'Filters Applied:',
      formattedFilters['asset'],
      formattedFilters['belongsTo'],
      formattedFilters['lastCollectedDate'],
      formattedFilters['lastReportedDate'],
      '',
      formattedFilters['status'],
      formattedFilters['analysisReasons'],
      '',
    ].join(','),
    [
      'Analysis priority',
      'Asset name',
      'Belongs to',
      'Collected',
      'Last reported',
      'Days since last reported',
      'Asset status',
      'Analysis reasons',
      'Asset criticality',
    ].join(','),
    formattedData.unhandled
      .map((asset) => {
        let lastReportedDate = fromUnixTime(0).toISOString()
        if (asset.status !== 'never-reported') {
          const currentUnhandledAsset = asset as ReportedAssetRowUnhandled
          lastReportedDate = currentUnhandledAsset.lastReportedDate
        }
        return [
          asset.priority,
          formatCsvValues(asset.asset.name),
          formatCsvValues(asset.belongsTo.name),
          asset.lastCollectedDate !== 'no date available' ? format(parseISO(asset.lastCollectedDate), 'M/d/yyyy') : '-',

          asset.status !== 'never-reported' &&
          lastReportedDate !== 'no date available' &&
          lastReportedDate !== fromUnixTime(0).toISOString()
            ? format(parseISO(lastReportedDate), 'M/d/yyyy')
            : '-',

          asset.status !== 'never-reported' &&
          lastReportedDate !== 'no date available' &&
          lastReportedDate !== fromUnixTime(0).toISOString()
            ? differenceInDays(new Date(), parseISO(lastReportedDate))
            : '-',
          typeof asset.status === 'string' ? upperFirst(asset.status.replaceAll('-', ' ')) : 'status unknown',
          asset.analysisReasons.map((reason) => `${getCompleteReasonString(reason)} (${reason.count})`).join(' | '),
          asset.criticality,
        ].join(',')
      })
      .join('\n'),
    formattedData.handled
      .map((asset) =>
        [
          'handled',
          asset.asset.name,
          asset.belongsTo.name,
          asset.lastCollectedDate !== 'no date available' ? format(parseISO(asset.lastCollectedDate), 'M/d/yyyy') : '-',
          asset.lastReportedDate !== fromUnixTime(0).toISOString()
            ? format(parseISO(asset.lastReportedDate), 'M/d/yyyy')
            : '-',
          asset.lastReportedDate !== fromUnixTime(0).toISOString()
            ? differenceInDays(new Date(), parseISO(asset.lastReportedDate))
            : '-',
          upperFirst(asset.status.replaceAll('-', ' ')),
          '',
          asset.criticality,
        ].join(',')
      )
      .join('\n'),
  ].join('\n')
}

const mapFaultTypes = (faultTypes: GetReportFaultTypesResponse) => {
  return faultTypes?.types.map((type) => {
    return {
      code: type['code'],
      text: type['en'],
      deprecated: type.deprecated,
    }
  })
}

const mapRecommendationTypes = (recommendationTypes: ResponseRecommendationTypes): RecommendationTypes => {
  return recommendationTypes?.types.map((f) => {
    return {
      code: f['code'],
      text: f['en'],
    }
  })
}

export {
  calculateInfoPanelData,
  exportAnalysisBacklogDataToCsv,
  getAssetRow,
  mapAssetsData,
  mapFaultTypes,
  mapRecommendationTypes,
  count,
  markAsNormal,
  moveFromUnhandledToHandled,
}
