import { DataEntry } from '@/models/reportStatus/types'
import { StyledReconfirmRowContainer, StyledReconfirmRowInnerContainer } from '../../styled'
import HistoryReconfirmSection from './HistoryReconfirmSection'

interface FaultHistoryContainer {
  snapshot: DataEntry | DataEntry[]
}

export const ReconfirmHistoryContainer = ({ snapshot }: FaultHistoryContainer) => {
  return (
    <>
      {!Array.isArray(snapshot) && snapshot.reconfirms.length > 0 && (
        <StyledReconfirmRowContainer feFlexDirection="column">
          <StyledReconfirmRowInnerContainer feFlexDirection="row" feJustifyContent="flex-start">
            <HistoryReconfirmSection reconfirms={snapshot.reconfirms} />
          </StyledReconfirmRowInnerContainer>
        </StyledReconfirmRowContainer>
      )}
    </>
  )
}
