// =================================================================================================
// IMPORTS
// =================================================================================================

import { FC } from 'react'
import { Checkbox, Flexbox, Icon } from '@skf-internal/ui-components-react'
import { DevicesSensorsTableDeviceRowData } from '@/models/devicesAndSensors/types'
import { DeviceStatusIcon, DeviceStatusString } from '@/modules/devices-sensors/utils/constants'

// =================================================================================================
// COMPONENT
// =================================================================================================

export const DeviceStatusCheckbox: FC<{
  status: DevicesSensorsTableDeviceRowData['status']
  checked: boolean
  onChange: (status: DevicesSensorsTableDeviceRowData['status'], checked: boolean) => void
}> = ({ status, checked, onChange }) => {
  const { icon, color } = DeviceStatusIcon[status]

  return (
    <Flexbox feAlignItems="center" feJustifyContent="space-between" feGap="md">
      <Checkbox
        name={status}
        checked={checked}
        feLabel={DeviceStatusString[status]}
        onChange={(_, checked) => onChange(status, checked)}
      />
      <Icon feIcon={icon} feColor={color} feSize="sm" />
    </Flexbox>
  )
}
