import useTimeout from '@/shared/hooks/useTimeout'
import { useAppDispatch, useTypedSelector } from '@/store/store'
import { useToast } from '@skf-internal/ui-components-react'
import { useCallback, useEffect, useRef } from 'react'
import { closeToast as closeToastAction } from '@/store/genericToast/actions'
/**
 * The GenericToast component is a specialized toast notification system that leverages Redux for state management and custom hooks for handling timeouts and toast actions.
 * This component is designed to display a toast message when triggered by specific application state changes.
 *
 * The GenericToast component does not accept any props directly.
 * Instead, it relies on the global state managed by Redux to get its configuration and data.
 *
 * In order to invoke toast show action, define app dispatch in related component and call `openDispatch` action with next params:
 *
 * `children` (**required**): The content to be displayed inside the toast.
 *
 * `feSeverity` (**required**): The severity level of the toast (e.g., success, error, info).
 *
 * `timeout` (optional): The duration (in seconds) before the toast automatically closes. Defaults to 7 seconds if not provided.
 *
 * `onClose` (optional): A callback function to be executed when the toast is closed.
 *
 * **No need to include the `isOpen` parameter in any action; the reducer will handle it automatically.**
 * @return {*}
 */
const GenericToast = () => {
  const { genericToastData } = useTypedSelector((state) => ({ ...state }))

  const genericToastDispatch = useAppDispatch()

  const { isOpen, timeout = 7, children, feSeverity, onClose } = genericToastData

  const { addToast, removeToast } = useToast()
  const openedToastId = useRef<string>()

  const closeToast = useCallback(() => {
    openedToastId.current && removeToast(openedToastId.current)
    openedToastId.current = undefined
  }, [removeToast])

  const { startTimeout, clearTimeout } = useTimeout(() => {
    if (isOpen) {
      onClose && onClose()
      closeToast()
      genericToastDispatch(closeToastAction())
    }
  }, timeout * 1000)

  useEffect(() => {
    if (isOpen) {
      closeToast()
      clearTimeout()
      openedToastId.current = addToast({ ...genericToastData, children, feSeverity })
      startTimeout()
    } else {
      closeToast()
    }
  }, [addToast, children, clearTimeout, closeToast, feSeverity, genericToastData, isOpen, startTimeout])

  return <></>
}

export default GenericToast
