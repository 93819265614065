import { EvidenceImageData } from '@/models/reportStatus/evidenceTypes'
import {
  StyledFaultHistoryEvidenceHeadingContainer,
  StyledFaultHistoryEvidenceContainer,
  StyledFaultHistoryEvidenceFilesContainer,
  StyledFaultHistoryEvidenceHeadings,
  StyledFaultHistoryEvidenceFilesWrapper,
} from '@/modules/report-status/styled'
import { Icon } from '@skf-internal/ui-components-react'
import { FC } from 'react'
import EvidenceHistoryContent from './EvidenceHistoryContent'
import { TextEllipsis } from '@/common/text-ellipsis'
import { FaultEvidenceContextProvider } from '@/contexts/moduleContexts/evidence'

interface EvidenceHistoryProps {
  evidences: EvidenceImageData['imageInfo'][]
  faultId: string
}

const EvidenceHistory: FC<EvidenceHistoryProps> = ({ evidences, faultId }) => {
  return (
    <FaultEvidenceContextProvider faultId={faultId}>
      <StyledFaultHistoryEvidenceContainer>
        <StyledFaultHistoryEvidenceHeadingContainer feAlignItems="top" feGap="xs">
          <Icon feIcon="arrowRight" feSize="sm" />
          <StyledFaultHistoryEvidenceHeadings>Evidence added</StyledFaultHistoryEvidenceHeadings>
        </StyledFaultHistoryEvidenceHeadingContainer>
        <StyledFaultHistoryEvidenceFilesContainer>
          <TextEllipsis label="Evidence added" value={`${evidences.length} files`} rowsUntilEllipsis={3} />
          <StyledFaultHistoryEvidenceFilesWrapper>
            {evidences.map((evidence) => (
              <EvidenceHistoryContent evidence={evidence} key={evidence.fileName} />
            ))}
          </StyledFaultHistoryEvidenceFilesWrapper>
        </StyledFaultHistoryEvidenceFilesContainer>
      </StyledFaultHistoryEvidenceContainer>
    </FaultEvidenceContextProvider>
  )
}
export default EvidenceHistory
