import { ReportStatusLoaderData } from '@/app/routes/types'
import useReconfirmReport from '@/modules/report-status/hooks/useReconfirmReport'
import { getOpenFaults } from '@/modules/report-status/utils'
import GenericConfirmationDialogButton from '@/shared/components/GenericConfirmationDialog/GenericConfirmationDialogButton'
import { closeGenericConfirmDialog, showGenericConfirmDialog } from '@/store/genericConfirmationDialog/actions'
import { useAppDispatch, useTypedSelector } from '@/store/store'
import { Button, Tooltip, useTooltip } from '@skf-internal/ui-components-react'
import { FC, useMemo } from 'react'
import { useLoaderData, useParams } from 'react-router-dom'

interface ReconfirmButtonInterface {
  assetName: string
  assetParent: string
}

const ReconfirmButton: FC<ReconfirmButtonInterface> = ({ assetName, assetParent }) => {
  const { assetFaultsAndHistory, customerNotes } = useLoaderData() as ReportStatusLoaderData

  const {
    asyncPoller: { isAsyncPollerActive },
  } = useTypedSelector((state) => ({ ...state }))

  const { assetId } = useParams()
  const hasCustomerNotes = useMemo(
    () =>
      getOpenFaults(assetFaultsAndHistory, customerNotes, assetId).some(
        (v) => v.metadata.customerNotes.filter((c) => c.acknowledgment === false).length > 0
      ),
    [assetFaultsAndHistory, customerNotes, assetId]
  )

  const analyticsBacklogControlPanelDispatch = useAppDispatch()

  const { execute: reconfirmReport } = useReconfirmReport(assetId)

  const [hoverRef, isHovered] = useTooltip()

  if (assetId === undefined) {
    return <></>
  }

  const closeConfirmDialog = () => {
    analyticsBacklogControlPanelDispatch(closeGenericConfirmDialog())
  }

  const handleStatusReconfirmClick = () => {
    analyticsBacklogControlPanelDispatch(
      showGenericConfirmDialog({
        isOpen: true,
        title: 'Reconfirm the current status',
        message: `Reconfirm the current status for asset ${assetName} from ${assetParent}?"`,
        buttons: [
          <GenericConfirmationDialogButton type="secondary" label="Cancel" onClick={() => closeConfirmDialog()} />,
          <GenericConfirmationDialogButton
            type="primary"
            label="Confirm"
            onClick={async () => {
              closeConfirmDialog()
              await reconfirmReport()
            }}
          />,
        ],
      })
    )
  }

  return (
    <>
      <Tooltip isHovered={isHovered && hasCustomerNotes} triggerElement={hoverRef.current}>
        There is a pending customer note, please update the status
      </Tooltip>
      <div ref={hoverRef}>
        <Button
          feType="primary"
          type="button"
          feLoading={isAsyncPollerActive}
          disabled={hasCustomerNotes}
          onClick={handleStatusReconfirmClick}
        >
          Reconfirm Status
        </Button>
      </div>
    </>
  )
}

export default ReconfirmButton
