import { useTypedSelector } from '@/store/store'
import { dataTestId } from '@/tests/testid'
import { Button } from '@skf-internal/ui-components-react'

/**
 * HeaderClearFiltersButton is the button that located in header action panel
 * and it responsible for trigger related module clear filter functionality
 *
 * HeaderClearFiltersButton can be configured by  title and disabled state
 * @returns {JSX.Element} The rendered component.
 */

const HeaderClearFiltersButton = () => {
  const {
    moduleHeaderData: {
      headerActionPanelConfig: { isClearFiltersButtonDisabled, clearFiltersButtonFn, clearFilterButtonTitle },
    },
  } = useTypedSelector((store) => ({ ...store }))

  return (
    <Button
      data-testid={dataTestId.headerClearFiltersButton}
      disabled={isClearFiltersButtonDisabled}
      feType="secondary"
      feSize="sm"
      onClick={clearFiltersButtonFn}
    >
      {clearFilterButtonTitle || 'Clear filters'}
    </Button>
  )
}

export default HeaderClearFiltersButton
