import { DarwinDetachedModuleError } from '@/common/error-boundary/DarwinError'
import { Customer } from '@/models/customer/types'
import { ROUTES } from '@/shared/constants'
import useDeepCompareEffect from '@/shared/hooks/useDeepCompareEffect'
import { getRouteFromPathname } from '@/shared/utils'
import { setCustomersList } from '@/store/customersList/actions'
import { setSelectedCustomer } from '@/store/selectedCustomer/actions'
import { useAppDispatch, useTypedSelector } from '@/store/store'
import { useIsAuthenticated } from '@azure/msal-react'
import { PropsWithChildren } from 'react'
import { useLoaderData, useLocation, useParams } from 'react-router-dom'

const AppDataInitializer = ({ children }: PropsWithChildren) => {
  const loadedCustomersList = useLoaderData() as Customer[]
  const appDispatch = useAppDispatch()
  let selectedCustomer = undefined
  const isAuthenticated = useIsAuthenticated()
  const { sid } = useParams()
  const { pathname } = useLocation()
  const { customersList } = useTypedSelector((state) => ({ ...state }))

  useDeepCompareEffect(() => {
    const customer = customersList.find((customer) => customer.id === sid)
    customer && appDispatch(setSelectedCustomer(customer))
  }, [sid, customersList, appDispatch])

  const initCustomerReducers = (listOfCustomers: Customer[]) => {
    const customerListWithOverview = [
      {
        id: 'overview',
        name: 'All sites overview',
        customerNotesEnabled: false,
      },
      ...listOfCustomers,
    ]
    appDispatch(setCustomersList(customerListWithOverview))

    const customer = listOfCustomers.find((customer) => customer.id === sid)
    const routeName = getRouteFromPathname(pathname)

    if (customer) {
      selectedCustomer = customer
      appDispatch(setSelectedCustomer(selectedCustomer))
    } else if (![ROUTES.OVERVIEW, ROUTES.ERROR, ROUTES.HOME, ROUTES.REPORT_STATUS].includes(routeName as ROUTES)) {
      throw new DarwinDetachedModuleError({
        name: '',
        status: 404,
        isAxiosError: false,
        toJSON: () => ({}),
        message: 'Company Id not found',
      })
    }
  }

  useDeepCompareEffect(() => {
    if (isAuthenticated) {
      initCustomerReducers(loadedCustomersList)
    }
  }, [loadedCustomersList, isAuthenticated])
  return <>{children}</>
}

export default AppDataInitializer
