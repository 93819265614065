import { useContext } from 'react'

interface IContextConfig {
  contextName: string
  providerName: string
}

const useContextWrapper = <T>(ReactContext: React.Context<T>, config: IContextConfig) => {
  const context = useContext(ReactContext)
  const { contextName, providerName } = config

  if (!context) {
    //TODO Navigate to error page, send error log
    throw new Error(`${contextName} must be used within a ${providerName}`)
  }

  return context
}

export default useContextWrapper
