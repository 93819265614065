import { FC, useState } from 'react'
import { FONT_SIZES, Flexbox, css } from '@skf-internal/ui-components-react'
import { AssetStatus, RecommendedAction } from '@/models/unresolvedRecommendations/types'
import StatusCheckbox from '@/modules/unresolved-recommendation/components/filters/status-filter/StatusCheckbox'
import { withColumnFilterPopover } from '@/common/table/filters/filter-popover-wrapper'

interface StatusFilterPopoverProps {
  initialValue?: AssetStatus[]
  onFilterValueChange: (value?: AssetStatus[]) => void
}

const StatusFilterPopover: FC<StatusFilterPopoverProps> = ({
  initialValue,
  onFilterValueChange,
}: StatusFilterPopoverProps) => {
  const [filteredStatus, setFilteredStatus] = useState<AssetStatus[]>(initialValue || [])

  const findStatusValue = (value: AssetStatus): boolean => filteredStatus.some((v) => v === value)

  const handleOnChange = (status: AssetStatus, checked: boolean) => {
    let newFilterValue: AssetStatus[] | undefined = undefined

    if (checked) {
      newFilterValue = [...filteredStatus, status]
      setFilteredStatus(newFilterValue)
    } else {
      newFilterValue = filteredStatus.filter((v) => v !== status)
      setFilteredStatus(newFilterValue)
    }

    if (newFilterValue.length === 0) {
      newFilterValue = undefined
    }

    onFilterValueChange(newFilterValue)
  }

  return (
    <Flexbox feJustifyContent="center" feFlexDirection="column" feGap="md">
      <StatusCheckbox
        status={AssetStatus.acceptable}
        checked={findStatusValue(AssetStatus.acceptable)}
        onChange={handleOnChange}
      />
      <StatusCheckbox
        status={AssetStatus.unsatisfactory}
        checked={findStatusValue(AssetStatus.unsatisfactory)}
        onChange={handleOnChange}
      />
      <StatusCheckbox
        status={AssetStatus.unacceptable}
        checked={findStatusValue(AssetStatus.unacceptable)}
        onChange={handleOnChange}
      />
      <StatusCheckbox
        status={AssetStatus.severe}
        checked={findStatusValue(AssetStatus.severe)}
        onChange={handleOnChange}
      />
    </Flexbox>
  )
}

export const StatusFilter = withColumnFilterPopover<RecommendedAction, AssetStatus[]>(
  StatusFilterPopover,
  (filterValue: AssetStatus[]) => (
    <ul
      css={css`
        list-style-type: disc;
        list-style-position: inside;
        margin: 0;
        padding: 0;

        & > li {
          font-size: ${FONT_SIZES.md} !important;
        }
      `}
    >
      {filterValue.map((v, index) => (
        <li key={index}>{v}</li>
      ))}
    </ul>
  )
)
