import { TextEllipsis } from '@/common/text-ellipsis'
import { AssetHistoryClosedRecommendedAction, AssetHistoryOpenRecommendedAction } from '@/models/reportStatus/types'
import { RecommendedActionDateDisplay } from '@/modules/report-status/components/history/DateDisplay'
import {
  StyledFaultHistoryRecommendedAction,
  StyledFaultHistoryRecommendedActionAdditionalComments,
  StyledFaultHistoryRecommendedActionContainer,
  StyledFaultHistoryRecommendedActionDate,
  StyledFaultHistoryRecommendedActionText,
  StyledFaultHistoryRecommendedActionWorkOrder,
} from '@/modules/report-status/styled'
import { Icon } from '@skf-internal/ui-components-react'
import { format, parseISO } from 'date-fns'
import { FC } from 'react'

const recommendedActionStateLabel = (
  recommendedAction: AssetHistoryOpenRecommendedAction | AssetHistoryClosedRecommendedAction
): string => {
  switch (recommendedAction.state) {
    case 'open':
      return 'Open'
    case 'closed':
      switch (recommendedAction.outcome) {
        case 'completed':
          return 'Completed'
        case 'rejected':
          return 'Rejected'
      }
  }
}

const RecommendedActionHistory: FC<{
  recommendedAction: AssetHistoryOpenRecommendedAction | AssetHistoryClosedRecommendedAction
}> = ({ recommendedAction }) => {
  const { recommendedAction: action, additionalNotes, dueDate, state, workOrder } = recommendedAction

  return (
    <StyledFaultHistoryRecommendedActionContainer feJustifyContent="space-between" feGap="lg">
      <StyledFaultHistoryRecommendedAction feAlignItems="center" feGap="xs">
        <Icon feIcon="arrowRight" feSize="sm" />
        <TextEllipsis
          label="Recommended Action"
          value={action === '' ? 'No Recommended Action' : action}
          rowsUntilEllipsis={3}
        />
      </StyledFaultHistoryRecommendedAction>
      <StyledFaultHistoryRecommendedActionText feFontWeight="bold">
        {recommendedActionStateLabel(recommendedAction)}
      </StyledFaultHistoryRecommendedActionText>
      <StyledFaultHistoryRecommendedActionAdditionalComments
        label="Additional comments"
        value={additionalNotes === '' ? 'No additional comments' : additionalNotes}
        rowsUntilEllipsis={3}
      />
      <StyledFaultHistoryRecommendedActionDate>
        <RecommendedActionDateDisplay label="Due Date" value={format(parseISO(dueDate), 'M/d/yyyy')} />
      </StyledFaultHistoryRecommendedActionDate>
      <StyledFaultHistoryRecommendedActionWorkOrder label="Work order" value={workOrder} rowsUntilEllipsis={3} />
      <StyledFaultHistoryRecommendedActionDate>
        {state === 'closed' && recommendedAction.closeDate !== null && (
          <RecommendedActionDateDisplay
            label="Closed Date"
            value={format(parseISO(recommendedAction.closeDate), 'M/d/yyyy')}
          />
        )}
      </StyledFaultHistoryRecommendedActionDate>
    </StyledFaultHistoryRecommendedActionContainer>
  )
}
export default RecommendedActionHistory
