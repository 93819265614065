import { CenteredLoader } from '@/common/centered-loader'
import { EvidenceImageData } from '@/models/reportStatus/evidenceTypes'
import useDeleteFaultImage from '@/modules/report-status/hooks/useDeleteFaultImage'
import useUploadFaultEvidenceImages from '@/modules/report-status/hooks/useUploadFaultEvidenceImages'
import {
  StyledCenteredLoader,
  StyledErrorIndicator,
  StyledErrorMessage,
  StyledImageWrapper,
  StyledUploadedImageCard,
} from '@/modules/report-status/styled'
import useDeepCompareEffect from '@/shared/hooks/useDeepCompareEffect'
import { useEffectOnce } from '@/shared/hooks/useEffectOnce'
import useTimeout from '@/shared/hooks/useTimeout'
import { dataTestId } from '@/tests/testid'
import { FC } from 'react'
import ImageActions from './ImageActions'
import ImageCardDetails from './ImageCardDetails'
import { useReportStatusContext } from '@/contexts/reportStatus'

interface UploadedImageCardProps {
  imageDetails: EvidenceImageData
}

const UploadedImageCard: FC<UploadedImageCardProps> = ({ imageDetails }) => {
  const {
    imageInfo: { fileID, fileName },
    appInfo: { error: imageError, isUploaded },
  } = imageDetails

  const { setUploadedEvidenceFilesStatus } = useReportStatusContext()

  const { execute: uploadFaultEvidenceImage, status: uploadFaultEvidenceImageStatus } =
    useUploadFaultEvidenceImages(imageDetails)

  const { execute: deleteFaultEvidenceImage, status: deleteFaultEvidenceImageStatus } = useDeleteFaultImage(fileID)

  useEffectOnce(() => {
    !isUploaded && !imageError && uploadFaultEvidenceImage()
  })

  const { startTimeout, clearTimeout } = useTimeout(() => {
    deleteFaultEvidenceImage()
  }, 5000)

  useDeepCompareEffect(() => {
    if (imageError) {
      startTimeout()
      return () => clearTimeout()
    }
  }, [imageError])

  useDeepCompareEffect(() => {
    const evidenceKey = imageDetails.imageInfo.fileID
    const evidenceStatus = {
      [evidenceKey]: uploadFaultEvidenceImageStatus,
    }

    if (!isUploaded && !imageError) {
      setUploadedEvidenceFilesStatus((prev) => {
        if (uploadFaultEvidenceImageStatus === 'success') {
          return prev.filter((status) => !(evidenceKey in status))
        }
        const existingStatusIndex = prev.findIndex((status) => evidenceKey in status)

        if (existingStatusIndex !== -1) {
          const updatedStatus = [...prev]
          updatedStatus[existingStatusIndex] = evidenceStatus
          return updatedStatus
        }

        return [...prev, evidenceStatus]
      })
    }
  }, [uploadFaultEvidenceImageStatus, imageDetails, isUploaded, imageError])

  return (
    <StyledImageWrapper key={fileName} data-testid={dataTestId.evidenceUploadedImageCard}>
      <StyledUploadedImageCard isError={Boolean(imageError ? true : false)}>
        {[uploadFaultEvidenceImageStatus, deleteFaultEvidenceImageStatus].includes('loading') ? (
          <StyledCenteredLoader>
            <CenteredLoader />
          </StyledCenteredLoader>
        ) : imageError ? (
          <StyledErrorIndicator>!</StyledErrorIndicator>
        ) : (
          <ImageActions imageDetails={imageDetails} deleteFaultEvidenceImage={deleteFaultEvidenceImage} />
        )}
      </StyledUploadedImageCard>
      {(imageError && <StyledErrorMessage>{imageError}</StyledErrorMessage>) || (
        <ImageCardDetails imageDetails={imageDetails} />
      )}
    </StyledImageWrapper>
  )
}

export default UploadedImageCard
