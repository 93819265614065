import { HalCustomerNoteData } from '@/models/reportStatus/types'
import NewNoteNotification from '@/modules/report-status/components/customerNote/NewNoteNotification'
import NoteMessage from '@/modules/report-status/components/customerNote/NoteMessage'
import { StyledCustomerNoteCollapse, StyledCustomerNoteNotificationContainer } from '@/modules/report-status/styled'
import { DateFormats, formatDate, getMaxDateFromArray } from '@/shared/dateUtils'
import { dataTestId } from '@/tests/testid'
import { FC, useState } from 'react'

interface CustomerNotesProps {
  customerNotes: HalCustomerNoteData[]
}

const CustomerNote: FC<CustomerNotesProps> = ({ customerNotes }) => {
  const [isCollapseOpen, setIsCollapseOpen] = useState(false)

  return (
    <StyledCustomerNoteCollapse
      feTitle={'Customer Notes'}
      feAnimate
      feTitleAs="h4"
      onClick={() => setIsCollapseOpen(!isCollapseOpen)}
      data-testid={dataTestId.customerNoteCollapse}
    >
      <StyledCustomerNoteNotificationContainer>
        {customerNotes
          ?.sort((a, b) => Date.parse(b.noteDate) - Date.parse(a.noteDate))
          .map((customerNote, index) => {
            return <NoteMessage key={index} customerNoteData={customerNote} />
          })}
      </StyledCustomerNoteNotificationContainer>

      {!isCollapseOpen && (
        <NewNoteNotification
          newestNoteCreationDate={getMaxDateFromArray(
            customerNotes.map((cn) => formatDate(cn.noteDate, DateFormats.ISO8601Format))
          )}
        />
      )}
    </StyledCustomerNoteCollapse>
  )
}

export default CustomerNote
