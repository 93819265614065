import { ROUTES } from '@/shared/constants'
import { openToast } from '@/store/genericToast/actions'
import { useAppDispatch } from '@/store/store'
import { ColumnFiltersState, SortingState } from '@tanstack/react-table'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useLocation, useNavigationType } from 'react-router-dom'

type UseApplyTableFiltersAndSortingProps = {
  setColumnFilters: (value: SetStateAction<ColumnFiltersState>) => void
  setIsTableFiltered: (value: SetStateAction<boolean>) => void
  setSorting: (value: SetStateAction<SortingState>) => void
  setTableState: Dispatch<SetStateAction<boolean>>
}

/**
 * useApplyTableFiltersAndSorting is a custom hook that updates table filters, sorting, and state based on the
 * navigation state passed back from the report status route. It also shows a toast notification if toast properties are provided.
 *
 * @param {(value: SetStateAction<ColumnFiltersState>) => void} props.setColumnFilters - Function to set column filters state.
 * @param {(value: SetStateAction<boolean>) => void} props.setIsTableFiltered - Function to set table filtered state.
 * @param {(value: SetStateAction<SortingState>) => void} props.setSorting - Function to set sorting state.
 * @param {Dispatch<SetStateAction<boolean>>} props.setTableState - Function to set table state.
 *
 **/
const useApplyTableFiltersAndSorting = ({
  setColumnFilters,
  setIsTableFiltered,
  setSorting,
  setTableState,
}: UseApplyTableFiltersAndSortingProps) => {
  const location = useLocation()
  const navType = useNavigationType()
  const applyTableFiltersAndSortingDispatch = useAppDispatch()

  const [tableScrollOffset, setTableScrollOffset] = useState<number | undefined>(undefined)

  useEffect(() => {
    const { columnFilters, sorting, from, toastProps, tableState, tableScrollPosition } = location?.state || {}

    if (
      navType === 'PUSH' &&
      from === ROUTES.REPORT_STATUS &&
      (columnFilters?.length ||
        sorting?.length ||
        toastProps !== undefined ||
        tableState !== undefined ||
        tableScrollPosition !== undefined)
    ) {
      if (columnFilters?.length) {
        setColumnFilters(columnFilters)
        setIsTableFiltered(columnFilters.length === 0)
      }
      if (sorting?.length) {
        setSorting(sorting)
      }
      if (tableState !== undefined) {
        setTableState(tableState)
      }

      if (tableScrollPosition !== undefined) {
        setTableScrollOffset(tableScrollPosition)
      }

      if (toastProps !== undefined) {
        applyTableFiltersAndSortingDispatch(openToast(toastProps))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, navType])
  return { tableScrollOffset }
}

export default useApplyTableFiltersAndSorting
