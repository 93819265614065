import { useRef, useCallback } from 'react'
import { Row } from '@tanstack/react-table'
import { AssetRow } from '@/models/analysisBacklog/types'
import { RecommendedAction } from '@/models/unresolvedRecommendations/types'
import { OrphanNoteAcknowledged, OrphanNoteUnacknowledged } from '@/models/orphanNotes/types'
import { DevicesSensorsTableDeviceRowData } from '@/models/devicesAndSensors/types'

/**
 * This hook provides keyboard navigation functionality for a table component.
 *
 * @returns {object} An object containing the following properties:
 *   - `tbodyRef`: A reference to the `<tbody>` element of the table.
 *   - `handleKeyDown`: A callback function that handles the `keydown` event on the table body.
 *
 */
const useTableKeyboardNavigation = () => {
  const tbodyRef = useRef<HTMLTableSectionElement>(null)

  const handleKeyDown = useCallback(
    (
      event: React.KeyboardEvent<HTMLTableRowElement>,
      row:
        | Row<AssetRow>
        | Row<RecommendedAction>
        | Row<OrphanNoteUnacknowledged | OrphanNoteAcknowledged>
        | Row<DevicesSensorsTableDeviceRowData>
    ) => {
      event.preventDefault()
      const currentRow = tbodyRef.current?.children.namedItem(row.id) as HTMLTableRowElement | null

      if (event.key === 'ArrowUp') {
        const previousRow = currentRow?.previousElementSibling as HTMLTableRowElement | null
        previousRow?.focus()
      }
      if (event.key === 'ArrowDown') {
        const nextRow = currentRow?.nextElementSibling as HTMLTableRowElement | null
        nextRow?.focus()
      }
    },
    []
  )

  return { tbodyRef, handleKeyDown }
}

export default useTableKeyboardNavigation
