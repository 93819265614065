import { useRef, useEffect, useCallback } from 'react'

type IntervalCallback = () => void
/**
 * The useInterval hook provides a way to manage intervals in a React component.
 * Unlike typical intervals that start immediately, this hook returns a function that can be used to start the interval when needed.
 *
 * The useInterval hook accepts two arguments and returns an object with two functions.
 *
 * `callback` (IntervalCallback): A function to be executed when the interval is triggered.
 *
 * `delay` (number): The delay in milliseconds before the callback is executed.
 *
 * Returns:
 *
 * `startInterval` (() => void): A function to start the interval.
 *
 * `clearInterval` (() => void): A function to clear the interval.
 */
const useInterval = (callback: IntervalCallback, delay: number) => {
  const intervalRef = useRef<number | null>(null)
  const savedCallback = useRef<IntervalCallback>(callback)

  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  const startInterval = useCallback(() => {
    if (typeof delay === 'number') {
      intervalRef.current = window.setInterval(() => {
        savedCallback.current()
      }, delay)
    }
  }, [delay])

  const clearInterval = useCallback(() => {
    if (intervalRef.current !== null) {
      window.clearInterval(intervalRef.current)
      intervalRef.current = null
    }
  }, [])

  useEffect(() => {
    return () => clearInterval()
  }, [clearInterval])

  return { startInterval, clearInterval }
}

export default useInterval
