import { AssetStatus } from '@/models/analysisBacklog/types'

export const assetStatusString: Record<AssetStatus, string> = {
  'never-reported': 'Never Reported',
  normal: 'Normal',
  acceptable: 'Acceptable',
  unsatisfactory: 'Unsatisfactory',
  unacceptable: 'Unacceptable',
  severe: 'Severe',
}
