import { ReportStatusLoaderData } from '@/app/routes/types'
import { SkfCustomerNoteData } from '@/models/reportStatus/types'
import { StyledSKfCustomerNoteHistoryContainer } from '@/modules/report-status/styled'
import { DateFormats, formatDate } from '@/shared/dateUtils'
import { dataTestId } from '@/tests/testid'
import { FC } from 'react'
import { useLoaderData } from 'react-router-dom'
import CustomerNoteHistoryInfo from './CustomerNoteHistoryInfo'
import { capitalizeFirstLetter } from '@/shared/utils'

const SKfCustomerNoteHistory: FC<{ customerNote: SkfCustomerNoteData }> = ({ customerNote }) => {
  const { reportFaultTypes: faultTypesData } = useLoaderData() as ReportStatusLoaderData

  if (faultTypesData === undefined) {
    return null
  }

  const faultString = faultTypesData.find((f) => f.code === customerNote.fault)?.text

  return (
    <StyledSKfCustomerNoteHistoryContainer data-testid={dataTestId.sKfCustomerNoteHistory}>
      <CustomerNoteHistoryInfo value={faultString || 'Invalid fault type'} label="Fault" />
      <CustomerNoteHistoryInfo
        value={formatDate(customerNote.collectionDate, DateFormats.AmericanDateFormat)}
        label="Collection Date"
      />
      <CustomerNoteHistoryInfo value={capitalizeFirstLetter(customerNote.status)} label="Status" />
      <CustomerNoteHistoryInfo value={customerNote.comments} label="Comments" />
    </StyledSKfCustomerNoteHistoryContainer>
  )
}
export default SKfCustomerNoteHistory
