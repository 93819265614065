import styled from '@emotion/styled'
import { COLORS, SPACINGS } from '@skf-internal/ui-components-react'

//SingleLineTextEllipsis

export const StyledEllipsisTooltip = styled.div`
  background-color: ${COLORS.white};
  font-size: 0.8rem;
  max-width: 19rem;
  padding: ${SPACINGS.sm};
  border-radius: 0.1rem;
  z-index: 9999999;
  word-wrap: break-word;
  overflow-wrap: break-word;
`
export const StyledSingleLineTextEllipsisContainer = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

export const StyledSingleLineTextEllipsisContentWrapper = styled.div<{ fontSizeInRem: string }>`
  width: fit-content;
  margin: 0.4rem 0;
  font-size: ${(props) => props.fontSizeInRem};
`
