import { StatusCode } from '@/api/constants'
import CustomerSelector from '@/app/main-header/CustomerSelector'
import { StyledLogo, StyledLogoDivider, StyledLogoutArea, StyledMainHeader } from '@/app/main-header/styled'
import { DarwinError } from '@/common/error-boundary/DarwinError'
import { ROUTES } from '@/shared/constants'
import useDeepCompareEffect from '@/shared/hooks/useDeepCompareEffect'
import { STORAGE_DARWIN_ERROR, STORAGE_GLOBAL_FILTERS_KEY } from '@/shared/localStorageUtils'
import { useTypedSelector } from '@/store/store'
import { dataTestId } from '@/tests/testid'
import { useMsal } from '@azure/msal-react'
import { IconButton, Logo, Text } from '@skf-internal/ui-components-react'
import { useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'

export const MainHeader = () => {
  const { instance: msalInstance } = useMsal()
  const { errorData } = useTypedSelector((state) => ({ ...state }))
  const [isHeaderShown, setIsHeaderShown] = useState<boolean>(true)
  const storedError = useRef<DarwinError>(JSON.parse(localStorage.getItem(STORAGE_DARWIN_ERROR) || '{}') as DarwinError)

  const { pathname } = useLocation()

  useDeepCompareEffect(() => {
    const darwinError = errorData?.error || storedError.current
    if (
      (darwinError?.status === StatusCode.Unauthorized || darwinError?.status === undefined) &&
      pathname === ROUTES.ERROR
    ) {
      setIsHeaderShown(false)
    } else {
      setIsHeaderShown(true)
    }
  }, [errorData, pathname])

  const signOut = async () => {
    await msalInstance.logoutRedirect()
  }

  const onClick = () => {
    localStorage.removeItem(STORAGE_GLOBAL_FILTERS_KEY)
    signOut()
  }

  return (
    isHeaderShown && (
      <StyledMainHeader data-testid={dataTestId.appHeader}>
        <StyledLogo href="/">
          <Logo feWidth="6.5rem" feColor="secondary" title="SKF" />
          <StyledLogoDivider feVertical={true} feColor="secondary" feSpacing="lg" />
          <Text feColor="white" feFontSize="lg">
            COMPASS
          </Text>
        </StyledLogo>
        <CustomerSelector />
        <StyledLogoutArea onClick={() => onClick()} data-testid={dataTestId.logoutButton}>
          <IconButton feIcon="logout" feColor="white" as="button" aria-label="logout button" />
          <Text feColor="white" feFontWeight="bold">
            Log out
          </Text>
        </StyledLogoutArea>
      </StyledMainHeader>
    )
  )
}
