import { getAutomaticDiagnostics } from '@/api/paths/automaticDiagnostics'
import { DarwinError } from '@/common/error-boundary/DarwinError'
import { useAutomaticsDiagnosticsContext } from '@/contexts/automaticDiagnostics'
import { AutomaticDiagnosticsData } from '@/models/automaticDiagnostics/types'
import { generateAutomaticDiagnosticsChartData } from '@/modules/automatic-diagnostics/utils'
import { useApi } from '@/shared/hooks/useApi'
import { useAsyncThrow } from '@/shared/hooks/useAsyncThrow'
import { AxiosError } from 'axios'
import { useParams } from 'react-router-dom'

const useGetAutomaticDiagnosticsData = () => {
  const { sid: companyId } = useParams()

  const throwError = useAsyncThrow()

  const { setAutomaticsDiagnosticsData } = useAutomaticsDiagnosticsContext()

  return useApi(async () => {
    return getAutomaticDiagnostics(
      {
        onSuccess: async (result: AutomaticDiagnosticsData) => {
          setAutomaticsDiagnosticsData(generateAutomaticDiagnosticsChartData(result))
        },
        onError: async (error: AxiosError) => {
          throwError(new DarwinError(error))
        },
      },
      {
        companyId,
      }
    )
  })
}

export default useGetAutomaticDiagnosticsData
