import { Popup, useTooltip } from '@skf-internal/ui-components-react'
import { FC } from 'react'
import {
  StyledSingleLineTextEllipsisContentWrapper,
  StyledSingleLineTextEllipsisContainer,
  StyledEllipsisTooltip,
} from '../styled'

/**
 * SingleLineTextEllipsis Component
 *
 * This functional component displays a single line of text that can be truncated with an ellipsis
 * if it exceeds a specified character length. When the text is truncated, hovering over the text
 * will display the full content in a tooltip.
 *
 * @param {Object} props - The props for the component.
 * @param {string} [props.value] - The text content to display. If undefined, an empty string is shown.
 * @param {string} [props.className] - An optional className for styling the container element.
 * @param {number} props.characterLength - The maximum number of characters to display before truncating the text.
 *
 * @example
 * <SingleLineTextEllipsis value="This is a long string of text" characterLength={10} />
 *
 * @returns {JSX.Element} The rendered component.
 *
 * @remarks
 * - Uses the `useTooltip` hook to handle the hover state and display a tooltip when necessary.
 * - If the text exceeds the `characterLength`, it is truncated, and the full text is displayed in a tooltip on hover.
 * - The component supports passing a `className` for custom styling.
 */

const SingleLineTextEllipsis: FC<{
  value: string | undefined
  characterLength: number
  fontSizeInRem: string
}> = ({ value, characterLength, fontSizeInRem }) => {
  const [hoverRef, isHovered] = useTooltip()

  const isOverflowing = value && value.length > characterLength

  const truncatedText = value && isOverflowing ? value.slice(0, characterLength) + '...' : value || ''

  return (
    <>
      <StyledSingleLineTextEllipsisContainer>
        <StyledSingleLineTextEllipsisContentWrapper ref={hoverRef} fontSizeInRem={fontSizeInRem}>
          {truncatedText}
        </StyledSingleLineTextEllipsisContentWrapper>
      </StyledSingleLineTextEllipsisContainer>
      {isHovered && isOverflowing && (
        <Popup isHovered={isHovered} triggerElement={hoverRef.current}>
          <StyledEllipsisTooltip>{value}</StyledEllipsisTooltip>
        </Popup>
      )}
    </>
  )
}

export default SingleLineTextEllipsis
