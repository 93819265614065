import { useFetchDataContext } from '@/contexts/common/fetchDataContext'
import { LoadingType } from '@/shared/hooks/useApi'
import { useTypedSelector } from '@/store/store'
import { dataTestId } from '@/tests/testid'
import { IconButton, Tooltip, useTooltip } from '@skf-internal/ui-components-react'

/**
 * HeaderRefreshButton is the button that located in header action panel
 * and it responsible for trigger related module refresh function (fetch data function from fetch context)
 *
 *
 * HeaderRefreshButton is disabled when related module fetch function runs
 *
 * @returns {JSX.Element} The rendered component.
 */
const HeaderRefreshButton = () => {
  const [refreshHoveredRef, isRefreshHovered] = useTooltip()
  const { fetchDataStatus, fetchDataFn } = useFetchDataContext()

  const {
    moduleHeaderData: {
      headerActionPanelConfig: { isAsyncIndicatorShown },
    },
  } = useTypedSelector((store) => ({ ...store }))

  return (
    <>
      <Tooltip isHovered={isRefreshHovered} triggerElement={refreshHoveredRef.current}>
        Refresh
      </Tooltip>
      <IconButton
        disabled={fetchDataStatus === 'loading' && !isAsyncIndicatorShown}
        feIcon="refresh"
        as="button"
        aria-label="Refresh"
        feColor="brand"
        onClick={() => {
          fetchDataFn({ loaderType: LoadingType.HEADER })
        }}
        ref={refreshHoveredRef}
        data-testid={dataTestId.headerRefreshButton}
      />
    </>
  )
}
export default HeaderRefreshButton
