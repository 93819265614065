import { css } from '@emotion/react'
import { COLORS, TextField } from '@skf-internal/ui-components-react'
import { createColumnHelper } from '@tanstack/react-table'
import {
  ClosedRecommendedActionState,
  RecommendationState,
  RecommendedAction,
} from '@/models/unresolvedRecommendations/types'
import AssetStatusIcon from '@/modules/unresolved-recommendation/components/status-icon'
import { sortRAtableWithAssetStatus } from '@/modules/unresolved-recommendation/components/sorting'
import { statusFilterFn } from '@/modules/unresolved-recommendation/utils'
import { StatusFilter } from '@/modules/unresolved-recommendation/components/filters/status-filter'
import { TextSearchFilter, textSearchFilterFn } from '@/common/table/filters/text-search-filter'
import { DateFilter, dateFilterFn } from '@/common/table/filters/date-filter'
import { RedirectButton } from '@/modules/unresolved-recommendation/components/redirectButton/redirectButton'
import { MetaTableState } from '@/shared/models/types'
import { DateSortFn } from '@/common/table/sort-functions/date'
import { TextEllipsis } from '@/common/text-ellipsis'
import { FC } from 'react'
import { withColumnFilterPopover } from '@/common/table/filters/filter-popover-wrapper'

const columnHelper = createColumnHelper<RecommendedAction>()

function dueInDaysHelper(daysInDue: string) {
  if (
    [RecommendationState.DefaultDate, ClosedRecommendedActionState.dueInDays].includes(
      daysInDue as RecommendationState.DefaultDate | ClosedRecommendedActionState.dueInDays
    )
  ) {
    return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>{'-'}</div>
  }
  const daysDiff = parseInt(daysInDue)
  let backgroundColor = ''
  let color = ''
  if (daysDiff < 1 && daysDiff > -30) {
    backgroundColor = COLORS.orangeDarker
    color = 'white'
  } else if (daysDiff <= -30) {
    backgroundColor = COLORS.redBase
    color = 'white'
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <span
        css={css({
          color: color,
          backgroundColor: backgroundColor,
          padding: '3px 5px 3px 8px',
          borderRadius: '5px',
        })}
      >
        {daysDiff} Days
      </span>
    </div>
  )
}

const DaysToDueSearchFilterPopover: FC<{
  initialValue?: string
  onFilterValueChange: (value?: string) => void
}> = ({ initialValue, onFilterValueChange }) => {
  return (
    <TextField
      feSize="sm"
      type="number"
      feLabel="Search"
      feHideLabel
      placeholder="Search"
      defaultValue={initialValue}
      onChange={(_, searchString) => onFilterValueChange(searchString)}
      autoFocus
    />
  )
}

const DaysToDueSearchFilter = withColumnFilterPopover<string, string>(DaysToDueSearchFilterPopover, (value) => (
  <div>{value}</div>
))

interface TableCellContentProps {
  children: React.ReactNode
  textAlignCondition: string
}

const TableCellContent: React.FC<TableCellContentProps> = ({ children, textAlignCondition }) => {
  return (
    <div
      css={css`
        overflow-wrap: anywhere;
        text-align: ${textAlignCondition === '-' ? 'center' : 'left'};
      `}
    >
      {children}
    </div>
  )
}

export const unresolvedRecommendationTableDefinition = (tableState: boolean = false) => [
  columnHelper.accessor((row) => row.id, {
    id: 'ID',
    header: '#',
    cell: ({ getValue }) => {
      return <span>{getValue()}</span>
    },
    size: 50,
    enableSorting: true,
    sortingFn: (rowA, rowB) => {
      const rowA_ID = rowA.original.id
      const rowB_ID = rowB.original.id

      if (rowA_ID === ClosedRecommendedActionState.dueInDays || rowB_ID === ClosedRecommendedActionState.dueInDays) {
        return 0
      } else {
        const diffA = parseInt(rowA_ID)
        const diffB = parseInt(rowB_ID)
        return diffB - diffA
      }
    },
    enableColumnFilter: false,
  }),

  columnHelper.accessor((row) => row.assetStatus, {
    id: 'assetStatus',
    header: 'Asset Status',
    cell: ({ getValue }) => {
      return (
        <AssetStatusIcon
          css={css`
            margin: auto;
          `}
          value={getValue()}
        />
      )
    },
    size: 200,
    enableSorting: true,
    sortingFn: (rowA, rowB) => sortRAtableWithAssetStatus(rowA.original.assetStatus, rowB.original.assetStatus),
    enableColumnFilter: true,
    filterFn: statusFilterFn,
    meta: {
      filterComponent: StatusFilter,
    },
  }),

  columnHelper.accessor((row) => row.assetName, {
    id: 'assetName',
    header: 'Asset',
    cell: ({ getValue }) => {
      return <TableCellContent textAlignCondition={getValue()}>{getValue()}</TableCellContent>
    },
    size: 200,
    enableSorting: true,
    enableColumnFilter: true,
    filterFn: textSearchFilterFn,
    meta: {
      filterComponent: TextSearchFilter,
    },
  }),

  columnHelper.accessor((row) => row.assetParent, {
    id: 'assetParent',
    header: 'Belongs To',
    cell: ({ getValue }) => {
      return <TableCellContent textAlignCondition={getValue()}>{getValue()}</TableCellContent>
    },
    size: 160,
    enableSorting: true,
    enableColumnFilter: true,
    filterFn: textSearchFilterFn,
    meta: {
      filterComponent: TextSearchFilter,
    },
  }),

  columnHelper.accessor((row) => row.faultType, {
    id: 'faultType',
    header: 'Fault',
    cell: ({ getValue }) => {
      return <TableCellContent textAlignCondition={getValue()}>{getValue()}</TableCellContent>
    },
    size: 200,
    enableSorting: true,
    enableColumnFilter: true,
    filterFn: textSearchFilterFn,
    meta: {
      filterComponent: TextSearchFilter,
    },
  }),

  columnHelper.accessor((row) => row.recommendedAction, {
    id: 'recommendedAction',
    header: 'Recommended Action',
    cell: ({ getValue }) => {
      return (
        <TableCellContent textAlignCondition={getValue()}>
          {<TextEllipsis rowsUntilEllipsis={3} value={getValue()} />}
        </TableCellContent>
      )
    },
    size: 400,
    enableSorting: true,
    enableColumnFilter: true,
    meta: {
      filterComponent: TextSearchFilter,
    },
    filterFn: textSearchFilterFn,
  }),

  columnHelper.accessor((row) => row.raOpenDate, {
    id: 'raOpenDate',
    header: 'Created Date',
    cell: ({ getValue }) => (
      <div
        css={css`
          display: flex;
          justify-content: center;
          align-items: center;
        `}
      >
        {getValue()}
      </div>
    ),
    size: 170,
    sortingFn: DateSortFn<RecommendedAction>,
    enableSorting: true,
    enableColumnFilter: true,
    meta: {
      filterComponent: DateFilter,
    },
    filterFn: dateFilterFn<RecommendedAction>,
  }),

  columnHelper.accessor((row) => row.raDueDate, {
    id: 'raDueDate',
    header: 'Due Date',
    cell: ({ getValue }) => (
      <div
        css={css`
          display: flex;
          justify-content: center;
          align-items: center;
        `}
      >
        {getValue()}
      </div>
    ),
    sortingFn: DateSortFn<RecommendedAction>,
    enableSorting: true,
    enableColumnFilter: true,
    meta: {
      filterComponent: DateFilter,
    },
    filterFn: dateFilterFn<RecommendedAction>,
    size: 145,
  }),

  columnHelper.accessor((row) => row.dueInDays.toString(), {
    id: 'dueInDays',
    header: 'Days To Due',
    cell: ({ getValue }) => {
      const value = getValue<string>()
      return dueInDaysHelper(value)
    },
    size: 145,
    sortingFn: (rowA, rowB) => {
      const daysA = rowA.original.dueInDays
      const daysB = rowB.original.dueInDays

      if ((daysA || daysB) === ClosedRecommendedActionState.dueInDays) {
        return 0
      } else {
        const daysDiffA = parseInt(daysA)
        const daysDiffB = parseInt(daysB)
        return daysDiffB - daysDiffA
      }
    },
    enableColumnFilter: true,
    filterFn: textSearchFilterFn,
    meta: {
      filterComponent: DaysToDueSearchFilter,
    },
  }),

  columnHelper.accessor((row) => row.assetID, {
    id: 'action',
    header: '',
    enableSorting: false,
    enableColumnFilter: false,
    cell: ({ getValue, table, column }) => {
      const value = getValue()
      const { columnFilters, sorting } = table.getState()
      const {
        columnDef: { meta },
      } = column
      const tableState = (meta as MetaTableState).tableState

      return <RedirectButton value={value} columnFilters={columnFilters} sorting={sorting} tableState={tableState} />
    },
    size: 50,
    meta: {
      tableState,
    },
  }),
]
