import { FC } from 'react'
import { tableCellFormatter, truncateChartAxisValue } from '@/modules/dashboard/utils'
import { StyledAHCContentRowContainer, StyledAHCContentStatusRow, StyledAHCContentStatusRowName } from '../../styled'
import { Icon } from '@skf-internal/ui-components-react'
import { severityToIconColor, severityToIconName } from '@/modules/dashboard/utils/asset-health-condition'
import { assetStatusString } from '@/modules/analysis-backlog/models'
import { Severity } from '@/models/widgets/asset-health-condition/types'
import { dataTestId } from '@/tests/testid'

interface AHCContentRowProps {
  name: string
  value: number
  showPercentages: boolean
}

const AHCContentRow: FC<AHCContentRowProps> = ({ name, value, showPercentages }) => {
  return (
    <StyledAHCContentRowContainer key={name} data-testid={dataTestId.assetHealthConditionCurrentMonthStatusContentRow}>
      <span>
        <Icon
          feSize={'sm'}
          feIcon={severityToIconName(name as Severity)}
          feColor={severityToIconColor(name as Severity)}
        />
      </span>
      <StyledAHCContentStatusRow>
        {value >= 0 ? truncateChartAxisValue(tableCellFormatter(value, 1, showPercentages)) : 'NA'}
        <StyledAHCContentStatusRowName>{assetStatusString[name as Severity]}</StyledAHCContentStatusRowName>
      </StyledAHCContentStatusRow>
    </StyledAHCContentRowContainer>
  )
}

export default AHCContentRow
